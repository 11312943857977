import axiosInstance from "../../service/iTeacherApi";
import {
  DELETE_SCHEDULE,
  GET_ALL_SCHEDULES,
  GET_SCHEDULE,
  NEW_SCHEDULE
} from "../reducers/schedulesReducer";
import {openInfoPopup, setErrorInfoText} from "./infoPopupAction";

export const getAllSchedules = (userType) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/${userType}/schedules/`);
      dispatch({type: GET_ALL_SCHEDULES, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const getSchedule = (userType, courseId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/${userType.toLowerCase()}/schedules/${courseId}/`);
      dispatch({type: GET_SCHEDULE, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const newSchedule = (data, closeModal) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`/teacher/schedules/`, data);
      dispatch({type: NEW_SCHEDULE, payload: response.data});
      closeModal();
    } catch (error) {
      if(error.response.data.info) {
        dispatch(setErrorInfoText(`${error.response.data.info}.`));
      } else {
        dispatch(setErrorInfoText('При создании события произошла ошибка, попробуйте позже.'));
      }
      dispatch(openInfoPopup());
    }
  }
}

export const editSchedule = (id, data, closeModal) => {
  return async (dispatch) => {
    try {
      await axiosInstance.put(`/teacher/schedules/${id}/`, data);
      const response = await axiosInstance.get(`/teacher/schedules/`);
      dispatch({type: GET_ALL_SCHEDULES, payload: response.data});
      closeModal();
    } catch (error) {
      if(error.response.data.info) {
        dispatch(setErrorInfoText(`${error.response.data.info}.`));
      } else {
        dispatch(setErrorInfoText('При создании события произошла ошибка, попробуйте позже.'));
      }
      dispatch(openInfoPopup());
    }
  }
}

export const deleteSchedule = (id) => {
  return async (dispatch) => {
    try {
      await axiosInstance.delete(`/teacher/schedules/${id}/`);
      dispatch({type: DELETE_SCHEDULE, payload: id});
    } catch (error) {
      console.log(error)
    }
  }
}
