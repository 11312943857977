import React, {useState, useEffect} from "react";

import {Wrapper} from "./styled";

const MainInput = (
  {
    label,
    name,
    type,
    required,
    errorText,
    validation,
    defaultValue,
    updateData = () => null,
    readOnly,
    grey,
    onClick = () => null,
    arrow = false,
    showArrow = false,
    width,
    task,
    className
  }
) => {
  // состояния инпута
  const [active, setActive] = useState(false)

  // проверяем есть ли дефолтное значение
  useEffect(() => {
    if (defaultValue) {
      setActive(true);
    }
  })

  const onChange = (e) => {
    setActive(e.target.value);
    updateData(e);
  }

  return (
    <Wrapper
      className={` ${validation && 'valid'} task-input ${className}`}
      grey={grey}
      arrow={arrow}
      width={width}
      task={task}>
      <label className={`label ${active && 'active'}`}>{label}</label>
      <input
        className={'input'}
        name={name}
        required={required}
        type={type}
        onChange={(e) => onChange(e)}
        value={defaultValue}
        readOnly={readOnly}
        onClick={onClick}
        pattern={name === "email" ? "^[a-zA-Z_0-9\\.-]+@[a-zA-ZА-Яа-я_0-9\\.-]+\\.[a-zA-ZА-Яа-я]+$" : undefined}
      />
      {
        showArrow && <div className={`arrow ${arrow && 'active'}`}/>

      }
      {
        validation
          ? <div className={'errorText'}>{errorText}</div>
          : null
      }

    </Wrapper>
  )
}

export default MainInput;
