import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Sentence = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  
  &:last-child {margin-bottom: 0;}
  
  p {
    margin: 0 2px 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
  }

  @media ${baseTheme.media.small} {
    .task-input {
      max-width: 150px;
      height: 34px;
      min-height: auto;
      input {
        padding: 5px 12px;
      }
    }
  }
`

export {Sentence}
