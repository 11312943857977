import React from "react";

import { Button } from "./styled";

const MainButton = ({text, func = () => null, width, type = 'button', classList, disabled}) => {
  return (
    <Button
      onClick={func}
      width={width}
      type={type}
      className={classList}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

export default MainButton;
