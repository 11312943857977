import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {loginUser} from "../../store/actions/usersAction";
import axiosInstance from "../../service/iTeacherApi";
import MainInput from "../../components/inputs/mainInput/mainInput";
import PhotoBlock from "../../components/photoBlock/photoBlock";
import useFormAndValidation from "../../hooks/useFormValidation";

import {SignInForm} from "./styled";

const RegisterTab = ({loginUser}) => {
  const [isMatched, setIsMatched] = useState(true);

  const {
    values,
    handleChange,
    errors,
    setErrors,
    isValid,
  } = useFormAndValidation();
  const { email, password, repassword } = values;

  //Проверка паролей на совпадение
  useEffect(()=> {
    if(password?.length > 0) {
      if (repassword?.length > 0) {
        if (password === repassword) {
          setIsMatched(true);
        } else {
          setIsMatched(false);
        }
      }
    }
  }, [password, repassword])

  // регистрация нового пользователя
  const registrationNewUser = async (e) => {
    e.preventDefault();
    try {
      const emailLowerCase = email.toLowerCase();
      const data = new FormData();
      data.set('username', emailLowerCase)
      data.set('email', emailLowerCase)
      data.set('password', password)

      if (e.target.photo.files[0])
        data.set('photo',  e.target.photo.files[0]);

      await axiosInstance.post('/users/', data);
      const tokenResponse = await axiosInstance.post("/token/", {
        email: data.get('email'),
        password: data.get('password')
      });
      axiosInstance.defaults.headers["Authorization"] =
        "JWT " + tokenResponse.data.access;
      localStorage.setItem("access_token", tokenResponse.data.access);
      localStorage.setItem("refresh_token", tokenResponse.data.refresh);

      const userResponse = await axiosInstance.get("/users/me/");
      const userData = userResponse.data;
      localStorage.setItem("user", JSON.stringify(userData));
      loginUser(userData);
    } catch (error) {
      if(error.response.data.email.includes("Пользователь с таким адрес электронной почты уже существует.")) {
        setErrors({...errors, email: "Пользователь с данным email уже зарегистрирован. Пожалуйста, авторизуйтесь"});
      }
    }
  }

  return (
    <>
      <SignInForm onSubmit={(e) => registrationNewUser(e)} disabledBtn={!(isValid && isMatched)} noValidate>
        <MainInput required={true} type={'text'} label={'Email'} name={'email'} validation={errors.email} errorText={errors.email} updateData={handleChange}/>
        <MainInput required={true} type={'password'} label={'Пароль'} name={'password'} updateData={handleChange}/>
        <MainInput required={true} type={'password'} label={'Повторите пароль'} name={'repassword'} validation={!isMatched} errorText={'Пароли не совпадают'} updateData={handleChange}/>
        <PhotoBlock/>
        <div className="desc">Нажимая кнопку «Сохранить и продолжить», Вы соглашаетесь на обработку предоставленных Вами персональных данных.</div>
        <button
          className={'submit'}
          type='submit'
          disabled={!(isValid && isMatched)}
        >Сохранить и продолжить</button>
      </SignInForm>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

const mapDispatchToProps = {
  loginUser
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTab);
