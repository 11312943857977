import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import CheckedBlock from '../checkedBlock/checkedBlock';
import ResetProgress from '../resetProgress/resetProgress';

import * as Style from './style'
import {Word} from '../mediaDragWords/dragWordsStyled';
import Notes from '../notes/notes';

const MediaConnects = ({data, handleCheckTask, userType, tasks}) => {
    const [words, setWords] = useState([]);
    const [endEmpties, setEndEmpties] = useState([]);
    const [activeWord, setActive] = useState(null);
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {
        if (!data.id) return;
        if (data.completed?.completed) {
            displayingTask(data.completed?.task, true);
        } else if (data.completed?.have_answer) {
            const additional_info = JSON.parse(data.completed?.additional_info);
            setWords(additional_info.words);
            setEndEmpties(additional_info.endEmpties);
        } else {
            displayingTask(data.completed?.task || data.list_connects || data.task, false)
        }
    }, [data]);

    const displayingTask = (task, isCompleted) => {
        if (task?.length > 0) {
            if (isCompleted) {
                setWords(task);
            } else {
                const newWords = [];
                const newEndEmpties = [];
                let counter = 0;
                task.forEach(item => {
                    newWords.push({start: item.start, id: counter});
                    newEndEmpties.push({value: item.end, id: counter});
                    counter++
                })
                setWords(newWords);
                setEndEmpties(newEndEmpties);
            }
        }
    }

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) {
            setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
            if (taskChecked.additional_info === JSON.stringify({words, endEmpties})) {
                const additional_info = JSON.parse(taskChecked.additional_info);
                setWords(additional_info.words);
                setEndEmpties(additional_info.endEmpties);
            }
        }
    }, [tasks]);

    const dndStart = (e, word) => {
        e.target.style.opacity = '0.4';
        setTimeout(() => {
            const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
            const allDnDWord = document.querySelectorAll('.' + classNameDrag);
            allDnDWord.forEach(word => {
                word.classList.remove(classNameDrag)
            })
            e.target.classList.add(classNameDrag)
        })
        setActive(word)
    }

    const dndEnter = (e) => {
        e.target.classList.add('dnd-hovered');
    }

    const dndLeave = (e) => {
        e.target.classList.remove('dnd-hovered');
    }

    const dndDrop = (e, id) => {
        e.target.classList.add('dnd-hovered');
        document.querySelectorAll('.dnd-hovered').forEach(block => {
            block.classList.remove('dnd-hovered');
        })
        setEmptyWord(id);
    }

    const dndOver = (e) => {
        e.preventDefault();
    }

    const dndEnd = (e) => {
        e.target.style.opacity = '1';
        const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
        e.target.classList.remove(classNameDrag);
    }

    const setEmptyWord = (id) => {
        if (id === null || !activeWord?.value) return
        const newWords = words.map(item => item.id === id ? {
            ...item,
            end: activeWord.value
        } : item);
        const newEndEmpties = endEmpties.filter(item => item.value !== activeWord.value);
        setWords(newWords);
        setEndEmpties(newEndEmpties);
        setActive(null);
        handleFalseCloseChecked();
    }

    const handleCheck = () => {
        const answer = words.map(item => ({start: item.start, end: item.end}));
        const additionalInfo = {words, endEmpties};
        handleCheckTask(answer, data.id, JSON.stringify(additionalInfo));
    }

    const handleFalseCloseChecked = () => setCompleted(false);

    const startCol = words.map((word, key) => {
        if (word.end === undefined) {
            return (
                <Style.EmptyWrap key={key + word.id}>
                    <Style.Words startWord={true}>{word.start}</Style.Words>
                    <Style.EmptyItem
                        className={'dnd-hover'}
                        task={data.id}
                        empty={word.id}
                        userType={userType}
                        noneMargin={true}
                        onDragEnter={dndEnter}
                        onDragLeave={dndLeave}
                        onDragOver={dndOver}
                        onDrop={(e) => dndDrop(e, word.id)}
                        onClick={() => setEmptyWord(word.id)}
                    />
                </Style.EmptyWrap>)
        }
        return (
            <Style.EmptyWrap key={key + word.id}>
                <Style.Words startWord={true}>{word.start}</Style.Words>
                <Word noneMargin={true}>{word.end}</Word>
            </Style.EmptyWrap>)
    })

    const endCol = endEmpties.map(word => {
        return <Style.Words
            key={word.id}
            draggable="true"
            onDragStart={(e) => dndStart(e, word)}
            onClick={() => setActive(word)}
            isSelect={activeWord?.id === word.id}
            onDragEnd={dndEnd}
        >{word.value}</Style.Words>
    })

    return (
        <>
            <Style.Wrapper>
                <Style.Container>
                    <Style.Col>
                        {startCol}
                    </Style.Col>

                    <Style.Col>
                        {endCol}
                    </Style.Col>
                </Style.Container>
            </Style.Wrapper>

            <div style={{paddingTop: '15px'}}>
                <Notes notes={data.notes} userType={userType}/>
            </div>

            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
            >
                {handleCheckTask && completed !== '#24A1481A' &&
                <ResetProgress handleReset={() => displayingTask(data.completed.task)}/>}
            </CheckedBlock>
            }
        </>
    )
}

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
};


export default connect(mapStateToProps)(MediaConnects);
