import React from "react";

const NewLesson = () => {
  return (
    <td className="right right_new--lesson">
      <div className="td-wrapper">
        <div className={'StartBlock'}>
          Новый урок
        </div>
      </div>
    </td>
  )
}

export default NewLesson;