import React from "react";

import AddButton from "../../../buttons/addButton/addButton";
import InfoBlock from "../infoBlock";
import InfoItem from "../InfoItem";
import InfoNoItem from "../InfoNoItem";
import {getLessonTimeString} from "../../../../service/timePrettier";

const LessonBlock = ({lesson, date, show, isInactiveCourse, userType}) => {
  return (
    <InfoBlock title={'Следующее занятие'}>
      {!!date ? (
        <InfoItem
          type={'schedule'}
          name={`#${lesson?.count || ''} ${date.lesson}`}
          time={getLessonTimeString(date.start_time)}
        />
      ) : (
        userType === 'teacher' && !!show
          ? <InfoItem>
            <AddButton
              style={isInactiveCourse ? {opacity: 0.4} : null}
              text={'Назначить занятие'}
              func={!isInactiveCourse ? () => show(lesson.id) : null}
            />
          </InfoItem>
          : <InfoNoItem>{'Нет назначенных занятий'}</InfoNoItem>
      )}
    </InfoBlock>
  )
}

export default LessonBlock;
