import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

import iconPen from "../../../assets/media/icon/pen_grey.svg";
import iconTrash from "../../../assets/media/icon/trash_basket.svg";

export const HomeWorkItemWrap = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  margin-bottom: 8px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 15px 24px;
  min-height: 92px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  
  &:hover,
  &:focus {
    background: ${baseTheme.colors.hoverGray}
  }

  @media ${baseTheme.media.medium} {
    min-height: 64px;
    padding: 16px;
    gap: 15px 10px;
    grid-template-columns: 1fr 1fr;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    background-color: transparent;
  }
  
  .user {
    @media ${baseTheme.media.medium} {
      justify-self: flex-end;
      align-self: start;
      
      div {
        justify-content: flex-end;
      }
    }
  }

  &.teacher {
    .status{
      margin-right: 60px;
    }
  }
  .right {
    background-color: transparent;
    justify-self: flex-end;
    
    .status {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding: 14px 20px;
      border: 2px solid ${baseTheme.colors.bgGrey};
      border-radius: 1000px;
      color: ${baseTheme.colors.textGrey};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 56px;
      width: 240px;

      @media ${baseTheme.media.large} {
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
        width: 140px;
        min-height: 48px;
      }

      @media ${baseTheme.media.medium} {
        width: 170px;
        margin-right: 0;
      }

      &.complete {
        border-color: ${baseTheme.colors.bgSuccess};
        color: ${baseTheme.colors.textSuccess};
      }

      &.checked {
        border-color: ${baseTheme.colors.bgSuccess};
        background-color: ${baseTheme.colors.bgSuccess};
        color: ${baseTheme.colors.textWhite};
      }
      
     &.revision {
        border-color: ${baseTheme.colors.hoverGray};
        background-color: ${baseTheme.colors.revision};
        color: ${baseTheme.colors.textWhite};
      }
      
      &.start {
        border-color: ${baseTheme.colors.bgStart};
        color:${baseTheme.colors.textBlue};
        
        & img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 8px;
        }
      }
    }
  }
`

export const HomeWorkBtnContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 24px;
  display: flex;
  transform: translateY(-50%);

  @media ${baseTheme.media.medium} {
    top: 16px;
    right: 50%;
    transform: translate(50%, 0);
  }
`

export const HomeWorkBtn = styled.button`
  border: none;
  background-color: transparent;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  min-width: 20px;
  cursor: pointer;
  transition: .3s;
  
  &:hover, &:focus {
    opacity: .7;
  }
  
  &.edit {
    background-image: url(${iconPen});
    margin-right: 10px;
  }

  &.delete {
    background-image: url(${iconTrash});
  }
`

export const Name = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #111111;
  background-color: transparent;
`

export const Desc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #697077;
  background-color: transparent;
  margin-top: 5px;
  
  &.info {
    display: flex;
    
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      object-position: center center;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
`
