import React, {useState, useEffect} from "react";

import {FormMainContainer} from "../../popupsAdmin/styled";
import {Editor} from "react-draft-wysiwyg";
import bold from "../../../assets/media/icon/bold.svg";
import italic from "../../../assets/media/icon/italic.svg";
import underline from "../../../assets/media/icon/underline.svg";
import {
    convertFromRaw,
    convertToRaw,
    EditorState,
} from "draft-js";

const NoteInput = (
    {
        label,
        type,
        defaultValue,
        desc_note = () => null,
    }
) => {
    // состояния инпута
    const [studentVisibility, setStudentVisibility] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [text, setText] = useState( '')

    //без этого JSON.parse в media err
    useEffect(() => {
        setText(JSON.stringify({
            "blocks": [],
            "entityMap": {}
        }))
    }, [])

    useEffect(()=>{
        if(type==='edit' && defaultValue[0] && !!defaultValue[0].id){
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue[0].text))))
            setText(defaultValue[0].text)
            setStudentVisibility(defaultValue[0].visible)
        }
    },[type])

    useEffect(() => {
        let logPost = {
            text: text,
            visible: studentVisibility
        };
        desc_note(logPost)
    }, [text, studentVisibility])

    const onCheckboxChange = () => {
        setStudentVisibility(!studentVisibility)
    }

    const updateTextDescription = (state) => {
        setEditorState(state);
        setText(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    }

    return (
        <><FormMainContainer style={{paddingTop: '16px', textIndent: '15px'}}>
            <div style={{maxHeight: '163px'}}>
                <Editor
                    defaultEditorState={editorState}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    placeholder={label}
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={updateTextDescription}
                    toolbar={{
                        options: ['inline', 'colorPicker'],
                        inline: {
                            inDropdown: false,
                            className: 'media icon_font',
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline'],
                            bold: {icon: bold, className: undefined},
                            italic: {icon: italic, className: undefined},
                            underline: {icon: underline, className: undefined},
                        },
                        colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            colors: ['rgb(0,0,0)', 'rgb(192,192,192)', 'rgb(128,128,128)', 'rgb(255,255,255)', 'rgb(128,0,0)',
                                'rgb(255,0,0)', 'rgb(128,0,128)', 'rgb(255,0,255)', 'rgb(0,128,0)', 'rgb(0,255,0)',
                                'rgb(128,128,0)', 'rgb(255,255,0)', 'rgb(0,0,128)',
                                'rgb(0,0,255)', 'rgb(0,128,128)', 'rgb(0,255,255)'],
                        },
                    }}
                />
            </div>

        </FormMainContainer>
            <div className="note-block" style={{paddingTop: '5px', height: '50px'}}>
                    <span className="switch-text-note text-muted">
                        Видимость для ученика
                    </span>

                <label className="switch">
                    <input type="checkbox" checked={studentVisibility}
                           onChange={onCheckboxChange}/>
                    <span className="switch-slider"/>
                </label>
            </div>
        </>
    )
}

export default NoteInput;
