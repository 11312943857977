import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const CaptionBlockWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  
  .left {
    display: flex;
    align-items: center;
    
    .nameBlock {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      
      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: #000000;
        margin-bottom: 4px;
      }
      .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #697077;
      }
    }
    
  }
  
  .btn-list {
    display: flex;
    
    button {
      margin-right: 20px;
      
      :last-child {
        margin-right: 0;
      }
    }
  }
  .settings {
    border: 1px solid #DDE1E6;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  @media ${baseTheme.media.medium} {
    flex-direction: column-reverse;
    align-items: start;
    margin-bottom: 24px;
    
    .left {
      width: 100%;

      .nameBlock {
        margin-bottom: 0;
        .name {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 0;
        }
      }
    }

    .btn-list {
      width: 100%;
      justify-content: start;
      
      button {
        padding: 10px 15px;
      }
    }
    
    .settings {
      width: 42px;
      height: 42px;
    }
  }

  @media ${baseTheme.media.small} {
    margin-bottom: 16px;
    
    .btn-list {
      justify-content: space-between;
    }
  }
`

export {
  CaptionBlockWrap
}
