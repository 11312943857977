import React, {useEffect, useState} from 'react';
import {convertFromRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {NotesStyles} from "./commentStyled";

const Comment = ({comments, userType,header,homeworkId,handleVisibleComment}) => {
    const [editorStateNote, setEditorStateNote] = useState(EditorState.createEmpty())
    const [studentVisibility, setStudentVisibility] = useState(false);

    let content;
    let comment;

    if (comments) {
        comment = comments[comments.length-1]
    }

    useEffect(() => {
        if (comment && !!comment.id) {
            setEditorStateNote(EditorState.createWithContent(convertFromRaw(JSON.parse(comment.text))))
            setStudentVisibility(!comment.visible)
        }
    }, [comments])

    //управление видимостью подсказкок
    const onCheckboxChange = async (e) => {
        setStudentVisibility(!studentVisibility)
        handleVisibleComment(userType,comment.id,studentVisibility,homeworkId)
    }

    if (userType === 'teacher') {
        content = (
            <NotesStyles>
                <div className="note_container">

                    <div className="visible_block">

                        { <p>{header}</p>}
                        <span className="note-block">
                        <span className="switch-text-note text-muted">
                            Видимость для ученика
                        </span>

                        <label className="switch">
                            <input type="checkbox"
                                   onChange={onCheckboxChange}
                                   checked={!studentVisibility}/>
                            <span className="switch-slider"/>
                        </label>
                     </span>
                    </div>
                    <div style={{borderRadius:'8px'}}>
                        <span>Комментарий от учителя</span>
                        <Editor
                            editorState={editorStateNote}
                            toolbarHidden
                            readOnly
                            wrapperClassName="wrapper-class"
                            toolbarClassName="toolbar-class"
                        /></div>
                </div>
            </NotesStyles>)


    } else {
        content = ((comment && comment.visible) &&
            <NotesStyles>
                <div className="note_container">
                    Комментарий от учителя
                    <Editor
                        editorState={editorStateNote}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </NotesStyles>
        )
    }

    return (
        <>
            {content}
        </>
    );
};

export default Comment;