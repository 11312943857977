import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

export const FooterWrap = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  padding: 20px 48px;
  width: 100%;
  box-shadow: 0 0 4px rgba(105, 112, 119, 0.08), 0px -4px 4px rgba(105, 112, 119, 0.16);
  background-color: #fff;
  height: 90px;

  //a {
  //  text-decoration: none;
  //  font-size: 0;
  //}
  
  @media ${baseTheme.media.extraLarge} {
    padding: 16px 24px;
  }

  @media ${baseTheme.media.large} {
    padding: 16px;
  }

  @media ${baseTheme.media.medium} {
    flex-direction: column;
    height: 174px;
  }

  @media ${baseTheme.media.small} {
    padding: 12px 16px;
  }
`

export const LogoWrap = styled.div`
  width: 311px;
  display: flex;
  align-items: center;
  @media (max-width: 1050px) {
    max-width: 175px;
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`

export const NavList = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
  a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #707070;
    letter-spacing: -0.01em;
    margin-right: 40px;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  & a.active {
    color: #111 !important;
    font-weight: normal !important;
  }

  @media ${baseTheme.media.medium} {
    margin: 0 0 10px;

    a {
      font-size: 14px;
      line-height: 16px;
      margin-right: 25px;
    }
  }

  @media (max-width: 575px) {
    a {
      font-size: 14px;
      line-height: 16px;
      margin-right: 10px;
    }
  }
`

export const SocialsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 311px;

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    
    .social {
      display: block;
      margin-left: 16px;
      font-size: 0;

      .icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      :first-child {
        margin: 0;
      }
    }

    @media (max-width: 575px) {
      .social {
        margin-left: 10px;
      }
    }
  }
  
  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -.01em;
    color: rgba(105, 112, 119, .8);
    text-align: center;
  }

  @media (max-width: 1050px) {
    max-width: 175px;
    
    .desc {
      font-size: 12px;
      line-height: 15px;
    }
  }
`
