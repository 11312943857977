import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const TextModalBody = styled.form`
  width: 100%;
  max-width: 660px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(105, 112, 119, 0.12), 0px 8px 16px rgba(105, 112, 119, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  padding: 24px;
  max-height: 745px;

  .closed {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #000000;
    width: 100%;
    margin-bottom: 24px;
  }
  
  @media ${baseTheme.media.medium} {
    padding: 16px;

    .title {
      font-size: ${baseTheme.fz.titlePopupSmall};
      line-height: ${baseTheme.lh.titlePopupSmall};
      margin-bottom: 20px;
    }

    .closed {
      top: 16px;
      right: 16px;
    }
    
    button {
      font-size: 16px;
      line-height: 20px;
      padding: 14px 20px;
    }
  }
`

const TextModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;

  @media ${baseTheme.media.medium} {
    padding: 16px;
  }
`

export {
  TextModalOverlay,
  TextModalBody
}
