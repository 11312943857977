import styled from "styled-components";
import {baseTheme} from "../../baseTheme";


const CourseEmptyWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @media ${baseTheme.media.large} {
    flex-direction: column;
  }
`

const MainContent = styled.div`
  flex: 2;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16), 0px 0.5px 0.5px rgba(0, 0, 0, 0.1), 0px 0.1px 0.1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding-top: 16px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #111111;
    background-color: transparent;
    padding: 0 16px 16px;
    border-bottom: 1px solid #DDE1E6;
  }

  @media ${baseTheme.media.large} {
    order: 3;
    width: 100%;
  }
`

const Plans = styled.div`
  padding: 0 16px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const SidebarItem = styled.div`
  background-color: #fff;
  box-shadow: 0 0 2px rgba(105, 112, 119, 0.06), 0 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 24px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #111111;
    padding: 0 16px 16px;
    border-bottom: 1px solid #DDE1E6;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1099px) {
      font-size: 16px;
    }
  }

  .info-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 16px 0;

    .info {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      .date {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #111111;
      }

      .photo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        border: 1px dashed #DDE1E6;
        margin-right: 16px;

        img {
          width: 32px;
          height: 32px;
          min-width: 32px;
          margin-right: 0;
        }
      }

      .no-photo {

      }

      .name {
        overflow: hidden;

        .email {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #111111;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .student {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #111111;
          background-color: #fff;
        }
      }

      .no-text {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #697077;
        opacity: 0.72;
      }
    }
  }

  @media (max-width: 1099px) {
    margin-bottom: 16px;
  }
`

const ActiveSidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: transparent;
  margin-left: 24px;
  width: 318px;
  min-width: 318px;
  
  &.left {
    margin-left: 0;
    margin-right: 24px;
  }

  @media ${baseTheme.media.extraLarge} {
    width: 300px;
    min-width: 300px;
    margin-left: 10px;

    &.left {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  @media ${baseTheme.media.large} {
    order: 2;
    width: 100%;
    min-width: auto;
    margin-left: 0;

    &.left {
      margin-right: 0;
    }
  }
`

export {
  CourseEmptyWrap,
  MainContent,
  Plans,
  SidebarItem,
  ActiveSidebarWrap
}
