import React, {useEffect, useState} from 'react';

import {convertFromRaw, EditorState} from 'draft-js';
import axiosInstance from '../../../service/iTeacherApi';
import {NotesStyles} from '../notes/noteStyled';
import {Editor} from 'react-draft-wysiwyg';

const MediaNote = ({data, userType}) => {
    const [editorStateNote, setEditorStateNote] = useState(EditorState.createEmpty())
    const [studentVisibility, setStudentVisibility] = useState(false);

    let content;

    let url = window.location.pathname;
    const isCourse = url?.search('/course/') != -1

    useEffect(() => {
        const desc = data.desc
        if (desc !== '' && !!desc) {
            setEditorStateNote(EditorState.createWithContent(convertFromRaw(JSON.parse(data.desc))))
            // setStudentVisibility(!data.visible)
            setStudentVisibility(data.visible)
        }
    }, [data])

//управление видимостью подсказкок
    const onCheckboxChange = async (e) => {
        // setStudentVisibility(!studentVisibility)
        const checkedValue = e.currentTarget.checked
        setStudentVisibility(checkedValue)

        try {
            await axiosInstance.patch(`teacher/task/${data.id}/`, {visible: checkedValue})
        } catch (e) {
            console.log(e)
        }
    }

    if (userType === 'TEACHER' || userType === 'ADMIN') {
        content = (
            <NotesStyles>
                <div className="note_container">
                    <div className="visible_block">
                    <span className="note-block">
                        <span className="switch-text-note text-muted">
                            Видимость для ученика
                        </span>

                        <label className="switch">
                            <input type="checkbox"
                                   onChange={onCheckboxChange}
                                   checked={studentVisibility}
                                   disabled={isCourse}/>
                            <span className="switch-slider"/>
                        </label>
                     </span>
                    </div>

                    <Editor
                        editorState={editorStateNote}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </NotesStyles>)
        content = isCourse && studentVisibility ? content : !isCourse && content
    }
    if (userType === 'STUDENT') {
        content = ((data.desc && data.visible) &&
            <NotesStyles>
                <div className="note_container">
                    <Editor
                        editorState={editorStateNote}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </NotesStyles>
        )
    }

    return (<>
        {content}
    </>)
};

export default MediaNote;
