import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const CleanWrapp = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  flex-grow: 1;
  
  .flex-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 12px 0;
  }

  .lesson {
    width: 360px;
    height: 360px;
    border-radius: 50%;
    object-fit: cover;
    
  }

  span {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: #697077;
    text-align: center;
  }

  .clear_title {

    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 16px;

  }
  
  button {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  @media ${baseTheme.media.small} {
    .lesson {
      width: 280px;
      height: 280px;
    }
  }
`;

export { CleanWrapp };
