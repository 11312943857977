import React from 'react';

import CourseCover from "../../../components/courseCover/courseCover";

import {CaptionBlockWrap} from './styled';

import settings from '../../../assets/media/icon/settings.svg';
import MainButton from "../../../components/buttons/mainButton/mainButton";

const CaptionBlock = ({course, isStudentCourse, onOpenDeleteModal, onBack, onEditCourse}) => {

  return (
    <CaptionBlockWrap>
      <div className="left">

        <CourseCover course={course.course_template} medium={true} courseTemplate={true}/>

        <div className="nameBlock">
          <div className={'name'}>{course.course_template.name}</div>
          {/*<div className={'desc'}>{course.small_desc}</div>*/}
        </div>

      </div>
      <div className={"btn-list"}>
        <MainButton
          text={'Назад'}
          type={'button'}
          width={'120'}
          func={onBack}
          classList={'btn-back'}
        />
        {!isStudentCourse && (
          <MainButton
            text={'Удалить'}
            type={'button'}
            width={'120'}
            func={onOpenDeleteModal}
            classList={'btn-del'}
          />
        )}
        {!isStudentCourse && !course.course_template.general && (
          <button
            className={'settings'}
            type={'button'}
            onClick={onEditCourse}
          >
            <img src={settings} alt="icon"/>
          </button>
        )}
      </div>
    </CaptionBlockWrap>
  )
}

export default CaptionBlock;
