import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const WithOutHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 10;
  background-color: #E5E5E5;
  
  @media ${baseTheme.media.medium} {
    background-color: ${baseTheme.colors.bgWhite};
  }

  .registration_block {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;

    a {
      font-style: normal;
      font-weight: normal!important;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-decoration-line: underline;
      color: #4F7FFF;
    }
  }
`

const SignInModal = styled.div`
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  background-color: #fff;
  max-width: 660px;
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 48px;
  margin-bottom: 32px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 24px;
    background-color: #fff;
  }

  @media ${baseTheme.media.medium} {
    padding: 24px 0;
    margin-bottom: 8px;
    box-shadow: none;

    .logo {
      width: 240px;
      height: 32px;
      margin-bottom: 16px;
    }

    h4 {
      font-size: ${baseTheme.fz.titlePageSmall};
      line-height: ${baseTheme.lh.titlePageSmall};
      margin-bottom: 32px;
    }
  }
`

const SignInModalWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  
  .registration_block {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;
    
    a {
      font-style: normal;
      font-weight: normal!important;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-decoration-line: underline;
      color: #4F7FFF; 
    }
    
  }
  @media ${baseTheme.media.medium} {
    justify-content: flex-start;

    .registration_block {
      text-align: center;
    }
  }
`

const TabsBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
`

export {
  WithOutHeaderContainer,
  SignInModal,
  SignInModalWrapp,
  TabsBody
}
