import axiosInstance from "../../service/iTeacherApi";
import {GET_ALL_COURSES, GET_COURSE, CLEAR_COURSE} from "../reducers/coursesReducer";

export const getAllAdminCourses = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/admin/courses-library/`);
      dispatch({type: GET_ALL_COURSES, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const getAllCourses = (userType) => {
  return async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/${userType}/courses/`);
      dispatch({type: GET_ALL_COURSES, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const getCourse = (userType, courseId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/${userType.toLowerCase()}/courses/${courseId}/`);
      dispatch({type: GET_COURSE, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const getStudentCourse = (courseId, studentId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/teacher/courses/${courseId}/student-course/?student=${studentId}`);
      dispatch({type: GET_COURSE, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const clearCourse = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_COURSE})
  }
}
