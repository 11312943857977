import styled from "styled-components";

import icon_play from "../../../../assets/media/icon/audio.svg";
import icon_change from "../../../../assets/media/icon/refresh.svg";
import {baseTheme} from "../../../../baseTheme";

const AudioWrap = styled.div`

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #DDE1E6;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  padding: 19px 24px;

  .video {

    &__container {

      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      background-color: #DDE1E6;
      margin-right: 16px;
      border-radius: 50%;

    }

    &__play {

      width: 18px;
      height: 18px;
      background: url(${icon_play}) center center/contain no-repeat;
      cursor: pointer;
    }

    &__wrap {

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &_1 {

        flex-direction: column;
        margin-right: auto;

      }

      &_2 {

        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

      }

    }

    &__text {
      overflow-wrap: anywhere;
    }

    &__subtext {

      font-size: 14px;
      line-height: 20px;
      color: #697077;

    }

    &__icon {

      display: block;
      width: 20px;
      height: 16px;
      background: url(${icon_change}) center center/cover no-repeat;
      margin-right: 9px;

    }

    &__remove {

      color: #4F7FFF;

    }

  }

  .delete_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 0;

    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      background-color: transparent;
      object-fit: contain;
    }

  }

  @media ${baseTheme.media.small} {
    padding: 16px 8px;

    .video__wrap_1 {
      max-width: calc(100vw - 100px);
    }
  }
  
`;

export {AudioWrap}
