import styled from "styled-components";

import icon_arrow_left from '../../assets/media/icon/arrow_left.svg';
import {baseTheme} from "../../baseTheme";

const HeaderWrap = styled.div`
  width: 100%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 0px 2px rgb(105 112 119 / 6%), 0px 2px 2px rgb(105 112 119 / 12%);
  position: sticky;
  top: 0;
  
  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 24px 48px;
    max-width: 1442px;
    width: 100%;
    margin: 0 auto;
  }
  
  & button {
    margin-bottom: 0!important;
  }

  @media ${baseTheme.media.extraLarge} {
    .container-header {
      padding: 16px 24px;
    }
  }

  @media ${baseTheme.media.large} {
    .container-header {
      padding: 16px;
    }
  }

  @media ${baseTheme.media.medium} {
    & button {
      padding: 8px;
      font-size: 12px;
      width: 157px;
    }
  }
`;

const IconArrow = styled.a`
  display: block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: url(${icon_arrow_left}) center center/cover no-repeat;
  cursor: pointer;
  &:focus {
      outline: transparent;
  }
`;

const HeaderInput = styled.input`

  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #111111;
  margin: 0 28px;
  border: none;
  flex-grow: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid transparent;

  &:hover {
    color: rgba(17, 17, 17, 0.6);
  }

  &:focus {
    outline: transparent;
    color: rgba(17, 17, 17, 0.6);
    border-color: #DDE1E6;
  }
  
  @media ${baseTheme.media.small} {
    font-size: ${baseTheme.fz.titlePageSmall};
    line-height: ${baseTheme.lh.titlePageSmall};
    line-height: 32px;
    width: calc(100% - 160px);
    margin-left: 16px;
    &:focus {
      width: calc(100% - 160px);
    }
  }
`;

export { HeaderWrap, IconArrow, HeaderInput };
