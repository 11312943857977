import React from 'react'

import TabCourses from "./tabCourses/tabCourses";

import * as Style from './styled.js'

const Tabs = ({data, studentId, handleOpenAddCourseModal}) => {
  return (
    <>
      <Style.TabsHead>
        <div
            className={`tabs courses`}
        >
          Курсы
        </div>
      </Style.TabsHead>
      <TabCourses data={data} studentId={studentId} handleOpenAddCourseModal={handleOpenAddCourseModal}/>
    </>
  )
}

export default Tabs
