import React, {useState} from 'react';

import MainInput from "../../../components/inputs/mainInput/mainInput";

import * as Style from './styled';

import google from '../../../assets/media/icon/google.svg';
import vk from '../../../assets/media/icon/vk.svg';
import facebook from '../../../assets/media/icon/facebook.svg';
import useFormAndValidation from "../../../hooks/useFormValidation";
import {useDispatch} from "react-redux";
import axiosInstance from "../../../service/iTeacherApi";
import {signIn} from "../../../store/actions/usersAction";
import {NavLink} from "react-router-dom";

const LoginTabs = () => {
  const dispatch = useDispatch();
  const [validation, setValidation] = useState(false);

  const {
    values,
    handleChange,
    errors,
    isValid,
  } = useFormAndValidation();
  const {email, password,} = values;

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const tokenResponse = await axiosInstance.post("/token/", {
        email: email.toLowerCase(),
        password: password,
      });

      axiosInstance.defaults.headers["Authorization"] =
          "JWT " + tokenResponse.data.access;
      localStorage.setItem("access_token", tokenResponse.data.access);
      localStorage.setItem("refresh_token", tokenResponse.data.refresh);

      dispatch(signIn())
      return tokenResponse;
    } catch (err) {
      setValidation(true);
    }
  };

  const handleInput = (e) => {
    setValidation(false);
    handleChange(e);
  }

  return (
      <>
        <Style.Form onSubmit={onLogin} disabledBtn={!isValid}>
          <MainInput required={false} type={'text'} label={'Email'} name={'email'} validation={errors.email || validation}
                     errorText={errors.email} updateData={handleInput}/>
          <MainInput required={true} type={'password'} label={'Пароль'} name={'password'} validation={errors.password || validation}
                     errorText={errors.password || (validation && 'Неверный логин или пароль')} updateData={handleInput}/>
          <div className={'forget'}>
            <p>Забыли пароль?</p>
            <NavLink to="/login-recovery">Восстановить пароль</NavLink>
          </div>
          <button
              className={'submit'}
              type='submit'
              disabled={!isValid}
          >Войти</button>
        </Style.Form>

        {/*<Style.SocialBLock>*/}
        {/*  <a><img src={google} alt="icon"/></a>*/}
        {/*  <a><img src={vk} alt="icon"/></a>*/}
        {/*  <a><img src={facebook} alt="icon"/></a>*/}
        {/*</Style.SocialBLock>*/}
      </>
  )
}
export default LoginTabs;
