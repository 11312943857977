import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Columns = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 16px;
  flex-wrap: wrap;

  @media ${baseTheme.media.small} {
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  width: 23%;
  margin: 0 0 16px 16px;
  &:last-child {margin-right: 0}
  border: 1px solid #DDE1E6;
  border-radius: 8px;
  
  .title-block {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #697077;
    background: rgba(221, 225, 230, 0.4);
    padding: 16px;
  }
  
  .words-block {
    height: max-content;
    min-height: 100px;
    padding: 16px 16px 0 16px;
    display: flex;
    flex-wrap: wrap;

    ${props => props.active
            && parseInt(props.active.task) === parseInt(props.task)
            && props.active.empty === props.empty
            && props.userType === 'teacher'
            && {
              border: '1px solid #885CFF'
            }}

    ${props => props.active
            && parseInt(props.active.task) === parseInt(props.task)
            && props.active.empty === props.empty
            && props.userType === 'student'
            && {
              border: '1px solid #4F7FFF'
            }}
  }

  @media ${baseTheme.media.small} {
    /*max-width: 130px;*/
    width: 100%;
    margin: 0 ;
    margin-bottom: 8px;
  }
`

export {Column, Columns};
