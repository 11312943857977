import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const InfoPopupWrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 531;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1,1,1, .2);
  padding: 16px;
`

const InfoPopupSuccessBody = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(105, 112, 119, 0.12), 0px 8px 16px rgba(105, 112, 119, 0.16);
  border-radius: 8px;
  width: 100%;
  max-width: 660px;
  min-height: 197px;
  display: flex;
  align-items: center;
  padding: 48px;
  flex-direction: column;
  
  img {
    width: 128px;
    height: 128px;
    margin-bottom: 15px;
  }
  
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #111111;
    margin-bottom: 32px;
    background-color: transparent;
    width: 100%;
  }

  @media ${baseTheme.media.small} {
    padding: 16px;
    
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 5px;
    }

    h2 {
      font-size: ${baseTheme.fz.bodyPopupSmall};
      line-height: ${baseTheme.lh.bodyPopupSmall}; 
      margin-bottom: 16px;
    }
  }
`

const InfoPopupSuccessBtn =styled.button`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background: #4F7FFF;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  padding: 14px 20px;
  width: 100%;
`
export {InfoPopupWrap, InfoPopupSuccessBody, InfoPopupSuccessBtn}
