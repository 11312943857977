import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';

import Preloader from '../../components/preloader/preloader';
import MainButton from '../../components/buttons/mainButton/mainButton';
import TasksContent from './tasksContent/tasksContent';

import axiosInstance from '../../service/iTeacherApi';
import {
    clearHomework,
    getHomework,
} from '../../store/actions/homeworksAction';
import {addCheckedTask} from '../../store/actions/tasksCheckedAction';

import {
    RoomBackLink,
    RoomBody,
    RoomHeader,
    RoomNav,
    RoomWrap
} from '../../components/ui/room/styled';


const HomeworkRoom = (
    {
        id,
        userType,
        homework,
        getHomework,
        clearHomework,
        addCheckedTask,
    }) => {

    const history = useHistory();

    useEffect(() => {
        if (homework.id) clearHomework();
        getHomework(userType, id, redirectHomeworksPage);
        return () => {
            clearHomework();
        };
    }, []);

    const redirectHomeworksPage = () => history.push('/homework');

    const handleCheckTask = async (answer, taskId, additionalInfo) => {
        try {
            const result = {
                answer,
                additional_info: additionalInfo || null,
                homework_id: Number(id)
            };
            const {data} = await axiosInstance.post(`student/homework/${taskId}/check-task/`, result);
            addCheckedTask(data)
        } catch (e) {
            console.log(e);
        }
    }

    const handleCompleteHomework = async () => {
        try {
            if (homework.status === 'ASSIGNED' || homework.status === 'REVISION') await axiosInstance.get(`student/homework/${id}/completed-homework/`);
            redirectHomeworksPage();
        } catch (e) {
            console.log(e);
        }
    }

    const handleVerifyHomework = async () => {
        // const isCompleted = homework.tasks.filter(({task_type}) => task_type === 'WRITE_TEXT').every(({completed}) => completed.completed === true)
        const isCompleted = homework.tasks.every(({completed}) => completed.completed === true)

        try {
            // if (homework.status === 'COMPLETE') await axiosInstance.get(`teacher/homework/${id}/verified-homework/`);
            if (homework.status === 'REVISION' || homework.status === 'COMPLETE') {
                !isCompleted
                    ? await axiosInstance.get(`teacher/homework/${id}/revision-homework/`)
                    : await axiosInstance.get(`teacher/homework/${id}/verified-homework/`)
            }
            redirectHomeworksPage();
        } catch (e) {
            console.log(e);
        }
    }

    const handleUpdateWritingText = async (text, taskId, userType) => {
        try {
            const result = {
                answer: text,
                additional_info: null,
                homework_id: Number(id)
            };
            await axiosInstance.post(`/teacher/homework/${taskId}/edit-task/`, result);
            await getHomework(userType, id,)
        } catch (e) {
            console.log(e);
        }
    }

    const handleCreateComment = async (userType, comment) => {
        try {
            await axiosInstance.post(`/teacher/comments/`, comment);
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpdateComment = async (userType, commentUpdateData) => {
        try {
            await axiosInstance.patch(`/teacher/comments/${commentUpdateData.id}/`, commentUpdateData);
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleVisibleComment = async (userType, taskId, visible) => {
        try {
            await axiosInstance.patch(`teacher/comments/${taskId}/`, {visible: visible})
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleVisibleForTeacher = async (userType, taskId, visible) => {
        try {
            await axiosInstance.patch(`student/update-completed-task/${taskId}/`, {
                sent_to_teacher: visible,
                homework_id: Number(id)
            })
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }


    const handleRevision = async (userType) => {
        try {
            await axiosInstance.get(`teacher/homework/${id}/revision-homework/`)
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleDoneTask = async (userType, dataRoom, taskId) => {
        try {
            await axiosInstance.patch(`tasks/${taskId}/set-complete-task/`, dataRoom)
            await getHomework(userType, id)
        } catch (e) {
            console.log(e)
        }
    }

    if (homework.id === undefined) return (
        <RoomWrap>
            <Preloader/>
        </RoomWrap>
    )

    const btnText = (homework.status === 'ASSIGNED' || homework.status === 'REVISION') && userType === 'student' ? 'Отправить на проверку '
        : (homework.status === 'COMPLETE' || homework.status === 'REVISION') && userType === 'teacher' ? 'Закончить проверку'
            : 'Закрыть'

    return (
        <RoomWrap className="slider">
            <RoomHeader className="homework">
                <div className="titleBlock">
                    <RoomBackLink onClick={history.goBack} className="header__arrow"/>
                    <p>
                        {homework.template.lesson.name}
                    </p>
                </div>
                <RoomNav>
                    <MainButton
                        text={btnText}
                        type={'button'}
                        width="full"
                        func={userType === 'teacher' ? handleVerifyHomework : handleCompleteHomework}
                    />
                </RoomNav>
            </RoomHeader>
            <RoomBody className={'container'} style={{maxWidth: '1124px'}}>
                    <TasksContent
                        handleVisibleForTeacher={handleVisibleForTeacher}
                        dataHomework={homework}
                        data={homework.tasks}
                        handleCheckTask={handleCheckTask}
                        createComment={handleCreateComment}
                        handleUpdateComment={handleUpdateComment}
                        handleVisibleComment={handleVisibleComment}
                        handleUpdateWritingText={handleUpdateWritingText}
                        handleRevision={handleRevision}
                        handleDoneTask={handleDoneTask}
                    />
            </RoomBody>
        </RoomWrap>
    );
};

const mapStateToProps = ({users, homeworks}) => ({
    user: users.user,
    userType: users.userType,
    homework: homeworks.homework
});

const mapDispatchToProps = (dispatch) => ({
    getHomework: (userType, id, catchFunc) => dispatch(getHomework(userType, id, catchFunc)),
    clearHomework: () => dispatch(clearHomework()),
    addCheckedTask: (data) => dispatch(addCheckedTask(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkRoom);
