import React, {useEffect, useState} from 'react';
import '../text/react-draft-wysiwyg.css';

import AdminModalTask from '../../adminModalTask/adminModalTask';
import MainButton from '../../../buttons/mainButton/mainButton';

import {
    FormBtnContainer,
    FormWrap
} from '../../styled';
import NoteInput from '../../../inputs/noteInput/noteInput';

const NoteModal = ({
                       edit,
                       back,
                       close,
                       addTask,
                       editTask
                   }) => {
    const [type, setType] = useState('add');
    const [taskId, setTaskId] = useState(null);
    const [studentVisibility, setStudentVisibility] = useState(false);
    const [editNoteValue, setEditNoteValue] = useState('');
    const [note, setNote] = useState({});
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage('')
    }, [editNoteValue])

    useEffect(() => {
        if (!!edit.id) {
            const desc = edit.desc
            //массив для NoteInput
            let arrDesc = [{id: edit.id, text: desc, visible: edit.visible}]

            setTaskId(edit.id)
            setType('edit');
            setStudentVisibility(edit.visible)
            setNote(arrDesc)
            setEditNoteValue(desc)
        }
    }, [])

    const handleCreateTask = (e) => {
        e.preventDefault();

        const task = {
            title: 'Заметка',
            desc: editNoteValue,
            task_type: 'NOTE',
            visible: studentVisibility
        }
        editNoteValue === '' ? setMessage('Заметка пустая') : addTask(task);
    }

    const handleEditTask = (e) => {
        e.preventDefault();
        const task = {
            title: 'Заметка',
            desc: editNoteValue,
            task_type: 'NOTE',
            visible: studentVisibility
        }
        editTask(taskId, task);
    }

    const editNote = (noteValue) => {
        const emptyEditor = '{"blocks":[],"entityMap":{}}'
        const text = noteValue.text
        if (text !== emptyEditor && text !== '') {
            setEditNoteValue(text)
        }
        setStudentVisibility(noteValue.visible)
    }

    return (
        <AdminModalTask
            type={type}
            back={back}
            title={'Заметка для учителя'}
            close={close}
        >
            <FormWrap
                onSubmit={(e) => {
                    type === 'add' ? handleCreateTask(e) : handleEditTask(e)
                }}
            >
                {/*        <FormHeadContainer>
          <div className="desc">Введите текст заметки</div>
          <div>
            <span className="switch-text text-muted">Видимость для ученика</span>
            <label className="switch">
              <input type="checkbox" checked={studentVisibility}
                     onChange={() => setStudentVisibility(!studentVisibility)}/>
              <span className="switch-slider"></span>
            </label>
          </div>
        </FormHeadContainer>*/}

                <div style={{padding: '25px'}}>
                    <NoteInput
                        type={type}
                        label={'Заметка'}
                        defaultValue={note}
                        desc_note={noteValue => editNote(noteValue)}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>{message !== '' && message}</div>
                <FormBtnContainer>
                    <MainButton
                        text={type === 'add' ? 'Добавить' : 'Сохранить'}
                        width={'full'}
                        type={'submit'}
                    />
                </FormBtnContainer>
            </FormWrap>
        </AdminModalTask>
    )
}

export default NoteModal;
