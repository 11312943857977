import React, {useState} from 'react';
import Dropzone from 'react-dropzone'

import Popup from '../popupContainer/popupContainer';
import Button from "../../buttons/button/button";

import {PopupTitle} from '../popupContainer/popupStyled';
import {CoverWrap} from './popupChangeCoverStyled';

import axiosInstance from "../../../service/iTeacherApi";
import {getAllAdminCourses, getAllCourses} from '../../../store/actions/coursesAction';
import {useDispatch} from "react-redux";

const PopupChangeCover = ({userType, course, setCourse, close}) => {
  const dispatch = useDispatch();

  // список градиентов
  const gradient = [
    "linear-gradient(60.64deg, #0093E9 0%, #80D0C7 100%)",
    "linear-gradient(240.64deg, #FFCC70 0%, #C850C0 53.65%, #4158D0 100%)",
    "linear-gradient(240.64deg, #E0C3FC 0%, #80D0C7 100%)",
    "linear-gradient(240.64deg, #F7CE68 0%, #FBAB7E 100%)",
    "linear-gradient(240.64deg, #00DBDE 0%, #FC00FF 100%)",
    "linear-gradient(240.64deg, #FFE53B 0%, #FF2525 100%)",
    "linear-gradient(240.64deg, #8BC6EC 0%, #9599E2 100%)",
    "linear-gradient(240.64deg, #2AF598 0%, #08AEEA 100%)",
    "linear-gradient(60.64deg, #0093E9 0%, #80D0C7 100%)",
    "linear-gradient(240.64deg, #FFCC70 0%, #C850C0 53.65%, #4158D0 100%)",
    "linear-gradient(240.64deg, #E0C3FC 0%, #80D0C7 100%)",
    "linear-gradient(240.64deg, #F7CE68 0%, #FBAB7E 100%)",
    "linear-gradient(60.64deg, #396AFC 0%, #2948FF 100%)",
    "linear-gradient(240.64deg, #6190E8 0%, #A7BFE8 100%)",
    "linear-gradient(240.64deg, #9CECFB 0%, #65C7F7 48.44%, #0052D4 100%)",
    "linear-gradient(240.64deg, #EE0979 0%, #FF6A00 100%)",
  ];

  // для переключения табов
  const [isFirstTab, setIsFirstTab] = useState(true);

  const uploadImage = async (file) => {
    const newCourse = { name: course.name, bg_type: 'image' };
    const data = new FormData();
    data.set("data", JSON.stringify(newCourse));
    data.set("background_image", file[0])

    try {
      const response = await axiosInstance.patch(`/${userType}/courses-library/${course.id}/`, data);
      setCourse(response.data);
      close();
      dispatch(getAllCourses(userType));
    } catch (e) {
      console.log(e);
    }
  }

  const changeBackground = async (background) => {
    const newCourse = { name: course.name, background, bg_type: 'gradient' };
    const data = new FormData();
    data.set("data", JSON.stringify(newCourse));
    try {
      const response = await axiosInstance.patch(`/${userType}/courses-library/${course.id}/`, data);
      setCourse(response.data);
      close();
      if(userType !== 'admin'){
        dispatch(getAllCourses(userType));
      }else{
        dispatch(getAllAdminCourses(userType))
      }

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Popup onClose={close}>
      <PopupTitle>Изменить обложку</PopupTitle>
      <CoverWrap>
        <div className="cover__row cover__row_1">
          <div
            // для переключения табов
            className={`cover__gradient ${isFirstTab ? 'cover__gradient_active' : ''}`}
            tabIndex="-1"
            // для переключения табов
            onClick={() => setIsFirstTab(true)}
          >
            Градиент
          </div>
          <div
            className={`cover__image ${!isFirstTab ? 'cover__image_active' : ''}`}
            tabIndex="-1"
            onClick={() => setIsFirstTab(false)}
          >
            Изображение
          </div>
        </div>

        {isFirstTab
          ? <div className={`cover__row cover__row_2`}>
            {/*выводим все градиенты*/}
            {gradient.map((item, key) => (
              <div
                key={key}
                className="cover__gradient-item"
                style={{background: item}}
                onClick={() => changeBackground(item)}
              />

            ))}
          </div>
          : <Dropzone onDrop={acceptedFile => uploadImage(acceptedFile)}>

            {({getRootProps, getInputProps, isDragActive}) => (

              <div {...getRootProps()} className={`cover__row cover__row_3`}>

                {isDragActive

                  ? <span className="cover__title">Загрузить!</span>

                  : <>

                    <span className="cover__title">Перетащите файл сюда</span>

                    <span className="cover__subtitle">или</span>

                    <Button
                      text="Выбрать файл на устройстве"
                      type="cover"
                    />

                  </>}

                <input {...getInputProps()} />

              </div>

            )}
          </Dropzone>}
      </CoverWrap>
    </Popup>

  );

}

export default PopupChangeCover;
