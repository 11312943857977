import styled from 'styled-components';

import icon_change from '../../../assets/media/icon/refresh.svg';
import {baseTheme} from '../../../baseTheme';

const ImageWrap = styled.div`

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 7px;

  .image {

    &__container {
      max-width: 100%;
      //min-width: 164px;
      max-height: 258px;
      //min-height: 200px;
      //position: relative;
      border-radius: 8px;
      object-position: center;
      object-fit: cover;
      padding: 3px;
    }

    &__wrap {

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &_1 {

        flex-flow: column nowrap;
        margin-right: auto;

      }

      &_2 {

        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

      }

    }

    &__subtext {

      font-size: 14px;
      line-height: 20px;
      color: #697077;

    }

    &__icon {

      display: block;
      width: 20px;
      height: 16px;
      background: url(${icon_change}) center center/cover no-repeat;
      margin-right: 9px;

    }

    &__remove {

      color: #4F7FFF;

    }

  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
    padding: 0 16px;
    flex-grow: 1;
    overflow: auto;
    align-items: flex-start;

    .image__container {
      width: 100%;
      min-height: auto;
      height: auto;
    }
  }
`;


const ColumnsWordForImg = styled.div`
  display: flex;
  width: 100%;
  //justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media ${baseTheme.media.small} {
    flex-wrap: wrap;
  }
`

const ColumnWordForImg = styled.div`
  width: 23%;
  margin: 0 0 16px 16px;

  &:last-child {
    margin-right: 0
  }

  border: 1px solid #DDE1E6;
  border-radius: 8px;
  padding-top: 15px;

  .title-block {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #697077;
    background: rgba(221, 225, 230, 0.4);
    padding: 16px;
  }

  .words-image-block {
    height: max-content;
    min-height: 100px;
    padding: 16px 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${props => props.active
            && parseInt(props.active.task) === parseInt(props.task)
            && props.active.empty === props.empty
            && props.userType === 'teacher'
            && {
              border: '1px solid #885CFF'
            }}

    ${props => props.active
            && parseInt(props.active.task) === parseInt(props.task)
            && props.active.empty === props.empty
            && props.userType === 'student'
            && {
              border: '1px solid #4F7FFF'
            }}
  }

  @media ${baseTheme.media.small} {
    /*max-width: 130px;*/
    width: 100%;
    margin: 0 ;
    margin-bottom: 8px;
  }
`

// export {Column, Columns};

export {ColumnsWordForImg, ColumnWordForImg, ImageWrap};

