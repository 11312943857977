import styled from 'styled-components';
import {baseTheme} from "../../../baseTheme";

//ЭТО ЗАГОТОВКА
const SpanText = styled.div`

  font-size: 14px;
  line-height: 20px;
  //padding: 0 25px;
  figure {
    margin-bottom: 10px;

    img {
      max-width: 362px;
      height: auto;
      object-fit: cover;
      width: 100%;
      border-radius: 4px;
    }
  }

  p {
    //word-break: break-all;
    word-break: normal;
    margin-bottom: 8px;
  }

  ul {
    padding: 0 16px;

    li {
      list-style-type: none;
      margin-bottom: 6px;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #4F7FFF;
        border-radius: 50%;
        left: -17px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }

  ol {
    padding: 0 16px;

    li {
      margin-bottom: 6px;
    }
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
    padding: 0 16px;
    flex-grow: 1;
    overflow: auto;
  }
`;

export const CommentStyles = styled.div`
  //margin: 0px -16px -16px -23px;
  background-color: #fefae6;
  overflow: hidden;
  border-radius:8px;

  @media (max-width: 540px) {
    margin: 0;
  }

  .note_container {
    background-color: #fefae6;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .visible_block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }
`;
/*const NotesStyles = styled.div`
  margin: 0px -16px -16px -23px;
  background-color: #fefae6;
  overflow: hidden;
  border-radius: 0 0 8px 8px;

  @media (max-width: 540px) {
    margin: 0;
  }

  .note_container {
    background-color: #fefae6;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .visible_block {
    display: flex;
    justify-content: space-between;
  }
`;*/
export const FormBtnContainer = styled.div`
  padding: 24px;

  @media ${baseTheme.media.medium} {
    padding: 16px;
  }
`

export const FormAddBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 22px;
  min-height: 22px;
  cursor: pointer;
  transition: .3s;

  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    object-position: center;
    display: block;
    margin-right: 11px;
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4F7FFF;
  }

  &:hover,
  &:focus {
    opacity: .6;
  }

  &:active {
    transform: translateY(2px);
  }
  
`
export const CheckedWrapWriteText = styled.div`
  width: calc(100% + 40px);
  height: 69px;
  background-color: ${props => props.status ? props.status : '#f8f9fb'};
  border: 1px solid #DDE1E6;
  margin: 23px -24px -16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0 0 8px 8px;

  .icon {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .text {
    color: ${props => props.status === '#24A1481A' ? '#24A148' : 'rgba(238,146,160,0.99)'};
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .closed {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }


  .btn__check {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    background-color: #4F7FFF;
    color: #FFFFFF;
    padding: 8px 14px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    width: 100vw;
    margin: 0;
    padding: 8px 4px;
    min-height: 57px;
    height: 57px;
    max-height: 57px;

    button {
      font-size: 14px!important;
      padding: 8px 12px !important;
    }

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .text {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media ${baseTheme.media.extraSmall} {
    button {
      padding: 8px !important;
    }
  }
`


export {SpanText}
