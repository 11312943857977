import styled from "styled-components";

import del from '../../../../assets/media/icon/trash_basket.svg'
import {baseTheme} from "../../../../baseTheme";

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:hover {
    .delete {
      opacity: 1;
      
      &_disabled {
        opacity: .5;
      }
    }
  }
  
  .wrapper-input:first-child {
    margin-right: 16px;
  }
  
  .delete {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(${del});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 16px;
    transition: all .3s;
    opacity: 0;
  }
  @media ${baseTheme.media.medium} {
    .delete {
      opacity: 1;
    }
  }
`

export {Question}
