import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import AddHomeworkModal from "../../components/modals/addHomeworkModal/addHomeworkModal";
import Preloader from "../../components/preloader/preloader";
import HomeworkBlock from "../../components/ui/infoBlock/homeworkBlock/homeworkBlock";
import LessonBlock from "../../components/ui/infoBlock/lessonBlock/lessonBlock";
import StudentBlock from "../../components/ui/infoBlock/studentBlock/studentBlock";
import UserAvatar from "./userAvatar/userAvatar";
import Tabs from "./tabs/tabs";
import StudentModal from "./studentModal/studentModal";
import AddCourseModal from "./addCourseModal/addCourseModal";
import LessonEdit from "../lessonEdit/lessonEdit";

import axiosInstance from "../../service/iTeacherApi";
import {openInfoPopup, setErrorInfoText, setSuccessInfoText} from "../../store/actions/infoPopupAction";
import {clearStudent, getStudent} from "../../store/actions/studentsAction";
import {clearCourse, getStudentCourse} from "../../store/actions/coursesAction";
import {
  addHomework,
  clearFilteredHomeworks,
  deleteHomework,
  getFilteredHomeworks
} from "../../store/actions/homeworksAction";

import * as Style from './styled'

const StudentCart = (
  {
    id,
    user,
    userType,
    student,
    isLoading,
    clearStudent,
    getStudent,
    getStudentCourse,
    clearCourse,
    clearFilteredHomeworks,
    courses,
    course,
    setSuccessInfoText,
    setErrorInfoText,
    openInfoPopup,
    getFilteredHomeworks,
    homeworks,
    addHomework,
    deleteHomework
  }
) => {

  const [tableData, setTableData] = useState([])

  const [showStudentModal, setShowStudentModal] = useState(false);

  const [isOpenAddCourseModal, setOpenAddCourseModal] = useState(false);

  const [isOpenAddHomeworkStepOneModal, setOpenAddHomeworkStepOneModal] = useState(false);

  const [isOpenAddHomeworkStepTwoModal, setOpenAddHomeworkStepTwoModal] = useState(false);

  const [selectedAddCourseId, setAddCourseId] = useState(null);

  const [homeworkData, setHomeworkData] = useState({course: null, lesson: null})

  const [more, setMore] = useState(false)

  useEffect(() => {
    clearStudent();
    clearCourse();
    clearFilteredHomeworks();
    return () => {
      clearCourse();
      clearFilteredHomeworks();
      clearStudent();
    }
  }, [])

  useEffect(() => {
    getStudent(id);
    getFilteredHomeworks(userType, `student=${id}`);
  }, [id])

  useEffect(() => {
    if (!homeworkData.course) return
    clearCourse();
    getStudentCourse(homeworkData.course, id);
  }, [homeworkData.course]);

  useEffect(() => {
    updateTableData();
  }, [student, id, showStudentModal]);

  const updateTableData = () => {
    if (!student) return

    const dataArray = [];

    if (student.user?.city) {
      dataArray.push({name: 'Город', value: student.user.city})
    }
    if (student.user?.phone) {
      dataArray.push({name: 'Телефон', value: student.user.phone})
    }
    if (student.user?.email) {
      dataArray.push({name: 'Почта', value: student.user.email})
    }

    setTableData(dataArray)
  }

  const handleAddCourse = async () => {
    try {
      await axiosInstance.post(`/teacher/invite-course/`, {
        "email": student.user.email,
        "course_id": selectedAddCourseId
      })
      getStudent(id);
      closeAllModal();
      setSuccessInfoText('Курс успешно добавлен')
    } catch (err) {
      if (err.response.data.email) {
        setErrorInfoText(err.response.data.email[0])
      } else if (err.response.data.info) {
        setErrorInfoText(err.response.data.info)
      } else {
        setErrorInfoText('Произошла ошибка, попробуйте добавить ученика на курс позже.')
      }
    } finally {
      openInfoPopup();
    }
  }

  const handleChangeCourse = (course) => course.id ? setAddCourseId(course.id) : setAddCourseId(null);

  const showMoreInformation = () => {
    if (more) {
      updateTableData();
      setMore(false)
    } else {
      const socials = student.user.socials ? JSON.parse(student.user.socials).map(social => {
        return {value: social.type, name: social.link}
      }) : [];

      setTableData([
        ...tableData,
        ...socials
      ])
      setMore(true)
    }
  };

  const handleSelectHomeworkCourse = (course) => setHomeworkData({...homeworkData, course: course.id, lesson: null});

  const handleSelectHomeworkLesson = (lesson) => setHomeworkData({...homeworkData, lesson: lesson.id});

  const handleHomeworkTaskModalOpen = (e) => {
    e.preventDefault();
    setOpenAddHomeworkStepTwoModal(true);
  };

  const handleCreateHomework = async (template, tasks) => {
    try {
      const data = {...homeworkData, student: id, template, tasks}
      await addHomework(userType, data);
      getFilteredHomeworks(userType, `student=${id}`);
      setHomeworkData({course: null, lesson: null, template: null, tasks: []});
      closeAllModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteHomework = async (homeworkId, closeFunc) => {
    try {
      await deleteHomework(homeworkId);
      getFilteredHomeworks(userType, `student=${id}`);
      closeFunc();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenAddCourseModal = () => setOpenAddCourseModal(true);

  const handleOpenAddHomeWorkModal = () => setOpenAddHomeworkStepOneModal(true);

  const handleReturnHomeworkStepOne = () => {
    setHomeworkData({...homeworkData, template: null, tasks: []})
    setOpenAddHomeworkStepTwoModal(false);
  }

  const closeAllModal = () => {
    setShowStudentModal(false);
    setOpenAddCourseModal(false);
    setAddCourseId(null);
    setOpenAddHomeworkStepOneModal(false);
    setOpenAddHomeworkStepTwoModal(false);
  }

  if (isLoading) return (
    <Style.Wrapper className={'container'}>
      <Preloader/>
    </Style.Wrapper>
  )
  return (
    <Style.Wrapper className={'container'}>
      <div className="left">
        <UserAvatar data={student.user}/>
        <StudentBlock
          data={tableData}
          isMore={more}
          onEditStudent={setShowStudentModal}
          onToggleMoreInfo={showMoreInformation}
        />
        <LessonBlock
          date={student.user.schedule}
          userType={userType}
        />
        <HomeworkBlock
          userType={userType}
          data={homeworks}
          onAddHomework={handleOpenAddHomeWorkModal}
          onDeleteHomework={handleDeleteHomework}
        />
      </div>
      <div className="right">
        <Tabs data={student} studentId={id} handleOpenAddCourseModal={handleOpenAddCourseModal}/>
      </div>
      {
        isOpenAddCourseModal &&
        <AddCourseModal courses={courses}
                        handleChangeCourse={handleChangeCourse}
                        handleSubmit={handleAddCourse}
                        close={closeAllModal}/>
      }
      {
        showStudentModal &&
        <StudentModal
          data={student}
          close={closeAllModal}
        />
      }
      {isOpenAddHomeworkStepOneModal &&
        <AddHomeworkModal
          courses={student?.user?.courses}
          lessons={course?.course_template?.lessons}
          data={homeworkData}
          onNextForm={handleHomeworkTaskModalOpen}
          onSelectCourse={handleSelectHomeworkCourse}
          onSelectLesson={handleSelectHomeworkLesson}
          closeModal={closeAllModal}
        />
      }
      {isOpenAddHomeworkStepTwoModal &&
        <LessonEdit
          type={'homework'}
          lessonId={homeworkData.lesson}
          onCreateHomework={handleCreateHomework}
          returnModal={handleReturnHomeworkStepOne}
        />
      }
    </Style.Wrapper>
  )
}

const mapStateToProps = ({users, students, courses, homeworks}) => {
  return {
    user: users.user,
    userType: users.userType,
    student: students.student,
    isLoading: students.isStudentLoading,
    courses: courses.courses,
    course: courses.course,
    homeworks: homeworks.homeworkFilteredList
  }
}

const mapDispatchToProps = (dispatch) => ({
  getStudent: (id) => dispatch(getStudent(id)),
  getStudentCourse: (courseId, studentId) => dispatch(getStudentCourse(courseId, studentId)),
  clearCourse: () => dispatch(clearCourse()),
  setSuccessInfoText: (data) => dispatch(setSuccessInfoText(data)),
  setErrorInfoText: (data) => dispatch(setErrorInfoText(data)),
  openInfoPopup: () => dispatch(openInfoPopup()),
  getFilteredHomeworks: (userType, query) => dispatch(getFilteredHomeworks(userType, query)),
  addHomework: (userType, data) => dispatch(addHomework(userType, data)),
  clearFilteredHomeworks: () => dispatch(clearFilteredHomeworks()),
  deleteHomework: (homeworkId) => dispatch(deleteHomework(homeworkId)),
  clearStudent: () => dispatch(clearStudent())
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentCart);
