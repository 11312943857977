import axiosInstance from "../../service/iTeacherApi";
import {
    CHECK_ORDER,
    CREATE_ORDER,
    GET_LESSON_PRICE,
    GET_PAYMENT_LINK, RESET_ORDER
} from "../reducers/orderReducer";

export const createOrder = (data) => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post(`/payment/order/add-order/`, data);
            dispatch({type: CREATE_ORDER, payload: response.data});
        } catch (error) {
            console.log(error)
        }
    }
}

export const payLessons = (count) => {
    return createOrder({
        count: count,
        type: 'LESSON',
    })
}

export const payCourse = (courseId) => {
    return createOrder({
        course: courseId,
        type: 'COURSE_SUBSCRIBE',
    })
}

export const getPaymentLink = (orderId) => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post(`/payment/order/${orderId}/pay/`);
            dispatch({type: GET_PAYMENT_LINK, payload: response.data});
            if (response.data.PaymentURL) {
                localStorage.setItem('orderIdPay', orderId);
                window.location.href = response.data.PaymentURL;
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const getLessonPrice = () => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.get(`/payment/lesson-items/ `);
            dispatch({type: GET_LESSON_PRICE, payload: response.data});
        } catch (error) {
            console.log(error)
        }
    }
}

export const checkOrder = (orderId) => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post(`/payment/check-status/${orderId}/`);
            dispatch({type: CHECK_ORDER, payload: response.data});
            localStorage.removeItem('orderIdPay');
        } catch (error) {
            console.log(error)
        }
    }
}
export const resetOrder = () => {
    return {
        type: RESET_ORDER,
        payload: null,
    }
}
