import { combineReducers } from "redux";
import userReducer from "./userReducer";
import coursesReducer from "./coursesReducer";
import studentsReducer from "./studentsReducer";
import infoPopupReducer from "./infoPopupReducer"
import addCoursePopupReducer from "./addCoursePopupReducer";
import {orderReducer} from "./orderReducer";
import schedulesReducer from "./schedulesReducer";
import homeworksReducer from "./homeworksReducer";
import tasksCheckedReducer from "./tasksCheckedReducer";

export const rootReducer = combineReducers({
  users: userReducer,
  courses: coursesReducer,
  students: studentsReducer,
  infoPopup: infoPopupReducer,
  addCoursePopup: addCoursePopupReducer,
  order: orderReducer,
  schedules: schedulesReducer,
  homeworks: homeworksReducer,
  tasksChecked: tasksCheckedReducer
})

export default rootReducer
