export const baseTheme = {
  colors: {
    bgBlack: '#000000',
    bgBlue: '#4F7FFF',
    bgDarkGrey: '#697077',
    bgGrey: '#E5E5E5',
    bgLightGrey: '#F8F9FB',
    bgStart: '#4F7FFF',
    bgSuccess: '#24A148',
    bgError: '#DA1E28',
    bgWhite: '#FFFFFF',
    linkBlue: '#4F7FFF',
    spinner: '#4F7FFF',
    textBlack: '#000000',
    textBlue: '#4F7FFF',
    textSuccess: '#24A148',
    textGrey: '#697077',
    textWhite: '#FFFFFF',
    bgActiveSection: '#edf2ff',
    activeSection: '#4F7FFF',
    hoverGray: '#f1f1f1',
    revision: '#EECD4FFF',
  },

  media: {
    extraLarge: '(max-width: 1140px)',
    large: '(max-width: 960px)',
    medium: '(max-width: 720px)',
    small: '(max-width: 540px)',
    extraSmall: '(max-width: 359px)',
    heightMedium: '(max-height: 620px)',
    heightSmall: '(max-height: 540px)'
  },

  fz: {
    titlePage: '28px',
    titlePageSmall: '22px',
    titlePopupSmall: '20px',
    bodyPopupSmall: '16px',
  },

  lh: {
    titlePage: '32px',
    titlePageSmall: '26px',
    titlePopupSmall: '24px',
    bodyPopupSmall: '20px',
  }
}
