import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const PageWrap = styled.div`
  width: 100%;
  padding-top: 122px;
  padding-bottom: 24px;
  min-height: calc(100% - 90px);

  @media ${baseTheme.media.extraLarge} {
    padding-top: 112px;
  }

  @media ${baseTheme.media.medium} {
    min-height: calc(100% - 174px);
    padding-bottom: 16px;

    .container {
      padding: 0 16px;
    }
  }

  @media ${baseTheme.media.small} {
    padding-top: 72px;
  }
`

export const PageHeadWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${baseTheme.media.medium} {
    flex-direction: column;
    margin-bottom: 16px;
    align-items: start;
  }
`

export const PageTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;

  @media ${baseTheme.media.medium} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const PageFilterBlock = styled.div`
  margin-left: 20px;
  display: flex;
  flex-grow: 2;

  @media ${baseTheme.media.medium} {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  
  div {
    margin-right: 24px;

    @media ${baseTheme.media.medium} {
      margin-right: 14px;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
`

export const PageNoItemsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 350px);
  
  @media ${baseTheme.media.medium} {
    min-height: auto;
  }
  
  p {
    max-width: 432px;
    margin-top: 40px;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${baseTheme.colors.textGrey};

    @media ${baseTheme.media.medium} {
      font-size: 24px;
      line-height: 32px;
    }
  }
`
