const initState = {
  homeworkList: [],
  homeworkFilteredList: [],
  homework: {},
  isLoading: true,
}

export const GET_ALL_HOMEWORKS = 'GET_ALL_HOMEWORKS';
export const GET_HOMEWORK = 'GET_HOMEWORK';
export const GET_FILTERED_HOMEWORKS = 'GET_FILTERED_HOMEWORKS';
export const CLEAR_FILTERED_HOMEWORKS = 'CLEAR_FILTERED_HOMEWORKS';
export const CLEAR_HOMEWORK = 'CLEAR_HOMEWORK';


const homeworksReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_HOMEWORKS:
      return {
        ...state,
        homeworkList: action.payload,
        isLoading: false
      }

    case GET_HOMEWORK:
      return {
        ...state,
        homework: action.payload
      }

    case GET_FILTERED_HOMEWORKS:
      return {
        ...state,
        homeworkFilteredList: action.payload
      }

    case CLEAR_FILTERED_HOMEWORKS:
      return {
        ...state,
        homeworkFilteredList: [],
      }

    case CLEAR_HOMEWORK:
      return {
        ...state,
        homework: {},
      }


    default:
      return state
  }
}

export default homeworksReducer;
