import React from "react";

import {ConfirmPopupWrap, ConfirmPopupSuccessBody, ConfirmPopupBtn} from './confirmPopupStyled';
import closed from "../../assets/media/icon/close.svg";

const ConfirmPopup = ({onConfirm, onClose, text, successText, cancelText="Отмена"}) => {

  return(
    <ConfirmPopupWrap>
      <ConfirmPopupSuccessBody>
        <img src={closed} alt="" className={'close'} onClick={onClose}/>
        <h2>{text}</h2>
        <div className="btnList">
          <ConfirmPopupBtn
            onClick={onConfirm}
            className={'yes'}>
            {successText}
          </ConfirmPopupBtn>
          <ConfirmPopupBtn
            onClick={onClose}
            className={'margin'}>
            {cancelText}
          </ConfirmPopupBtn>
        </div>
      </ConfirmPopupSuccessBody>
    </ConfirmPopupWrap>
  )
}
export default ConfirmPopup;
