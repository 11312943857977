import React from "react";

import MainInput from "../../../../components/inputs/mainInput/mainInput";
import * as Style from './styled'

const MainInfo = ({data}) => {
  return (
    <Style.Wrapper>
      <MainInput
        type={'text'}
        label={'Город'}
        grey
        name={'city'}
        defaultValue={data.user.city}
      />

      <MainInput
        type={'text'}
        label={'Телефон'}
        grey
        name={'phone'}
        defaultValue={data.user.phone}
      />

      <MainInput
        type={'email'}
        label={'Почта'}
        defaultValue={data.user.email}
        grey
        name={'email'}
      />

      <Style.Line/>
    </Style.Wrapper>
  )
}

export default MainInfo;
