import styled from "styled-components";

import dd from '../../../assets/media/icon/dd.png'
import {baseTheme} from "../../../baseTheme";

const Wrapper = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  background-color: #DDE1E6;
  border-radius: ${props => props.task ? '100px' : '8px'};
  height: ${props => props.task ? '44px' : '56px'};
  min-height: ${props => props.task ? '44px' : '56px'};
  position: relative;
  margin-left: ${props => props.task ? '5px' : '0px'};
  margin-right: ${props => props.task ? '5px' : '0px'};
  margin-bottom: ${props => props.task ? '10px' : '23px'};
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.6 : 1};

  &.wrapper-textarea {
    min-height: 224px;
    max-height: 300px;
    margin-bottom: 0;
  }

  &.task-input_head {
    margin-bottom: 0;
  }
  
  &.task-input_name {
    margin-top: 24px;
    margin-bottom: 0;
  }

  .label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${props => props.ValiddationBg ? 'red' : '#697077'};
    opacity: 0.8;
    transition: .3s;
    z-index: 1;

    &.active {
      top: ${props => props.task ? '9px' : '15px'};
      font-size: 12px;
      line-height: 18px;
      color: ${props => props.grey ? '#697077' : '#4F7FFF'};
      opacity: 0.8;
    }
  }

  .labelPass {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #697077;
    opacity: 0.8;
    transition: .3s;
    z-index: 1;

    &.active {
      top: 15px;
      font-size: 12px;
      line-height: 18px;
      color: #4F7FFF;
      opacity: 0.8;
    }
  }

  .input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 10px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #111111;
    opacity: 0.8;
    padding-top: ${props => props.task ? '17px' : '20px'};
    padding-bottom: ${props => props.task ? '17px' : '10px'};
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    cursor: pointer;
  }

  textarea {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 10px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #111111;
    opacity: 0.8;
    resize: none;
    min-height: 224px;
  }

  .select-arrow {
    position: absolute;
    background-image: url(${dd});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
    margin-right: 0 !important;
    right: 12px;
    transform: rotate(0deg);

  }

  .arrow {
    position: absolute;
    background-image: url(${dd});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
    margin-right: 0 !important;
    right: 12px;
    transform: rotate(180deg);
    transition: .3s;

    &.active {
      transform: rotate(0deg);
    }
  }

  &.valid {
    background: linear-gradient(0deg, rgba(218, 30, 40, 0.1), rgba(218, 30, 40, 0.1)), #FFFFFF;
    border: 1px solid rgba(218, 30, 40, 0.1);

    .label {
      color: red;
    }
  }

  .errorText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #DA1E28;
    opacity: 0.8;
    margin-left: 14px;
    margin-top: 3px;
  }

  .fake {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: .2;
    left: 0;
    top: 0;
    z-index: 12;
    display: ${props => props.fakeBg ? 'block' : 'none'};
  }

  @media ${baseTheme.media.medium} {
    margin-bottom: ${props => props.task ? '10px' : '23px'};

    &.task-input_head {
      margin-bottom: 0;
    }

    &.task-input_name {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  @media ${baseTheme.media.small} {
    .select-arrow {
      right: 4px;
    }

    &.wrapper-textarea {
      min-height: auto;
      max-height: unset;
      height: 100%;
    }
  }


`

const DropLIstItems = styled.ul`
  width: 100%;
  box-shadow: rgb(105 112 119 / 12%) 0px 0px 16px, rgb(105 112 119 / 16%) 0px 8px 16px;
  background: #fff;
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  top: calc(100% + 8px);
  left: 0;
  padding: 0 16px;
  overflow: auto;
  max-height: 160px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .5) rgba(221, 225, 230, 0.5);
  
  &.maxHeight {
    max-height: 500px;

    @media ${baseTheme.media.heightSmall} {

      max-height: 200px;
    }
  }

  .listItem {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #111111;
    min-height: 48px;
    cursor: pointer;
    border-bottom: 1px solid #DDE1E6;
    overflow: hidden;
    
    &.teacher {
      justify-content: space-between;
      
      .teacher__name {
        color: ${baseTheme.colors.textGrey};
      }
    }
    &.reset{
      justify-content: center;
      min-height: 40px;
      position: sticky;
      top: 0;
      background-color: ${baseTheme.colors.bgWhite};
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .listItem:last-child {
    border-bottom: none;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgba(221, 225, 230, 0.5);
  }
`

const Label = styled.div`
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #697077;
  width: fit-content;
  background-color: transparent;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  &.active {
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    left: 16px;
    top: 8px;
    transform: none;
  }
`

export {
  Wrapper,
  DropLIstItems,
  Label
}
