import React from "react";

import MainDropList from "../../../components/inputs/mainDropList/mainDropList";
import MainButton from "../../../components/buttons/mainButton/mainButton";

import {TextModalBody, TextModalOverlay} from "./styled";

import closed from "../../../assets/media/icon/close.svg";

const AddCourseModal = ({close, courses, handleChangeCourse, handleSubmit}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  }

  return (
    <TextModalOverlay>
      <TextModalBody
        className={'EventModalBody'}
        onSubmit={onSubmit}
      >
        <img onClick={close} className={'closed'} src={closed} alt="icon"/>
        <h2 className={'title'}>Добавить на курс</h2>

       <MainDropList
          label={'Выберите курс'}
          name={'course'}
          required={true}
          options={courses}
          onChange={handleChangeCourse}
        />

        <MainButton
          text='Добавить'
          type='submit'
          width='full'
        />
      </TextModalBody>

    </TextModalOverlay>
  )
}

export default AddCourseModal;
