import React, {useEffect, useState} from 'react';

import AdminModalTask from '../../adminModalTask/adminModalTask';
import MainInput from '../../../inputs/mainInput/mainInput';
import MainButton from '../../../buttons/mainButton/mainButton';
import SimpleTextArea from '../../../inputs/simpleTextArea/simpleTextArea';
import * as Style from '../sentenceOfWords/style';

import {
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap
} from '../../styled';
import NoteInput from '../../../inputs/noteInput/noteInput';

const TransferWords = ({
                           edit,
                           back,
                           close,
                           addTask,
                           editTask
                       }) => {
    const [type, setType] = useState('add');
    const [taskTitle, setTaskTitle] = useState('');
    const [taskText, setTaskText] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [editNoteValue, setEditNoteValue] = useState({});
    const [note, setNote] = useState({});

    useEffect(() => {
        if (!!edit.id) {
            setType('edit');
            setTaskText(edit.desc);
            setTaskId(edit.id);
            setTaskTitle(edit.title);
            setNote(edit.notes)
        }
    }, [])

    const handleCreateTask = (e) => {
        e.preventDefault();
        let value = e.target.text.value
        let valueWithSpace = value.split('[').join(' ' + '[')
        let valueWithSpaces = valueWithSpace.split(']').join(']'+' ')

        const task = {
            title: taskTitle,
            desc: valueWithSpaces,
            task_type: 'TRANSFER_WORDS',
            note: editNoteValue,
        };

        addTask(task);
    }

    const handleEditTask = (e) => {
        e.preventDefault();
        let value = e.target.text.value
        let valueWithSpace = value.split('[').join(' ' + '[')
        let valueWithSpaces = valueWithSpace.split(']').join(']'+' ')
        const task = {
            title: taskTitle,
            desc: valueWithSpaces,
            task_type: 'TRANSFER_WORDS',
        };

        const noteData = {
            note: editNoteValue,
            noteId: note[0].id,
        }

        editTask(taskId, task, noteData);
    }

    return (
        <AdminModalTask
            type={type}
            back={back}
            title={'Перенести слова'}
            close={close}
        >
            <FormWrap
                onSubmit={(e) => {
                    type === 'add' ? handleCreateTask(e) : handleEditTask(e)
                }}
            >
                <FormHeadContainer>
                    <MainInput
                        className={'task-input_head'}
                        type={'text'}
                        label={'Условия упражнения'}
                        name={'title'}
                        required={true}
                        defaultValue={taskTitle}
                        updateData={e => setTaskTitle(e.target.value)}
                    />
                    <NoteInput
                        type={type}
                        label={'Заметка'}
                        defaultValue={note}
                        desc_note={noteValue => setEditNoteValue(noteValue)}
                    />
                    <Style.TextBlock>
                        <div className="title">Текст задания</div>
                        <div className="desc">Напишите текст. Слова и фразы, которые нужно
                            вставить из рамочки, заключите в
                            квадратные
                            скобки. В конце каждого предложения ставьте точку или запятую.
                        </div>
                    </Style.TextBlock>
                </FormHeadContainer>
                <FormMainContainer className={'padding noTopBotPadding'}>
                    <SimpleTextArea
                        name={'text'}
                        required={true}
                        placeholder={'Пример: I like [walking] in the park in the morning.'}
                        defaultValue={taskText}
                    />
                </FormMainContainer>
                <FormBtnContainer>
                    <MainButton
                        text={type === 'add' ? 'Добавить' : 'Сохранить'}
                        width={'full'}
                        type={'submit'}
                    />
                </FormBtnContainer>
            </FormWrap>
            {/*children*/}
        </AdminModalTask>
    )
}

export default TransferWords;
