import React from "react";

import MainInput from "../../../inputs/mainInput/mainInput";

import {Answer} from './styled';

const Checkbox = ({data, handleToggleCheckbox, handleChangeValue, onDelete}) => {
  return (
    <Answer>
      <input
        className={'checkbox'}
        type="checkbox"
        name={'result'}
        checked={data.result}
        onChange={handleToggleCheckbox}
      />
      <MainInput
        className={'task-input_head'}
        type={'text'}
        label={'Ответ'}
        name={'answer'}
        required={true}
        defaultValue={data.value}
        updateData={handleChangeValue}
      />
      <div onClick={onDelete} className="delete"/>
    </Answer>
  )
}

export default Checkbox;
