import styled from 'styled-components';
import {baseTheme} from '../../../../baseTheme';


const ImageBlockZone = styled.div`
  .image__block {

    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 33%;
    min-height: 200px;
    display: flex;
    left: 28.75%;
    right: 58.19%;
    top: 47.67%;
    bottom: 31.44%;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;

    /*    &_plus{
          position: absolute;
          left: 12.5%;
          right: 12.5%;
          top: 12.5%;
          bottom: 12.5%;
    
          background: #4F7FFF;
        }*/
  }

  .badDescription {
    background-color: #bbbec2;
  }

  .upload {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  .upload__button {
    width: 200px;
    min-height: 200px;
    border: 2px;
    border-radius: 8px;

  }

  .input__wrapper {
    position: relative;
    text-align: center;
  }

  .input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  .input__file-button-text {
    line-height: 1;
    margin-top: 1px;
    width: 200px;
    border: 2px;
    border-radius: 8px;
    color: #4F7FFF;
  }

  .input__file-button {
    border: 2px dashed #DDE1E6;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 198px;
    height: 200px;
    color: #a9aeb4;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    cursor: pointer;
    padding: 2px;
  }

  .prompt {
    width: 571px;
    height: 22px;
    left: 414px;
    top: calc(50% - 22px / 2 - 56px);

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #697077;
  }

  .focusBlock {
    position: relative;
    cursor: pointer;
  }

  .img {
    width: 198px;
    height: 200px;
    border-radius: 8px;
    padding: 2px;
  }

  .block {
    display: none;
  }

  .image__block:hover div.block {
    display: flex;
    //background-color: #111111;
    //opacity: 0.5;
    z-index: 9999;
    position: absolute;
    //top:0;
    top: 35%;
    right: 35%;
    bottom: 35%;
    left: 35%;
  }
`

export {ImageBlockZone}
