import React, {Component} from 'react';

import AdminModalTask from '../../adminModalTask/adminModalTask';
import MainButton from '../../../buttons/mainButton/mainButton';
import MainInput from '../../../inputs/mainInput/mainInput';
import icon_delete from '../../../../assets/media/icon/trash_basket.svg';


import {ImageBlockZone} from './styled'
import {
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap
} from '../../styled';
import NoteInput from '../../../inputs/noteInput/noteInput';
import NewSize from './resizeImg/newSize';

export default class WordForPicture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            descriptionText: '',
            files: [],
            imagesPreviewUrls: [],
            words: [],
            file: '',
            imagePreviewUrl: '',
            type: 'add',
            taskId: '',
            errMessage: '',
            url: '',
            ImgWordBlocks: [],
            word_to_images_remove: [],
        }
        this._handleImageChange = this._handleImageChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this.setResizeImg = this.setResizeImg.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.file = React.createRef();
        }, 1000)
        this.setTypeModal();
    }

    setTypeModal = () => {
        const {edit} = this.props;
        if (!!edit.id) {
            this.setState({
                type: 'edit',
                text: edit.title,
                file: edit.file,
                ImgWordBlocks: edit.words_to_image,
                word_to_images_remove: [],
                taskId: edit.id,
                name: edit.name_video,
                note: edit.notes,
            });
           // Проверка на null для админки
           /* edit.notes!== null ? this.setState({noteId: edit.notes[0].id}) : this.setState({noteId:[]})
            edit.notes!== null ? this.setState({note: edit.notes}) : this.setState({note:[]})*/
            const note = edit.notes[0]
            if(note && note.id){
                this.setState({noteId: note.id})
            }
        }
    }

    createTask = (e) => {
        e.preventDefault();

        const words = this.state.ImgWordBlocks.map(({word}) => word)
        const isWord = words.includes('')

        if (isWord) {
            this.setState({errMessage: 'Напишите слово'})
            setTimeout(() => {
                this.setState({errMessage: ''})
            }, [3000])
        } else {
            const data = new FormData();

            this.state.ImgWordBlocks.forEach(({image}) => data.append('image_to_word', image))
            this.state.ImgWordBlocks.forEach(({word}) => data.append('image_info', word.split(',')))

            data.set('task_type', 'WORD_TO_IMAGE');
            data.set('title', this.state.text);
            data.set('note', JSON.stringify(this.state.editorNote));

            this.props.addTask(data);
        }

    }

    editTask = async (e) => {
        e.preventDefault();
        const words = this.state.ImgWordBlocks.map(({word}) => word)
        const isWord = words.includes('')

        if (isWord) {
            this.setState({errMessage: 'Напишите слово'})
            setTimeout(() => {
                this.setState({errMessage: ''})
            }, [3000])
        } else {
            const data = new FormData();

            data.set('task_type', 'WORD_TO_IMAGE');
            data.set('title', this.state.text);

            if (this.state.ImgWordBlocks.length > 0) {
                this.state.ImgWordBlocks.forEach((block) => {
                    if (!block.id) {
                        data.append('image_to_word', block.image)
                    }
                })
                this.state.ImgWordBlocks.forEach((block) => {
                    if (!block.id) {
                        data.append('image_info', block.word.split(','))
                    }
                })
            }

            if (this.state.word_to_images_remove.length > 0) {
                this.state.word_to_images_remove.forEach(id => data.append('word_to_images_remove', id))
            }

            this.state.ImgWordBlocks.forEach(el => {
                const update_words = {}
                if (!!el.id) {
                    update_words[el.id] = el.word
                    data.append('update_words', JSON.stringify(update_words))
                }
            })

            const note = {
                note: this.state.editorNote,
                noteId: this.state.noteId,
            }

            this.props.editTask(this.state.taskId, data, note);
        }

    }

    // add image function
    _handleSubmit(e) {
        //Sumbit handler
        /*      e.preventDefault();
              const formData = new FormData();
              formData.append('image', this.state.file);
              axios.get('/api/upload', formData)
                  .then(response => {
                      console.log(response)
                  });*/
    }

    setResizeImg(img) {
        if (!!img) {
            this.setState({
                ImgWordBlocks: [...this.state.ImgWordBlocks,
                    {image: img, word: this.state.descriptionText},
                ],
                url: '',
                descriptionText: '',
            })
        }
    }

    _handleImageChange(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);

        files.forEach((file) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                const image = reader.result

                this.setState({
                    /*  files: [...this.state.files, file],
                      words: [...this.state.words, this.state.descriptionText],*/
                    // imagesPreviewUrls: [...this.state.imagesPreviewUrls, {
                    //     image,
                    //     word: this.state.descriptionText
                    // }],
                    // descriptionText: '',
                    url: image,
                });
            }
            reader.readAsDataURL(file);
        });

    }


    render() {
        const {back, close} = this.props;

        return (
            <AdminModalTask
                type={this.state.type}
                back={back}
                title={'Слово к картинке'}
                close={close}
            >
                <FormWrap onSubmit={(e) => {
                    this.state.type === 'add' ? this.createTask(e) : this.editTask(e)
                }}>
                    <FormHeadContainer>
                        <MainInput
                            className={'task-input_head'}
                            type={'text'}
                            label={'Условия упражнения'}
                            name={'title'}
                            required={true}
                            defaultValue={this.state.text}
                            updateData={e => this.setState({text: e.target.value})}
                        />
                        <NoteInput
                            type={this.state.type}
                            label={'Заметка'}
                            defaultValue={this.state.note}
                            desc_note={noteValue => this.setState({editorNote: noteValue})}
                        />

                        <div style={{height: '15px', paddingTop: '10px'}}>
                            {this.state.errMessage !== '' &&
                            <span className="switch-text-note text-muted">
                        У картинки нет слова
                    </span>}
                        </div>

                    </FormHeadContainer>

                    <FormMainContainer className={'padding noTopBotPadding'}>

                        <ImageBlockZone>
                            <div>
                                <form onSubmit={this._handleSubmit}>
                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <div>
                                            <div className="input__wrapper">
                                                <input name="file" type="file"
                                                       id="input__file"
                                                       className="input input__file"
                                                       accept="image/*"
                                                       onChange={this._handleImageChange}
                                                       multiple
                                                />
                                                <label htmlFor="input__file"
                                                       className="input__file-button">
                                                    <span
                                                        className="input__file-button-text">
                                                        Добавить изображение
                                                    </span>
                                                </label>
                                            </div>
                                            <div style={{
                                                maxWidth: '200px',
                                                padding: '7px 2px',
                                            }}>
                                                <MainInput
                                                    className={'description'}
                                                    type={'text'}
                                                    label={'Слово'}
                                                    name={'description'}
                                                    required={false}
                                                    defaultValue={this.state.descriptionText}
                                                    updateData={e => this.setState({descriptionText: e.target.value})}
                                                />
                                            </div>
                                        </div>

                                        {this.state.url !== '' ?
                                            <NewSize url={this.state.url}
                                                     setResizeImg={this.setResizeImg}/>
                                            : this.state.ImgWordBlocks.map((imageData, key) => {
                                                {/*{this.state.imagesPreviewUrls.map((imageData, key) => {*/
                                                }
                                                return (

                                                    <>

                                                        <div className={`image__block`}
                                                             key={key}>

                                                            <div className="focusBlock">
                                                                <img className="img"
                                                                     alt="previewImg"
                                                                     src={imageData.image}
                                                                />
                                                                <div className="block"
                                                                     onClick={() => {
                                                                         this.setState({word_to_images_remove: [...this.state.word_to_images_remove, imageData.id]})
                                                                         const ImgWordBlocks = this.state.ImgWordBlocks.filter(({image}) => imageData.image !== image)
                                                                         this.setState({ImgWordBlocks})
                                                                     }}><img
                                                                    src={icon_delete}
                                                                    alt="Удалить"/>
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                maxWidth: '200px',
                                                                padding: '2px',
                                                            }}>

                                                                <MainInput
                                                                    className={imageData.word === '' ? 'badDescription' : 'description'}
                                                                    type={'text'}
                                                                    label={imageData.word === '' ? `${this.state.errMessage === '' ? 'У картинки нет пары' : this.state.errMessage}` : 'Слово'}
                                                                    name={'description'}
                                                                    required={true}
                                                                    defaultValue={imageData.word}
                                                                    updateData={e => {
                                                                        this.setState(prevState => ({
                                                                            ImgWordBlocks: prevState.ImgWordBlocks.map(
                                                                                obj => (obj.image === imageData.image ? Object.assign(obj, {word: e.target.value}) : obj)
                                                                            )
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                    </div>
                                </form>
                            </div>
                        </ImageBlockZone>
                    </FormMainContainer>
                    <FormBtnContainer>
                        <MainButton
                            text={this.state.type === 'add' ? 'Добавить' : 'Сохранить'}
                            width={'full'}
                            type={'submit'}
                        />
                    </FormBtnContainer>
                </FormWrap>
            </AdminModalTask>
        )
    }
}
