const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || {
    login: '',
    password: '',
    photo: '',
    status: null
  },
  users: [],
  userType: JSON.parse(localStorage.getItem("user"))?.type.toLowerCase() || null,
  topAlert: false,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    // получаем все пользователей, при разработке системы польтзователей нужно от этого избавиться
    case 'GET_ALL_USERS':
      return {
        ...state,
        users: action.users
      }

    // передаем данные пользователя
    case 'LOGIN_USER':
      const newUser = {
        ...action.user,
        status: true
      }
      return {
        ...state,
        user: newUser,
        userType: newUser.type.toLowerCase()
      }

    case 'SHOW_TOP_ALERT':
      return {
        ...state,
        topAlert: action.text
      }

    default:
      return state;
  }
}

export default userReducer;
