import React from 'react';
import {useHistory} from "react-router";
import {connect} from "react-redux";

import {getLessonTimeString} from "../../../service/timePrettier";

import * as Style from './styled'

import ava from "../../../assets/media/icon/avatar.svg";
import more from "../../../assets/media/icon/more.svg";
/*import lesson from '../../../assets/media/icon/lesson.svg';*/

const StudentItem = ({data, onRepeatInviteSend}) => {
  const history = new useHistory();

  const handleInviteRepeat = (e) => {
    onRepeatInviteSend(e, data.email);
  }

  return (
    <Style.CoursesItem
      key={data.id}
      className={'courseItems'}
      onClick={() => history.push(`/students/${data.id}`)}
    >
      <div className="right">
        <div className={"right__name"}>
          <img src={data.photo ? data.photo : ava} alt=""/>
          <div className="names">
            <h3>{data.username}</h3>
            <p>{data.email}</p>
          </div>
        </div>
      </div>

      <div className="left">
        <Style.Lesson>
          {data.status_confirm ? (
            <>
              <h4 className={'small-title'}>Ближайший урок</h4>
              <p className={'small-desc'}>{data.schedule ? `${getLessonTimeString(data.schedule.start_time)}` : 'Не назначен'}</p>
            </>
          ) : (
            <>
              <h4 className={'small-title'}>Неактивен</h4>
              <button onClick={handleInviteRepeat} className={'invite-repeat'}>Отправить приглашение повторно</button>
            </>
          )}
        </Style.Lesson>

        <div className={'navSection'}>
          {/*          <div className="lesson">
            <img className={'openNotes'} onClick={() => note(data.id)} src={lesson} alt="icon"/>
            <div className='number'>0</div>
          </div>*/}
          <button
            className="more"
          ><img src={more} alt="img"/></button>
        </div>
      </div>
    </Style.CoursesItem>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  }
}

export default connect(mapStateToProps)(StudentItem);
