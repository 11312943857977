import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import CheckedBlock from '../checkedBlock/checkedBlock';

import {DocumentWrap} from './mediaDocumentStyled';
import Notes from '../notes/notes';

const MediaDocument = ({data, handleCheckTask, userType, tasks}) => {
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
    }, [tasks]);

    const handleCheck = () => {
        handleCheckTask('', data.id)
    };

    const handleFalseCloseChecked = () => setCompleted(false);

    let format = data.file?.split('/media/');
    if (!!format?.length) format = format[1].split('.')[1];
    return (
        <>
            <DocumentWrap>
                <div className="video__container">
                    <span className="video__play"/>
                </div>
                <a download={data.file} href={data.file} target={'_blank'}
                   className="video__wrap video__wrap_1">
                    <span className="video__text">{data.name_video}</span>
                    <span className="video__subtext">{format}, {data.file_size}</span>
                </a>
            </DocumentWrap>

            <div style={{paddingTop: '15px'}}>
                <Notes notes={data.notes} userType={userType}/>
            </div>

            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
                isSimple
            />
            }
        </>
    )
}

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
};


export default connect(mapStateToProps)(MediaDocument);
