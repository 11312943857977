import React from 'react';

import AddCourse from "./styled";
import plus from "../../../assets/media/icon/plus-blue.svg";

const AddCourseButton = ({clickHandle, text}) => {
  return (
    <AddCourse onClick={clickHandle}>
      <div className="addSquare">
        <img src={plus} alt="plus"/>
      </div>
      <div className="textAddCourse">
        {text}
      </div>
    </AddCourse>
  )
}

export default AddCourseButton;
