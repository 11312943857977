import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

export const HomeworkAddInstruction = styled.div`
  width: 100%;
  padding: 16px 20px;
  background-color: ${baseTheme.colors.bgWhite};
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgb(105 112 119 / 6%), 0px 2px 2px rgb(105 112 119 / 12%);
  margin-bottom: 15px;

  @media ${baseTheme.media.medium} {
    font-size: 14px;
    line-height: 18px;
  }
`
