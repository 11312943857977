import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import AddEventModal from "./addEventModal/addEventModal";
import List from "./list/list";
import ScheduleEmpty from "./scheduleEmpty/scheduleEmpty";

import {ScheduleWrap, Title, AddCourse, Caption} from './styled';

import plus from "../../assets/media/icon/plusW.svg";

const Schedules = ({teacher, user, schedules}) => {
  const [EventModal, setEventMModal] = useState(false);

  const closeEventModal = () => {
    setEventMModal(false)
  }

  return (
    <>
      {
        schedules.length > 0
          ? (
            <ScheduleWrap>
              <Caption>
                <Title>Расписание</Title>
                {/*если это логин пользователя*/}
                {
                  teacher && (
                    <AddCourse
                      className={'openModal'}
                      onClick={() => setEventMModal(true)}
                    >
                      Добавить событие
                      <img src={plus} alt="icon"/>
                    </AddCourse>
                  )
                }
              </Caption>
              {/*список событий*/}
              {/*пока просто костыль*/}
                  <List
                    schedules={schedules}
                    type={user.type}
                  />


              {/*список событий*/}
            </ScheduleWrap>
          ) : (
            <ScheduleEmpty teacher={teacher} open={setEventMModal}/>
          )
      }


      {
        EventModal && (
          <AddEventModal
            courses={user.courses}
            close={closeEventModal}
            user={user}
          />
        )
      }
    </>
  )
}

const mapStateToProps = ({users, schedules}) => {
  return {
    user: users.user,
    schedules: schedules.scheduleList
  }
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
