import React, {useEffect, useRef, useState} from 'react';
import {InputWrapper, Invite} from "./addStudentModalStyled";
import {useSelector} from "react-redux";
import ava from "../../../assets/media/icon/avatar.svg";
import {DropLIstItems} from "../../../components/inputs/mainInput/styled";

const InviteLink = ({value, onInput, setStudent}) => {
  const {students} = useSelector(state => state.students)
  const [studentList, setStudentList] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const selectEl = useRef(null);
  const selectListEl = useRef(null);

  useEffect(() => {
    if (students.length > 0) {
      const filteredStudents = students.sort((a, b) => a.username.localeCompare(b.username)).filter(student => student.username.toLowerCase().includes(value.toLowerCase()));
      setStudentList(filteredStudents);
    }
  }, [students, value]);

  const handleChangeStudent = (email) => {
    setShowStatus(false);
    setStudent(email);
  }

  const dropdownList = studentList.map((item) => {
    return (
      <li
        className={'listItem'}
        key={item.id}
        onClick={() => handleChangeStudent(item)}
      >
        <img className="ava" src={item.photo ? item.photo : ava} alt=""/>
        <p>{item.username}</p>
      </li>)
  })

  const handleOpenDropdownList = () => {
    setShowStatus(true);
  }

  const closeOutsideClick = (e) => {
    // проверяем был ли клик по списку
    if (selectListEl.current && !selectListEl.current.contains(e.target)) {
      // проверяем был ли до клика открыт список и если он открыт то закрываем
      if (selectEl.current && !selectEl.current.contains(e.target)) {
        setShowStatus(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeOutsideClick);
  }, []);

  return (
    <Invite>
      <InputWrapper>
        <input type="text" placeholder='Введите имя ученика' value={value} ref={selectEl}
               onChange={(e) => onInput(e, handleOpenDropdownList, students)} onClick={handleOpenDropdownList}/>
        {
          showStatus && (
            <DropLIstItems
              ref={selectListEl}
              className={'dropListWrap'}
            >
              {dropdownList}
            </DropLIstItems>
          )
        }
      </InputWrapper>
    </Invite>
  )
}

export default InviteLink;
