import React, {useEffect, useState} from "react";

import {Wrapper} from "../mainInput/styled";

const SimpleInput = ({name, type, handleChange, required, errorText, validation, defaultValue, className}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);

  const changeValue = (e) => {
    if (handleChange === undefined) {
      setValue(e.target.value)
    } else {
      handleChange(e)
    }
  }


  return (
    <Wrapper className={`wrapper-input ${validation && 'valid'} ${className}`}>
      <input className={'input'} name={name} required={required} onChange={changeValue} type={type} value={value}/>
      {
        validation
          ? <div className={'errorText'}>{errorText}</div>
          : null
      }
    </Wrapper>
  )
}

export default SimpleInput;
