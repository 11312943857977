import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import MemberBlock from "../../../components/ui/infoBlock/memberBlock/memberBlock";
import LessonBlock from "../../../components/ui/infoBlock/lessonBlock/lessonBlock";
import HomeworkBlock from "../../../components/ui/infoBlock/homeworkBlock/homeworkBlock";

import {ActiveSidebarWrap} from "../styled";

const RightSidebar = (
  {
    info,
    showAddStudent,
    course,
    lessons,
    schedules,
    homeworks,
    showAddEvent,
    user,
    userType,
    isStudentCourse,
    isInactiveCourse,
    onDeleteHomework
  }
) => {

  const [lesson, setLesson] = useState(null)


  useEffect(() => {
    if (lessons && lessons.length > 0 && user.type === 'TEACHER') {
      let lesson = lessons[0];

      if (!!schedules) {
        for (let key in lessons) {
          for (let i in schedules) {
            if (lessons[key].id === schedules[i].lesson) {
              lesson = lessons[key];
              lesson.count = (Number(key) + 1);
            }
          }
        }
      }
      setLesson(lesson)
    }
  }, [lessons, schedules])

  return (
    <ActiveSidebarWrap>
      <MemberBlock
        list={userType === 'teacher' ? isStudentCourse ? course?.student : course.students : course?.teacher}
        isInactiveCourse={isInactiveCourse}
        userType={userType}
        show={showAddStudent}
        isStudentCourse={isStudentCourse}
      />
      {isStudentCourse ?
        <LessonBlock
          lesson={lesson}
          date={info.schedule}
          userType={userType}
          show={showAddEvent}
          isInactiveCourse={isInactiveCourse}
        />
        : null}
      {isStudentCourse ?
        <HomeworkBlock
          userType={userType}
          data={homeworks}
          onDeleteHomework={onDeleteHomework}
        />
        : null}
    </ActiveSidebarWrap>)
}

const mapStateToProps = ({users, courses, homeworks}) => ({
  user: users.user,
  userType: users.userType,
  course: courses.course,
  lessons: courses.course.course_template.lessons,
  schedules: courses.course.schedules,
  homeworks: homeworks.homeworkFilteredList
})

export default connect(mapStateToProps, null)(RightSidebar);
