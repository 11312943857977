import React, {useEffect, useRef, useState} from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import Centrifuge from 'centrifuge';
import {connect} from 'react-redux';

import {LessonHeader, LessonNav, LessonBody, LessonWrap} from './styled'
import IconDictionary from '../../assets/media/icon/dictionary.svg';
import MainButton from '../../components/buttons/mainButton/mainButton';
import LeftSideBar from './leftSideBar/leftSideBar';
import MainContent from './mainContent/mainContent';
import axiosInstance from '../../service/iTeacherApi';
import {useHistory} from 'react-router';
import useAgora from '../../hooks/useAgora';
import {signIn} from '../../store/actions/usersAction';
import {addCheckedTask} from '../../store/actions/tasksCheckedAction';
import {getAllSchedules} from '../../store/actions/schedulesAction';
import Preloader from '../../components/preloader/preloader';
import {RoomBody, RoomHeader, RoomNav, RoomWrap} from '../../components/ui/room/styled';
import axios from 'axios';

const wsURL = `${process.env.REACT_APP_WS_URL}`;

const client = AgoraRTC.createClient({codec: 'h264', mode: 'rtc'});

const ClassRoom = ({id, user, signIn, addCheckedTask, getAllSchedules}) => {
    const [activeSection, setActiveSection] = useState(0);
    const [data, setData] = useState({});
    const history = useHistory();
    const [centrifugeRoom, setCentrifugeRoom] = useState({});
    const [channel, setChannel] = useState('');
    const {
        localVideoTrack, leave, join, remoteUsers, toggleLocalTracksMute
    } = useAgora(client);
    const sliderRef = useRef();

    useEffect(async () => {
        let dataId
        try {
            const {data} = await axiosInstance.get(`/classroom/connect/${id}/`);
            setData(data);
            setCentrifugeRoom(setConnection(data.name, user.chat_token));
            dataId = data.id
        } catch (e) {
            console.log(e);
            history.push('/schedule');
        }
        try {
            const response = await axiosInstance.get(`/agora/get-token/${dataId}/`);
            const {token, classroom} = response.data;
            setChannel(classroom);
            await join('1e5872894ddd4dfa821b3c5d86cff61e', classroom, token, user.id);

        } catch (e) {
            console.log(e);
        }
        window.addEventListener('beforeunload', handleCloseClassRoom);
        return () => {
            window.removeEventListener('beforeunload', handleCloseClassRoom);
            window.__ARTC__.__TRACK_LIST__.forEach(track => {
                track.stop();
                track.close();
            })
            handleCloseClassRoom();
        }
    }, []);
/*    let selObj = window.getSelection();

    // alert(selObj);
    let selRange = selObj.getRangeAt(0);
    // вернёт диапазон Range
    useEffect(() => {
        console.log(selObj)
    }, [selObj])*/

    const setConnection = (channel, jwt) => {
        const centrifuge = new Centrifuge(wsURL);
        centrifuge.setToken(jwt);
        centrifuge.subscribe(channel, (res) => handleCentrifugeChannel(res, centrifuge))
            .on('join', function (e) {
                console.log('join', e);
            })
            .on('leave', function (e) {
                console.log('leave', e);
            })
            .on('unsubscribe', function (e) {
                console.log('unsubscribe', e);
            })
            .on('subscribe', function (e) {
                console.log('subscribe', e);
            })
            .on('connect', function (data) {
                console.log('connect', data);
            })
            .on('message', function (data) {
                console.log('message', data);
            })
            .on('disconnect', function (data) {
                console.log('disconnect', data);
            });
        centrifuge.connect();
        return centrifuge
    }

    const handleCentrifugeChannel = ({data}, centrifuge) => {
        switch (data.type) {
            case 'task': {
                addCheckedTask(data)
                break
            }
            case 'changeSection': {
                setActiveSection(data.activeSection);
                sliderRef.current?.swiper.slideTo(0, 0);
                break
            }
            case 'end': {
                if (user.type === 'STUDENT') {
                    window.__ARTC__.__TRACK_LIST__.forEach(track => {
                        track.stop();
                        track.close();
                    })
                    leave().then(() => {
                        centrifuge.disconnect();
                        history.goBack();
                        getAllSchedules();
                        signIn();
                        window.__ARTC__.__TRACK_LIST__.forEach(track => {
                            track.stop();
                            track.close();
                        })
                    })
                }
                break
            }
            /*    default:{
                    return
                }*/
        }
    }

    const handleCloseClassRoom = () => {
        axiosInstance.get(`/classroom/disconnect/${id}/`);
        leave();
        centrifugeRoom.disconnect();
        signIn();
        history.goBack();
    }

    const handleCompleteClassRoom = () => {
        if (user.type === 'TEACHER') axiosInstance.get(`/classroom/complete-schedule/${id}/`);
        handleCloseClassRoom();
    }

    const handleCheckTask = async (answer, taskId, additionalInfo) => {
        try {
            const result = {
                answer,
                additional_info: additionalInfo || null,
                // classroom_id: Number(id)
                classroom_id: Number(data.id)
            };
            await axiosInstance.post(`classroom/check-task/${taskId}/`, result);

        } catch (e) {
            console.log(e);
        }
    }

    const handleChangeNextSection = () => {
        if (activeSection + 1 < data.lesson_id.sections.length) {
            centrifugeRoom.publish(channel, {
                type: 'changeSection',
                activeSection: activeSection + 1
            }).then(function (res) {
                console.log('successfully published', res);
            }, function (err) {
                console.log('publish error', err);
            });
        } else {
            console.log('Все секции пройдены')
        }
    }

    const handleChangePrevSection = () => {
        if (activeSection - 1 >= 0) {
            centrifugeRoom.publish(channel, {
                type: 'changeSection',
                activeSection: activeSection - 1
            }).then(function (res) {
                console.log('successfully published', res);
            }, function (err) {
                console.log('publish error', err);
            });
        } else {
            console.log('Вы на первой секции')
        }
    }

    const handleVisibleForTeacher = async (userType, taskId, visible) => {
        try {
            await axiosInstance.patch(`student/update-completed-task/${taskId}/`, {
                sent_to_teacher: visible,
                classroom_id: data.id,
                homework_id: null
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleCreateComment = async (userType, comment) => {
        try {
            await axiosInstance.post(`/teacher/comments/`, comment);
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpdateComment = async (userType, commentUpdateData) => {
        try {
            await axiosInstance.patch(`/teacher/comments/${commentUpdateData.id}/`, commentUpdateData);
        } catch (e) {
            console.log(e)
        }
    }

    const handleDoneTask = async (userType, dataRoom, taskId) => {
        try {
            await axiosInstance.patch(`tasks/${taskId}/set-complete-task/`, dataRoom)
        } catch (e) {
            console.log(e)
        }
    }

    if (data.id === undefined) return (
        <RoomWrap>
            <Preloader/>
        </RoomWrap>
    )

    return (
        <RoomWrap>
            <RoomHeader>
                <div className="titleBlock">{data.lesson_id.name}</div>
                <RoomNav>
                    <MainButton
                        text={user.type === 'TEACHER' ? 'Завершить занятие' : 'Выйти из занятия'}
                        type={'button'}
                        width="full"
                        func={handleCompleteClassRoom}
                    />
                </RoomNav>
            </RoomHeader>
            <RoomBody
                className={'container'}
            >

                <LeftSideBar
                    data={data.lesson_id.sections}
                    user={user}
                    companion={user.type === 'TEACHER' ? data.student.user : data.teacher.user}
                    activeSection={activeSection}
                    localVideoTrack={localVideoTrack}
                    remoteUsers={remoteUsers}
                    toggleLocalTracksMute={toggleLocalTracksMute}
                />
                <MainContent
                    sliderRefLink={sliderRef}
                    data={data.lesson_id.sections}
                    user={user}
                    classroomId={data.id}
                    activeSection={activeSection}
                    handleCheckTask={handleCheckTask}
                    nextSection={handleChangeNextSection}
                    prevSection={handleChangePrevSection}
                    handleVisibleForTeacher={handleVisibleForTeacher}
                    createComment={handleCreateComment}
                    handleUpdateComment={handleUpdateComment}
                    handleDoneTask={handleDoneTask}
                />
            </RoomBody>
        </RoomWrap>
    )
}

const mapStateToProps = ({users}) => {
    return {
        user: users.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    signIn: () => dispatch(signIn()),
    getAllSchedules: () => dispatch(getAllSchedules('student')),
    addCheckedTask: (data) => dispatch(addCheckedTask(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassRoom);


const Convert = ({text, language = 'Russian'}) => {
    const [convertedText, setConvertedText] = useState('');

    useEffect(() => {
        const response = axios
            .post(
                'https://translation.googleapis.com/language/translate/v2',
                {},
                {
                    params: {
                        q: text,
                        target: language,
                        key: 'AIzaSyCHUCmpR7cT_yDFHC98CZJy2LTms-IwDlM'
                    }
                }
            )
            .then((response) => {
                setConvertedText(response.data.data.translations[0].translatedText);
            })
            .catch((err) => {
                console.log('rest api error', err);
            });
    }, [text, language]);

    return <div>{convertedText}</div>;
};