import styled from 'styled-components';

const NotesStyles = styled.div`
  margin: 0px -16px -16px -23px;
  background-color: #fefae6;
  overflow: hidden;
  border-radius: 0 0 8px 8px;

  @media (max-width: 540px) {
    margin: 0;
  }

  .note_container {
    background-color: #fefae6;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .visible_block {
    display: flex;
    justify-content: space-between;
  }
`;

export {NotesStyles};
