const initState = {
  isOpenPopup: false
}
export const OPEN_COURSE_POPUP = 'OPEN_COURSE_POPUP';
export const CLOSE_COURSE_POPUP = 'CLOSE_COURSE_POPUP';

const addCoursePopupReducer = (state = initState, action) => {
  switch (action.type) {

    case OPEN_COURSE_POPUP:
      return {
        ...state,
        isOpenPopup: true
      }

    case CLOSE_COURSE_POPUP:
      return {
        ...state,
        isOpenPopup: false,
      }

    default:
      return state
  }
}

export default addCoursePopupReducer;
