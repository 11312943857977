import React from 'react';

import MainInput from "../../../components/inputs/mainInput/mainInput";

import * as Style from './styled';


import useFormAndValidation from "../../../hooks/useFormValidation";
import {useDispatch} from "react-redux";
import axiosInstance from "../../../service/iTeacherApi";
import {NavLink, useHistory} from "react-router-dom";
import {openInfoPopup, setSuccessInfoText} from "../../../store/actions/infoPopupAction";

const LoginRecoveryTabs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    values,
    handleChange,
    setErrors,
    errors,
    isValid,
  } = useFormAndValidation();

  const {email} = values;

  const onLoginRecovery = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/users/send-password/", {
        email: email.toLowerCase(),
      });
      history.push("/login");
      dispatch(setSuccessInfoText('Мы выслали новый пароль на почту\n' +
        email));
      dispatch(openInfoPopup());
    } catch (err) {
      console.log(err)
      if (err.response?.data?.info === "Указанный пользователей не существует") {
        setErrors({...errors, email: 'Не найден пользователь с таким email'});
      } else {
        setErrors({...errors, email: 'Ошибка при восстановлении пароля'});
      }
    }
  };

  return (
      <>
        <Style.Form onSubmit={onLoginRecovery} disabledBtn={!isValid}>
          <MainInput required={false} type={'text'} label={'Email'} name={'email'} validation={errors.email}
                     errorText={errors.email} updateData={handleChange}/>
          <div className={'buttons'}>
            <NavLink to="/login">Отмена</NavLink>
            <button
              className={'submit'}
              type='submit'
              disabled={!isValid}
            >Восстановить</button>
          </div>
        </Style.Form>
      </>
  )
}
export default LoginRecoveryTabs;
