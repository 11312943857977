import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";

import {DragWordsWrap, WordsSection, Word, EmptyWord} from '../mediaDragWords/dragWordsStyled';
import * as Style from './style'
import ResetProgress from "../resetProgress/resetProgress";
import Notes from "../notes/notes";

const MediaSentence = ({data, handleCheckTask, userType, tasks, taskType}) => {
  const [dataList, setData] = useState([]);
  const [emptyList, setEmpty] = useState([]);
  const [activeWord, setActive] = useState(null);
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    if (!data.id) return;
    if (data.completed?.completed) {
      displayingTask(data.completed?.task, true);
    } else if (data.completed?.have_answer) {
      const additional_info = JSON.parse(data.completed?.additional_info);
      setData(additional_info.dataList);
      setEmpty(additional_info.emptyList);
    } else {
      displayingTask(data.completed?.task || data.mixed_desc || data.task, false);
    }
  }, [data]);

  const displayingTask = (task, isCompleted) => {
    const empty = task.split('/').map((word, key) => {
      return {
        id: key,
        word: word,
        activeWord: isCompleted ? word : false
      }
    });
    if (!isCompleted) setData(task.split('/'));
    setEmpty(empty);
  }

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id)
    if (taskChecked) {
      setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
      if (taskChecked.additional_info === JSON.stringify({dataList, emptyList})) {
        const additional_info = JSON.parse(taskChecked.additional_info);
        setData(additional_info.dataList);
        setEmpty(additional_info.emptyList);
      }
    }
  }, [tasks]);

  const dndStart = (e, word, key) => {
    e.target.style.opacity = '0.4';
    setTimeout(() => {
      setActive({word, key});
      const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
      const allDnDWord = document.querySelectorAll('.' + classNameDrag);
      allDnDWord.forEach(word => {
        word.classList.remove(classNameDrag)
      })
      e.target.classList.add(classNameDrag)
    })
  }

  const dndEnd = (e) => {
    e.target.style.opacity = '1';
    const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
    e.target.classList.remove(classNameDrag);
  }

  const dndEnter = (e) => e.target.classList.add('dnd-hovered');

  const dndOver = (e) => e.preventDefault();

  const dndLeave = (e) => e.target.classList.remove('dnd-hovered')

  const dndDrop = (e, block) => {
    document.querySelectorAll('.dnd-hovered').forEach(block => {
      block.classList.remove('dnd-hovered')
    })

    setEmptyWord(block)
  }

  const setEmptyWord = (block) => {
    if (!activeWord?.word) return
    const indexBlock = emptyList.findIndex(empty => parseInt(empty.id) === parseInt(block.id));
    const newEmptyList = [
      ...emptyList.slice(0, indexBlock),
      {
        ...block,
        activeWord: activeWord.word
      },
      ...emptyList.slice(indexBlock + 1)
    ]
    setEmpty(newEmptyList);
    setData(dataList.filter(i => i !== activeWord.word));
    setActive(null);
    handleFalseCloseChecked();
  }

  const setActiveWord = (word, key) => setActive({word, key});

  const handleCheck = () => {
    const answer = emptyList.map(item => item.activeWord).join('/');
    const additionalInfo = {dataList, emptyList};
    handleCheckTask(answer, data.id, JSON.stringify(additionalInfo));
  }

  const handleFalseCloseChecked = () => setCompleted(false);

  const handleResetProgress = () => displayingTask(data.completed?.task || data.mixed_desc || data.task, false);

  const emptyBlock = emptyList.map(block => {
    if (block.activeWord) {
      return <Word key={block.id}>{block.activeWord}</Word>
    } else {
      return (
          <div style={{display:"flex"}}>
            {taskType === 'text' && <span style={{margin: '7px', fontSize: '17px'}}>{block.id + 1}</span>}
            <Style.EmptyItem
                task={data.id}
                empty={block.id}
                userType={userType}
                className={'dnd-hover'}
                key={block.id}
                onClick={() => setEmptyWord(block)}
                onDragEnter={dndEnter}
                onDragLeave={dndLeave}
                onDragOver={dndOver}
                onDrop={(e) => dndDrop(e, block)}
                taskType={taskType}
            />
          </div>
          )
    }
  })
  return (
    <>
      <DragWordsWrap>
        <WordsSection className={!!handleCheckTask ? "words_classroom" : ""}>
          {dataList.length > 0 ? dataList.map((word, key) => (
            <Word
              key={word + key}
              onClick={() => setActiveWord(word, key)}
              isSelect={activeWord?.key === key}
              userType={userType}
              word={word}
              task={data.id}
              draggable="true"
              onDragStart={(e) => {
                dndStart(e, word, key)
              }}
              onDragEnd={dndEnd}
            >
              {word}
            </Word>
          )) : <EmptyWord/>}
        </WordsSection>

        <Style.EmptyBlock mt>
          {emptyBlock}
        </Style.EmptyBlock>

      </DragWordsWrap>

      <Notes notes={data.notes} userType={userType}/>

      {handleCheckTask &&
        <CheckedBlock
          userType={userType}
          completed={completed}
          handleCheck={handleCheck}
          closeFalseChecked={handleFalseCloseChecked}
        >
          {handleCheckTask && completed !== '#24A1481A' && <ResetProgress handleReset={handleResetProgress}/>}
        </CheckedBlock>}
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaSentence);
