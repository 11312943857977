import React, {Component} from 'react';
import './react-draft-wysiwyg.css';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

import bold from '../../../../assets/media/icon/bold.svg';
import italic from '../../../../assets/media/icon/italic.svg';
import underline from '../../../../assets/media/icon/underline.svg';
import unordered from '../../../../assets/media/icon/mark_list.svg';
import link from '../../../../assets/media/icon/link.svg';
import image from '../../../../assets/media/icon/photo.svg';

import {uploadFile} from '../../../../service/iTeacherApi';
import {
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap
} from '../../styled';
import AdminModalTask from '../../adminModalTask/adminModalTask';
import MainButton from '../../../buttons/mainButton/mainButton';
import MainInput from '../../../inputs/mainInput/mainInput';
import {ImageZone} from '../image/styled';
import Dropzone from 'react-dropzone';
import Button from '../../../buttons/button/button';

export default class WriteTextModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            file: '',
            imagePreviewUrl: '',
            type: 'add',
            taskId: 0,
            editorState: EditorState.createEmpty(),
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.file = React.createRef();
        }, 1000)
        this.setTypeModal();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            editorContentHtml: stateToHTML(editorState.getCurrentContent()),
        });
    };

    setTypeModal = () => {
        const {edit} = this.props;
        if (!!edit.id) {
            const editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(edit.desc)
                )
            )
            this.setState({
                type: 'edit',
                text: edit.title,
                name: edit.name_video,
                file: edit.file,
                taskId: edit.id,
                editorState,
                editorContentHtml: stateToHTML(editorState.getCurrentContent()),
            });
        }
    }

    createTask = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.set('task_type', 'WRITE_TEXT');
        data.set('title', this.state.text);
        data.set('desc', this.state.editorContentHtml);

        if (this.state.file !== '') {
            data.set('file', this.state.file);
        }

        this.props.addTask(data);
    }

    editTask = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.set('task_type', 'WRITE_TEXT');
        data.set('title', this.state.text);
        data.set('desc', this.state.editorContentHtml);

        if (this.props.edit.file !== this.state.file) {
            data.set('file', this.state.file);
        }

        this.props.editTask(this.state.taskId, data);
    }

    // add image function
    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file)
    }

    uploadImage(image) {
        let reader = new FileReader();
        let file = image[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
    }

    render() {
        const {back, close} = this.props;
        const {type, editorState} = this.state;

        const {imagePreviewUrl, file} = this.state;
        let $imagePreview = null;

        if (imagePreviewUrl || file) {
            $imagePreview = (
                <ImageZone style={{marginBottom:"24px"}}>
                    <img alt={'img'} className={'bannerVisible'}
                         src={imagePreviewUrl ? imagePreviewUrl : file}/>
                    <input
                        ref={this.file}
                        accept="image/*"
                        className={'hidden-input'}
                        id="contained-button-file"
                        name={'banner'}
                        onChange={(e) => this._handleImageChange(e)}
                        type={'file'}
                        />
                </ImageZone>
            );
        } else {

            $imagePreview = (
                <Dropzone onDrop={acceptedFile => this.uploadImage(acceptedFile)}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div style={{maxHeight:'200px'}}>
                            <div {...getRootProps()}
                                 className={`cover__row cover__row`}>
                                {isDragActive
                                    ? <span className="cover__title">Загрузить!</span>
                                    : <div style={{display:"flex",    alignItems: 'center',
                                        flexDirection: "column",padding:"15px"}}>
                                    <span
                                        className="cover__title">Перетащите файл сюда</span>
                                        <span className="cover__subtitle">или</span>
                                        <Button
                                            text="Выбрать файл на устройстве"
                                            type="cover"
                                        />
                                    </div>}

                                <input
                                    ref={this.file}
                                    {...getInputProps()}
                                    accept="image/*"
                                    className={'hidden-input'}
                                    id="contained-button-file"
                                    name={'banner'}
                                    onChange={(e) => this._handleImageChange(e)}
                                />
                            </div>
                        </div>
                    )}
                </Dropzone>
            );
        }


        return (
            <AdminModalTask
                type={type}
                back={back}
                title={'Сочинение'}
                close={close}
            >
                <FormWrap onSubmit={(e) => {
                    type === 'add' ? this.createTask(e) : this.editTask(e)
                }}>
                    <FormHeadContainer>
                        <MainInput
                            className={'task-input_head'}
                            type={'text'}
                            label={'Условия упражнения/Название'}
                            name={'title'}
                            required={true}
                            defaultValue={this.state.text}
                            updateData={e => this.setState({text: e.target.value})}
                        />
                    </FormHeadContainer>

                    <FormMainContainer className={'padding noTopBotPadding'}>
                        <span style={{opacity:'0.6'}}>Здесь Вы можете давать любые письменные задания (эссе, письмо другу, резюме и т.д.)</span>
                        {$imagePreview}

                        <Editor
                            defaultEditorState={editorState}
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={this.onEditorStateChange}
                            placeholder={"Пропишите дополнительные условия к упражнению"}
                            toolbar={{
                                options: ['inline', 'image', 'link', 'list',],
                                inline: {
                                    inDropdown: false,
                                    className: 'media icon_font',
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic', 'underline'],
                                    bold: {icon: bold, className: undefined},
                                    italic: {icon: italic, className: undefined},
                                    underline: {icon: underline, className: undefined},
                                },
                                list: {
                                    inDropdown: false,
                                    className: 'media icon_list',
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['unordered', 'ordered'],
                                    unordered: {icon: unordered, className: undefined},
                                },
                                link: {
                                    inDropdown: false,
                                    className: 'media icon_link',
                                    component: undefined,
                                    popupClassName: undefined,
                                    dropdownClassName: undefined,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link'],
                                    link: {icon: link, className: undefined},
                                    linkCallback: undefined
                                },
                                image: {
                                    icon: image,
                                    className: 'media',
                                    component: undefined,
                                    popupClassName: undefined,
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: false,
                                    uploadCallback: uploadFile,
                                    alt: {present: true, mandatory: false},
                                    previewImage: true,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    defaultSize: {
                                        height: 'auto',
                                        width: '233px',
                                    },
                                },
                            }}
                        />

                    </FormMainContainer>
                    <FormBtnContainer>
                        <MainButton
                            text={type === 'add' ? 'Добавить' : 'Сохранить'}
                            width={'full'}
                            type={'submit'}
                        />
                    </FormBtnContainer>
                </FormWrap>
            </AdminModalTask>
        )
    }
}
