import React from "react";

import LoginRecoveryTabs from "./loginRecoveryTabs/loginRecoveryTabs";

import * as Style from "./styled";

const LoginRecovery = () => {
  return (
    <Style.WithOutHeaderContainer>
      <div className={"container"}>
        <Style.SignInModalWrapp>
          <Style.SignInModal>
            <h4>Восстановление пароля</h4>
            <Style.TabsBody>
              <LoginRecoveryTabs />
            </Style.TabsBody>
          </Style.SignInModal>
        </Style.SignInModalWrapp>
      </div>
    </Style.WithOutHeaderContainer>
  );
};

export default LoginRecovery;
