import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Wrapper = styled.div`
  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
    padding: 0 16px;
    flex-grow: 1;
    overflow: auto;
  }
`

const Container = styled.div`
  border: 2px dashed #DDE1E6;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  width: 100%;

  @media ${baseTheme.media.small} {
    padding: 8px;
  }
`

const Col = styled.div`
  width: 100%;
  min-height: 100px;
  padding-left: 16px;

  &:first-child {
    border-right: 1px solid #DDE1E6;
    padding-left: 0;
  }

  @media ${baseTheme.media.small} {
    padding-left: 8px;
    min-height: 50px;

    &:first-child {
      padding-right: 8px;
    }
  }
`

const Words = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${props => props.isSelect ? '#ffffff' : '#697077'};
  padding: 7px 14px;
  background:${props => props.isSelect ? '#697077' : '#DDE1E6'};
  border: 1px solid #DDE1E6;
  border-radius: 100px;
  margin-bottom: 16px;
  width: max-content;
  
  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.word === props.word
          && props.user.type === 'STUDENT'
          && {
            backgroundColor: '#DDE1E6',
            border: '1px solid rgba(105, 112, 119, 0.4)'
          }} // если слово выбраное (активное) у преподавателя
  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.word === props.word
          && props.user.type === 'TEACHER'
          && {
            backgroundColor: 'rgba(136, 92, 255, 0.1)',
            border: '1px solid #885CFF',
            color: '#885CFF'
          }}
  ${props => props.startWord && {
    margin: '0',
    padding: '0 14px 0 0',
    background: '#FFFFFF',
    border: 'none',
    color: '#000000'
  }}

  @media ${baseTheme.media.small} {
    font-size: 14px;
    line-height: 18px;
  }

  @media ${baseTheme.media.extraSmall} {
    padding: 5px 8px;

    ${props => props.startWord && {
      padding: '0 8px 0 0',
    }}
  }
`

const EmptyWrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;

  :last-child {
    margin: 0;
  }
`

const EmptyItem = styled.div`
  width: 107px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #DDE1E6;
  border-radius: 100px;
  margin-right: ${props => props.noneMargin ? '0' : '16px'};
  margin-bottom: ${props => props.noneMargin ? '0' : '16px'};

  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.empty === props.empty
          && props.userType === 'teacher'
          && {
            border: '1px solid #885CFF'
          }}
  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.empty === props.empty
          && props.userType === 'student'
          && {
            border: '1px solid #4F7FFF'
          }}
  &:last-child {
    margin-right: 0;
  }
  
  @media ${baseTheme.media.small} {
    width: 77px;
    height: 34px;
  }
`

export {Wrapper, Container, Col, Words, EmptyWrap, EmptyItem}
