import React from "react";
import {Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

import {FooterWrap, LogoWrap, NavList, SocialsWrap} from "./styled";

import {Logo,} from "../header/headerStyle";
import logo from "../../assets/media/image/logo.png";
/*import twLogo from "../../assets/media/icon/socials/tw.svg";
import fbLogo from "../../assets/media/icon/socials/fb.svg";
import liLogo from "../../assets/media/icon/socials/li.svg";*/
import mailLogo from "../../assets/media/icon/socials/mail.svg";
// import instaLogo from "../../assets/media/icon/socials/insta.svg"
import vkLogo from "../../assets/media/icon/socials/vk.svg"


const Footer = () => {
  const user = useSelector(state => state.users.user);
  const socialLinksData = [
    // {name: 'Instagram', icon: instaLogo, link: 'https://www.instagram.com/zapartoy.platform?utm_medium=copy_link/'},
    {name: 'VK', icon: vkLogo, link: 'https://vk.com/zapartoyplatform'},
/*    {name: 'Twitter', icon: twLogo, link: 'https://twitter.com/'},
    {name: 'Facebook', icon: fbLogo, link: 'https://ru-ru.facebook.com/'},
    {name: 'LinkedIn', icon: liLogo, link: 'https://www.linkedin.com/'},*/
    {name: 'Email', icon: mailLogo, link: 'mailto:info@zapartoy.com'},
      // {name: 'Техподдержка', link: 'https://t.me/+4PiBji9NwVNiNDhi'},
  ];

  const NavLinks = user.type === 'TEACHER' ? (
    <>
      <NavLink to='/schedule'>Расписание</NavLink>
      <NavLink to='/students'>Ученики</NavLink>
      <NavLink to='/homework'>Домашнее задание</NavLink>
      <NavLink to='/courses'>Курсы</NavLink>
    </>
  ) : user.type === 'STUDENT' ? (
    <>
      <NavLink to='/schedule'>Мое расписание</NavLink>
      <NavLink to='/homework'>Домашнее задание</NavLink>
      <NavLink to='/courses'>Мои курсы</NavLink>
      {/*<NavLink to='/dictionary'>Мой словарь</NavLink>*/}
    </>
  ) : (
    <>
      <NavLink to='/admin-panel/users'>Пользователи</NavLink>
      <NavLink to='/admin-panel/templates'>Курсы</NavLink>
      <NavLink to='/admin-panel/resources'>Полезное</NavLink>
    </>
  )

  const SocialLinks = socialLinksData.map((social, key) => {
    return (
      <a className="social" target="_blank" rel="noreferrer" href={social.link} key={key}>
        <img
          className={'icon'}
          src={social.icon}
          alt={social.name}/>
      </a>
    )
  })

  const Copyright = (
    <div className="desc">Copyright © {new Date().getFullYear()} ZaPartoy. All&nbsp;rights reserved.</div>
  );

  return (
    <FooterWrap>
      <LogoWrap>
        <Link to='/'>
          <Logo src={logo} alt="img"/>
        </Link>
      </LogoWrap>
      <NavList>
        {NavLinks}
      </NavList>

      <SocialsWrap>
        <div className={'links'}>
          {SocialLinks}
            <a href='https://t.me/+4PiBji9NwVNiNDhi' className="desc" style={{paddingLeft:'15px'}}>Техподдержка</a>
        </div>
        {Copyright}
      </SocialsWrap>

    </FooterWrap>

  )
}

export default Footer;
