import {Redirect} from "react-router";

import Login from "../../pages/login/login";
import Registration from "../../pages/registration/registration";
import Courses from "../../pages/courses/courses";
import SingleCourse from "../../pages/singleCourse/singleCourse";
//import Resources from "../../pages/resourses/resourses";
import Article from "../../pages/article/article";
import Students from "../../pages/students/students";
import Schedules from "../../pages/shedules/shedules";
import CalendarSchedule from "../../pages/calendarSchedule/calendarSchedule";
//import Dictionary from "../../pages/dictionary/dictionary";
import HomeWork from "../../pages/homeWork/homeWork";
import AdminCoursesList from "../../pages/adminCoursesList/adminCoursesList";
import AdminResources from "../../pages/resourses/adminResources/adminResources";
import Users from "../../pages/users/users";
import StudentCart from "../../pages/studentCart/studentCart";
import AddCourses from "../../pages/addCourses/addCourses";
import Pricing from "../../pages/pricing/pricing";
import LoginRecovery from "../../pages/loginRecovery/loginRecovery";
import CourseEdit from "../../pages/courseEdit/courseEdit";
import LessonEdit from "../../pages/lessonEdit/lessonEdit";
import Course from "../../pages/courseTemplate/Course";
import ClassRoom from "../../pages/classRoom/classRoom";
import HomeworkRoom from "../../pages/homeworkRoom/homeworkRoom";

const mainPagesTypeUser = {
  teacher: {redirect: <Redirect to={'/courses'}/>, component: <Courses/>},
  student: {redirect: <Redirect to={'/schedule'}/>, component: <Schedules/>},
  admin: {redirect: <Redirect to={'/admin-panel/templates'}/>, component: <AdminCoursesList/>}
}

const routingData = [
  {
    path: '/',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/login',
    redirect: <Login/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/login-recovery',
    redirect: <LoginRecovery/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/registration',
    redirect: <Registration/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/courses',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.component,
      admin: mainPagesTypeUser.admin.redirect,
      student: <Courses/>
    }
  },
  {
    path: '/course/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id} = match.params
        return <Course courseId={id} isStudentCourse={false}/>
      },
      admin: mainPagesTypeUser.admin.redirect,
      student: ({match}) => {
        const {id} = match.params
        return <Course courseId={id} isStudentCourse={true}/>
      }
    }
  },
  {
    path: '/course-edit/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id} = match.params
        return <CourseEdit courseId={id}/>
      },
      admin: ({match}) => {
        const {id} = match.params
        return <CourseEdit courseId={id}/>
      },
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/course-edit/:id/lessons/:idLesson',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id, idLesson} = match.params
        return <LessonEdit lessonId={idLesson} courseId={id}/>
      },
      admin: ({match}) => {
        const {id, idLesson} = match.params
        return <LessonEdit lessonId={idLesson} courseId={id}/>
      },
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/students/:studentId/course/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id, studentId} = match.params
        return <Course
            courseId={id}
            isStudentCourse={true}
            studentId={studentId}
        />
      },
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/single-course/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id} = match.params
        return <SingleCourse courseId={id}/>
      },
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/resources',
    redirect: <Redirect to={'/login'}/>,
    components: {
      admin: mainPagesTypeUser.admin.redirect,
    }
  },
  {
    path: '/article',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <Article/>,
      admin: <Article/>,
      student: <Article/>,
    }
  },
  {
    path: '/students',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <Students/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/schedule',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <CalendarSchedule/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: <Schedules/>
    }
  },
  {
    path: '/class-room/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id} = match.params
        return <ClassRoom id={id}/>
      },
      admin: mainPagesTypeUser.admin.redirect,
      student: ({match}) => {
        const {id} = match.params
        return <ClassRoom id={id}/>
      },
    }
  },
  {
    path: '/dictionary',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.admin.redirect
      /*student: <Dictionary/>*/
    }
  },
  {
    path: '/homework',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <HomeWork/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: <HomeWork/>
    }
  },
  {
    path: '/homework/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => <LessonEdit type={'homework'} homeworkId={match.params.id}/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: ({match}) => <LessonEdit type={'homework'} homeworkId={match.params.id}/>
    }
  },
  {
    path: '/homework-room/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => <HomeworkRoom id={match.params.id}/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: ({match}) => <HomeworkRoom id={match.params.id}/>
    }
  },
  {
    path: '/admin-panel/templates',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: mainPagesTypeUser.admin.component,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/admin-panel/resources',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: <AdminResources/>,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/admin-panel/users',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: <Users/>,
      student: mainPagesTypeUser.student.redirect
    }
  },
  {
    path: '/admin-panel/templates/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: ({match}) => {
        const {id} = match.params
        return <CourseEdit courseId={id}/>
      },
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/admin-panel/templates/:id/lessons/:idLesson',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: mainPagesTypeUser.teacher.redirect,
      admin: ({match}) => {
        const {id, idLesson} = match.params
        return <LessonEdit lessonId={idLesson} courseId={id}/>
      },
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/students/:id',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: ({match}) => {
        const {id} = match.params
        return <StudentCart id={id}/>
      },
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/courses-library',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <AddCourses/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect,
    }
  },
  {
    path: '/pricing',
    redirect: <Redirect to={'/login'}/>,
    components: {
      teacher: <Pricing/>,
      admin: mainPagesTypeUser.admin.redirect,
      student: mainPagesTypeUser.student.redirect,
    }
  },
]

export {routingData}
