import React, {useEffect, useState} from 'react';

import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import MainButton from '../../../components/buttons/mainButton/mainButton';
import MediaModals from '../../../components/mediaModals/mediaModals';

import '../../../assets/vendor/swiper/swiper.css';
import {BlockWrap, BtnSection} from '../styled'

const MainContent = ({
                         data,
                         activeSection,
                         user,
                         handleCheckTask,
                         nextSection,
                         prevSection,
                         sliderRefLink,
                         classroomId,
                         handleVisibleForTeacher,
                         createComment,
                         handleUpdateComment,
                         handleDoneTask,
                         // handleUpdateWritingText,
                     }) => {
    const [tasksList, setTasks] = useState([])
    const [sectionName, setSectionName] = useState(null);

    useEffect(() => {
        if (data.length > 0 && activeSection >= 0) {
            setSectionName(data[activeSection].name);
            setTasks(data[activeSection].tasks);
        } else {
            setSectionName('');
            setTasks([]);
        }
    }, [data, activeSection]);


    const renderTaskList = tasksList.map(task => {
        //Непонятное условие отрисовки заметок только для учителя- скорее всего удалить
        // if (task.task_type === 'NOTE' && user.type === 'STUDENT') return
        return (
            <SwiperSlide key={task.id} className={'taskItem'} tasktype={task.task_type}>
                <div className="section__title">{task.title}</div>
                <MediaModals
                    handleDoneTask={handleDoneTask}
                    handleCheckTask={handleCheckTask}
                    handleVisibleForTeacher={handleVisibleForTeacher}
                    createComment={createComment}
                    handleUpdateComment={handleUpdateComment}
                    handleUpdateWritingText={handleCheckTask}
                    classroomId={classroomId}
                    type={task.task_type}
                    data={task}
                />
            </SwiperSlide>
        )
    })

    return (
        <BlockWrap isStudent={user.type === 'STUDENT'}>
            <div className="title__block">{sectionName}</div>
            <Swiper
                ref={sliderRefLink}
                className={'tasksList'}
                scrollbar={false}
                modules={[Navigation, Pagination]}
                navigation={true}
                allowTouchMove={false}
                pagination={{
                    'type': 'fraction'
                }}

            >
                {renderTaskList}
            </Swiper>

            {user.type !== 'STUDENT' && (
                <BtnSection>
                    <MainButton
                        func={prevSection}
                        text={'Предыдущая секция'}
                        disabled={activeSection === 0}
                    />
                    <MainButton
                        func={nextSection}
                        text={'Следующая секция'}
                        disabled={!(activeSection + 1 < data.length)}
                    />
                </BtnSection>
            )
            }
        </BlockWrap>
    )
}

export default MainContent;
