import React from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from "react-redux";

import PopupCourse from '../popupCourse';
import axiosInstance from "../../../../service/iTeacherApi";

import {closeAddCoursePopup} from "../../../../store/actions/addCoursePopupAction";
import {getAllCourses} from "../../../../store/actions/coursesAction";

const PopupAddCourse = ({user, closeAddCoursePopup, getAllCourses}) => {
  const history = useHistory();
  const addCourse = async (data) => {
    try {
      const response = await axiosInstance.post(`/${user.type.toLowerCase()}/courses-library/`, data);
      closeAddCoursePopup();
      history.push(`/course-edit/${response.data.id}`);
      getAllCourses(user.type.toLowerCase());
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <PopupCourse
      type={'add'}
      func={addCourse}
      close={closeAddCoursePopup}
    />
  );
}

const mapStateToProps = ({users}) => {
  return {
    user: users.user
  }
};

const mapDispatchToProps = (dispatch) => ({
  closeAddCoursePopup: () => dispatch(closeAddCoursePopup()),
  getAllCourses: (userType) => dispatch(getAllCourses(userType))
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupAddCourse);
