import React, {useEffect, useState} from 'react'

import PricingBlock from "./pricingBlock/pricingBlock";
import MainButton from "../../../components/buttons/mainButton/mainButton";
import List from "./list/list";

import * as Style from './styled'

import logos from './media/logos.svg'
import {connect} from "react-redux";
import {getLessonPrice, getPaymentLink, payLessons} from "../../../store/actions/orderAction";

const ContentBlock = (props) => {
  const { order, payLessons, getPaymentLink, lessonPrice, getLessonPrice } = props;
  const [count, setCount] = useState(0);
  const [allPrice, setAllPrice] = useState(0);

  useEffect(() => {
    lessonPrice
        ? setAllPrice(lessonPrice)
        : getLessonPrice()
  }, [lessonPrice])
  useEffect(() => {
    if (order) getPaymentLink(order.id);
  }, [order])

  const changeCount = (value) => {
    if(value < 0 && count === 0) return;
    setAllPrice((count + value) * lessonPrice);
    setCount(count + value);
  }

  const handleClick = () => {
    payLessons(count)
  }

	return (
		<Style.Wrapper>

      <div className="left">
        <div className="left__title">Платите только за уроки,</div>
        <div className="left__title-bottom">остальное бесплатно!</div>

        <List price={lessonPrice}/>
      </div>

      <div className="right">
        <div className="right__title">Сколько Вам нужно уроков?</div>

        <PricingBlock count={count} changeCount={changeCount}/>

        <div className="right__title right__title--8">Общая стоимость</div>

      {count === 0 ? <div className="right__all-price">0.00₽</div>
            : <div className="right__all-price">{allPrice}.00₽</div>}

        <MainButton
          func={() => handleClick()}
          text={'Оплатить'}
          width={356}
        />

        <Style.Logos src={logos} alt={'logos'}/>
      </div>

		</Style.Wrapper>
	)
}

const mapStateToProps = ({ order }) => ({
  order: order.order,
  lessonPrice: order.lessonPrice,
})

const mapDispatchToProps = (dispatch) => ({
  payLessons: (count) => dispatch(payLessons(count)),
  getPaymentLink: (id) => dispatch(getPaymentLink(id)),
  getLessonPrice: () => dispatch(getLessonPrice())
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentBlock);
