import React, {Component} from 'react';
import InviteEmail from "./inviteEmail";
import InviteLink from "./inviteLink";

//styled
import {StudentModalWrap, StudentModal, TabBody,TabHead,TabHeadNav} from './addStudentModalStyled';
// icon
import closed from '../../../assets/media/icon/close.svg';


export default class AddStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabStatus: 'email',
      email: '',
      name: ''
    }
  }

  // change active tab
  changeTab = (e, tab) => {
    this.setState({tabStatus: tab});
    document.querySelector('.tabs-active').classList.remove('tabs-active');
    e.target.classList.add('tabs-active');
  };

  // ввод емейла
  setStudent = (student) => {
    this.setState({
      ...this.state,
      email: student.email,
      name: student.username
    })
  }

  onInput = (e) => {
    this.setState({
      ...this.state,
      email: e.target.value
    });
  }

  onInputName = (e, openDropDown, students) => {
    const student = students.find(el => el.username.toLowerCase() === e.target.value.toLowerCase())
    this.setState({
      ...this.state,
      email: student? student.email : e.target.value,
      name: e.target.value
    });
    openDropDown();
  }

  render() {
    const {close, func} = this.props;
    return (
      <StudentModalWrap>
        <StudentModal className='studentModal'>
          <img src={closed} alt="icon" className='close'  onClick={(e) => {close(e)}}/>
          <h2>Добавить ученика</h2>
          <TabHead>
            <TabHeadNav className='tabs-active' onClick={(e) => this.changeTab(e, 'email')}>Пригласить по Email</TabHeadNav>
            <TabHeadNav onClick={(e) => this.changeTab(e, 'link')}>Выбрать из списка</TabHeadNav>
          </TabHead>
          <TabBody>
            {
              this.state.tabStatus === 'email'
                ? <InviteEmail value={this.state.email} onInput={this.onInput}/>
                : <InviteLink value={this.state.name} onInput={this.onInputName} setStudent={this.setStudent}/>
            }
            <button className='invite'
                    onClick={() => {func(this.state.email)}}
            >
              {
                this.state.tabStatus === 'email'
                ? 'Пригласить'
                : 'Добавить ученика'
              }
            </button>
          </TabBody>
        </StudentModal>
      </StudentModalWrap>
    )
  }
}
