import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";
import ResetProgress from "../resetProgress/resetProgress";

import {DragWordsWrap, WordsSection, Word, EmptyWord} from '../mediaDragWords/dragWordsStyled';
import * as Style from '../mediaSentence/style'
import {Sentence} from "./style";
import Notes from "../notes/notes";

const regexp = /\[(\\.|[^\]\\])*\]/g;

const MediaTransfer = ({data, handleCheckTask, userType, tasks}) => {
    const [sentencesList, setSentencesList] = useState([]);
    const [emptiesList, setEmpties] = useState([]);
    const [activeWord, setActive] = useState(null);
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {
      if (data.completed?.completed) {
        displayingTask(data.completed?.task, true);
      } else if (data.completed?.have_answer) {
        const additional_info = JSON.parse(data.completed?.additional_info);
        setSentencesList(additional_info.sentencesList);
        setEmpties(additional_info.emptiesList);
      } else {
        displayingTask(data.completed?.task || data.mixed_desc || data.task, false)
      }
    }, [data]);

    const displayingTask = (task, isCompleted) => {
      let counterSentences = 0;
      let counterWords = 0;
      let counterEmpties = 0;
      const newSentencesList = (task).split('\n').map(item => (
        {
          sentence: item.replace(/\./g, ' . ').replace(regexp, str => str.replace(/\s/g, '_')).split(' ').filter(i => i !== '').map(word => {
            const newWord = {
              id: counterWords++
            };
            if (word.includes('[') && word.includes(']')) {
              newWord.word = '[]'
              if (isCompleted) newWord.setWord = word.slice(1, -1).replace('_', ' ');
            } else {
              newWord.word = word;
            }
            return newWord;
          }),
          sentenceId: counterSentences++
        })
      );
      setSentencesList(newSentencesList);
      if (!isCompleted) {
        setEmpties((task).match(regexp)?.map(el => ({
          empties: el.slice(1, -1),
          id: counterEmpties++
        })));
      }
    }

    useEffect(() => {
      const taskChecked = tasks.find(i => i.task_id === data.id)
      if (taskChecked) {
        setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
        if (JSON.stringify({sentencesList, emptiesList}) !== taskChecked.additional_info) {
          const additional_info = JSON.parse(taskChecked.additional_info);
          setSentencesList(additional_info.sentencesList);
          setEmpties(additional_info.emptiesList);
        }
      }
    }, [tasks]);

    const dndStart = (e, word) => {
      e.target.style.opacity = '0.4';
      setTimeout(() => {
        setActive(word);
        const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
        const allDnDWord = document.querySelectorAll('.' + classNameDrag);
        allDnDWord.forEach(word => {
          word.classList.remove(classNameDrag)
        })
        e.target.classList.add(classNameDrag)
      })
    }

    const dndEnd = (e) => {
      e.target.style.opacity = '1';
      const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
      e.target.classList.remove(classNameDrag)
    }

    const dndEnter = (e) => {
      e.target.classList.add('dnd-hovered');
    }

    const dndOver = (e) => {
      e.preventDefault();
    }

    const dndLeave = (e) => {
      e.target.classList.remove('dnd-hovered');
    }

    const dndDrop = (e, sentence) => {
      e.target.classList.add('dnd-hovered');
      document.querySelectorAll('.dnd-hovered').forEach(block => {
        block.classList.remove('dnd-hovered')
      })
      setEmptyWord(sentence)
    }

    const setActiveWord = (word) => setActive(word);

    const handleCheck = () => {
      const answer = sentencesList.map(item => item.sentence.map(i => i.setWord ? `[${i.setWord}]` : i.word).join(' ')).join('.');
      const additionalInfo = {sentencesList, emptiesList};
      handleCheckTask(answer, data.id, JSON.stringify(additionalInfo));
    }

    const handleFalseCloseChecked = () => setCompleted(false);

    const handleResetProgress = () => displayingTask(data.completed?.task || data.list_column || data.task, false);

    const setEmptyWord = (selectedSentence) => {
      if (!activeWord?.empties) return;
      if (selectedSentence.id === undefined) return;

      const newSentencesList = sentencesList.map(sentence => {
        if (sentence.sentenceId !== selectedSentence.sentenceId) {
          return sentence;
        } else {
          return ({
            ...sentence,
            sentence: sentence.sentence.map(word => word.id === selectedSentence.id ? ({
              ...word,
              setWord: activeWord.empties
            }) : word)
          });
        }
      });
      const newEmptiesList = emptiesList.filter(item => item.id !== activeWord.id);
      setSentencesList(newSentencesList);
      setEmpties(newEmptiesList);
      setActiveWord(null);
      handleFalseCloseChecked();
    }

    const sentencesRender = sentencesList.map(item => {
      const {sentence, sentenceId} = item;
      if (sentence.length < 2) return null
      return (
        <Sentence key={sentenceId}>
          {sentence.map(item => {
            const {word, id, setWord = null} = item;
            if (word === '') {
              return null
            } else if (!(word.includes('[') && word.includes(']'))) {
              return (
                <p key={id}>{word}</p>
              )
            } else {
              if (setWord) return <Word key={id} isTransfer={true}>{setWord}</Word>
              return (
                <Style.EmptyItem
                  key={id}
                  className={'dnd-hover'}
                  task={data.id}
                  empty={id}
                  userType={userType}
                  noneMargin={true}
                  isTransfer={true}
                  onClick={() => setEmptyWord({sentenceId, id})}
                  onDragEnter={dndEnter}
                  onDragLeave={dndLeave}
                  onDragOver={dndOver}
                  onDrop={(e) => dndDrop(e, {sentenceId, id})}
                />
              )
            }
          })
          }
        </Sentence>)

    })

    return (
      <>
        <DragWordsWrap>
          <WordsSection className={!!handleCheckTask ? "words_classroom" : ""}>
            {emptiesList?.length > 0 ? emptiesList.map((item) => (
              <Word
                key={item.id}
                onClick={() => setActiveWord(item)}
                isSelect={activeWord?.id === item.id}
                userType={userType}
                word={item.empties}
                task={data.id}
                draggable="true"
                onDragStart={(e) => {
                  dndStart(e, item)
                }}
                onDragEnd={dndEnd}
              >
                {item.empties}
              </Word>
            )) : <EmptyWord/>}
          </WordsSection>

          <Style.EmptyBlock sentence>
            {sentencesRender}
          </Style.EmptyBlock>
        </DragWordsWrap>
          <Notes notes={data.notes} userType={userType}/>
        {handleCheckTask &&
          <CheckedBlock
            userType={userType}
            completed={completed}
            handleCheck={handleCheck}
            closeFalseChecked={handleFalseCloseChecked}
          >
            {handleCheckTask && completed !== '#24A1481A' && <ResetProgress handleReset={handleResetProgress}/>}
          </CheckedBlock>
        }
      </>
    )
  }
;

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaTransfer);
