import React, {Component} from 'react';

import MainInput from "../../../components/inputs/mainInput/mainInput";

import Calendar from './calendar';

import {CalendarModalWrapp, CalendarBody, InfoInput} from './CalendarStyled';

import closed from '../../../assets/media/icon/close.svg';
import arrow from '../../../assets/media/icon/arrow.svg';

import TimeModal from "../timeModal/timeModal";
import {getLessonTime} from "../../../service/timePrettier";
import {connect} from "react-redux";
import {newSchedule} from "../../../store/actions/schedulesAction";

class CalendarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showCalendar: false,
      timeModal: false,
      timeData: null
    }
  }

  // show calendarSchedule
  showCalendar() {
    this.setState({showCalendar: !this.state.showCalendar})
    this.setState({timeModal: false})
  }

  handleDateChange = data => {
    this.setState({data, showCalendar: false})
  };

  // создание события
  createNewSchedule = async (e) => {
    e.preventDefault();
    const date = getLessonTime(this.state.data, e.target.time.value);
    const object = {
      start_time: date,
      course: this.props.data.courseId,
      lesson: this.props.data.lesson,
      student: this.props.data.studentId,
    }

    this.props.newSchedule(object, this.props.finish)
  }

  selectTime = (value) =>  {
    this.setState({timeData: value})
    this.setState({timeModal: false})
  }

  openTimeModal() {
    this.setState({timeModal: !this.state.timeModal})
    this.setState({showCalendar: false})
  }

  render() {
    const {close} = this.props;
    const {data, showCalendar} = this.state;
    return (
      <CalendarModalWrapp>
        <CalendarBody
          onSubmit={(e) => this.createNewSchedule(e)}
          isOpenModal={this.state.showCalendar || this.state.timeModal}
        >
          <img className={'close'} src={closed} alt="icon" onClick={(e) => {
            close(e)
          }}/>
          <h3>Назначьте первое занятие</h3>
          <InfoInput arrow={this.state.showCalendar}>
            <p className={'openCalendar'} onClick={() => this.showCalendar()}>Дата</p>

            <div
              className={'showData'}>
              {data && <input type={'text'} readOnly={true} name={'date'} value={data.toLocaleDateString()}/>}
            </div>
            <img onClick={() => this.showCalendar()} className={'arrow'} src={arrow} alt="icon"/>
            {
              showCalendar
                ? <Calendar
                  new
                  templateCalendarStyle
                  onChange={this.handleDateChange}
                />
                : null
            }
          </InfoInput>
          <MainInput
            type={'text'}
            label={'Время'}
            name={'time'}
            required={true}
            readOnly
            onClick={() => this.openTimeModal()}
            defaultValue={this.state.timeData}
            arrow={!this.state.timeModal}
            showArrow
          />
          {
            this.state.timeModal &&  <TimeModal templateTimeModal selectTime={this.selectTime}/>
          }
          <button
            type={'submit'}
            className={'addLesson'}
          >
            Назначить занятие
          </button>
        </CalendarBody>
      </CalendarModalWrapp>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  newSchedule: (data, handleClose) => dispatch(newSchedule(data, handleClose))
})

export default  connect(mapStateToProps, mapDispatchToProps)(CalendarModal);
