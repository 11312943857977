import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";

import MainButton from "../../../../components/buttons/mainButton/mainButton";

import Timer from "../timer/timer";
import MainInfo from "../mainInfo/mainInfo";
import MobileButton from "../mobileButton/mobileButton";
import NewLesson from "../newLesson/newLesson";

import {HomeWorkItemWrap, LeftTimes, TeacherCourse, NameCourse} from './styled';

import ok from '../../../../assets/media/icon/ok-green.svg';
import ava from "../../../../assets/media/icon/avatar.svg";

const ScheduleItem = ({done, event, course, type}) => {
    const history = useHistory();
    const [windowWidth, setWidth] = useState(window.innerWidth);
    const handlerResize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handlerResize);
        return () => {
            window.removeEventListener('resize', handlerResize);
        }
    }, [])

    const startLesson = () => {
        history.push(`/class-room/${event.id}`)
    }

    return (
        <>
            {
                windowWidth < 440 && event.status === 'process' ? (
                    <MobileButton/>
                ) : (
                    <HomeWorkItemWrap>
                        <MainInfo course={course} event={event}/>

                        <TeacherCourse>
                            <div className="td-wrapper td-wrapper--no-flex">
                                <p className="label">Преподаватель</p>
                                <div className="info">
                                    <img
                                        src={event.teacher.user.photo ? event.teacher.user.photo : ava}
                                        alt=""/>
                                    <p
                                        className="name">{event.teacher.user.username ? event.teacher.user.username : event.teacher.user.email}</p>
                                </div>
                            </div>
                        </TeacherCourse>
                        <NameCourse>
                            <div className="td-wrapper td-wrapper--no-flex">
                                <p className="label">Курс</p>
                                <p className="name">{event.course.course_template.name}</p>
                            </div>
                        </NameCourse>

                        {
                            type === 'STUDENT' && event.checked_by_student ?
                                <td className="right right_new--lesson"/> : <NewLesson/>
                        }
                        {
                            event.status === 'ACTIVE' &&
                            <Timer event={event} type={type}>
                                {type === 'TEACHER' && (
                                    <MainButton
                                        text={'Начать урок'}
                                        type={'button'}
                                        classList={'schedule-start-button'}
                                        func={startLesson}
                                    />
                                )}
                            </Timer>
                        }
                        {
                            event.status === 'PROCESS' && (
                                <td className="right" style={{whiteSpace: 'nowrap'}}
                                    colSpan={2}>
                                    <div className="td-wrapper td-wrapper--end">
                                        <p className="alert-text">Урок начался!</p>
                                        {event.status_teacher &&
                                        <MainButton
                                            text={type === 'STUDENT' ? 'Войти' : 'Начать урок'}
                                            type={'button'}
                                            classList={'schedule-start-button'}
                                            func={startLesson}
                                        />
                                        }
                                    </div>
                                </td>
                            )
                        }

                        {
                            event.status === 'INACTIVE' && (
                                <td className="right" style={{whiteSpace: 'nowrap'}}
                                    colSpan={2}>
                                    <div className="td-wrapper td-wrapper--end"
                                         style={{paddingRight: '16px'}}>
                                        <p className="alert-text inactive">Неактивен</p>
                                    </div>
                                </td>
                            )
                        }

                        {
                            done && (
                                <td className="right">
                                    <div className="td-wrapper td-wrapper--end">
                                        <div className={'DoneBlock'}>
                                            <img src={ok} alt="icon"/>
                                            <LeftTimes className={'done'}>Домашнее задание
                                                выполнено</LeftTimes>
                                        </div>
                                    </div>
                                </td>
                            )
                        }
                    </HomeWorkItemWrap>
                )
            }
        </>
    )
}

export default ScheduleItem;
