import React, {useState, useEffect} from "react";

import MainInput from "../../components/inputs/mainInput/mainInput";
import MainDropList from "../../components/inputs/mainDropList/mainDropList";
import MainButton from "../../components/buttons/mainButton/mainButton";
import Calendar from "../courseTemplate/calendarModal/calendar";

import {TextModalBody, TextModalOverlay, Line, SmallTitle} from "./styled";

import closed from "../../assets/media/icon/close.svg";
import TimeModal from "./timeModal/timeModal";
import {useDispatch, useSelector} from "react-redux";
import {getLessonTime} from "../../service/timePrettier";
import {editSchedule} from "../../store/actions/schedulesAction";
import {openInfoPopup, setErrorInfoText} from "../../store/actions/infoPopupAction";
import axiosInstance from "../../service/iTeacherApi";

const monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

const EditEventModal = ({close, schedule}) => {
    const {students} = useSelector(state => state.students);
    const dispatch = useDispatch();
    const [student, setStudent] = useState({});
    const [course, setCourse] = useState({});
    // список курсов
    const [coursesList, setCoursesList] = useState([]);
    // список уроков
    const [lessons, setLessons] = useState([]);

    const [selectedLesson, setSelectedLesson] = useState({});

    const [calendarModal, setCalendarModal] = useState(false);

    const [calendarDate, setCalendarDate] = useState(null);

    const [hiddenDate, setHiddenDate] = useState(false)

    const [dateValidation, setDateValidation] = useState(false)

    const [timeModal, setTimeModal] = useState(false)
    const [timeData, setTimeData] = useState(null)

    useEffect(() => {
        if (schedule.id) {
            handleDateChange(new Date(schedule.start_time));
            const time = new Date(schedule.start_time).toLocaleTimeString([], {hour12: false})
            selectTime(time.substr(0,time.length - 3))
        }
    }, [schedule])

    useEffect(() => {
        if (!student.id) {
            return
        }
        if (student.user?.courses?.length > 0) {
            setCoursesList(student.user.courses);
        } else {
            setCoursesList([]);
        }
        setLessons([]);
        setSelectedLesson({});
    }, [student]);

    useEffect(() => {
        if (!course.id) {
            return
        }
        if (course.course_template?.lessons?.length > 0) {
            setLessons(course.course_template.lessons);
            setSelectedLesson({});
        } else {
            setLessons([]);
            setSelectedLesson({});
        }
    }, [course])

    const onChangeStudentHandler = async (value) => {
        try {
            const response = await axiosInstance.get(`/teacher/students/${value.id}/`);
            setStudent(response.data)
        } catch (e) {
            console.log(e);
        }
    }

    const onChangeCourseHandler = async (value) => {
        try {
            const response = await axiosInstance.get(`/teacher/courses/${value.id}/`);
            setCourse(response.data)
        } catch (e) {
            console.log(e);
        }
    }

    const onChangeLessonHandler = (lesson) => {
        setSelectedLesson(lesson);
    }

    // обновление события
    const updateSchedule = async (e) => {
        e.preventDefault()
        const date = getLessonTime(e.target.date.value, e.target.time.value);

        if (e.target.date.value === "false" || e.target.time.value === '' || course.id === undefined || selectedLesson.id === undefined || student.id === undefined) {
            dispatch(setErrorInfoText('Заполните все поля для добавления события.'));
            dispatch(openInfoPopup());
            return
        }

        const data = {
            start_time: date,
            course: course.id,
            lesson: selectedLesson.id,
            student: student.id
        };
        dispatch(editSchedule(schedule.id, data, close));
    }

    const handleDateChange = data => {
        setCalendarModal(false);
        setCalendarDate(data.toLocaleDateString());
        setHiddenDate(data);
    };

    const selectTime = (value) => {
        setTimeData(value);
        setTimeModal(false);
    }

    const openTimeModal = () => {
        setTimeModal(!timeModal);
        setCalendarModal(false);
    }

    const openCalendarModal = () => {
        setTimeModal(false);
        setCalendarModal(!calendarModal);
    }

    const handleClose = () => {
        close();
    }

    return (
        <TextModalOverlay>
            <TextModalBody
                onSubmit={updateSchedule}
                className={'EventModalBody'}
                calendar={calendarModal}
            >
                <img onClick={handleClose} className={'closed'} src={closed} alt="icon"/>
                <h2 className={'title'}>Редактировать событие</h2>

                <MainDropList
                    gray
                    label={'Ученик'}
                    name={'student'}
                    required={true}
                    options={students}
                    onChange={onChangeStudentHandler}
                    defaultId={schedule.student.id}
                />
                <div className="double">
                    <MainDropList
                        label={'Курс'}
                        name={'course'}
                        required={true}
                        options={coursesList}
                        onChange={onChangeCourseHandler}
                        defaultId={schedule.course.id}
                    />
                    <MainDropList
                        label={'Занятие'}
                        name={'lesson'}
                        type={'text'}
                        options={lessons}
                        defaultId={schedule.lesson.id}
                        onChange={onChangeLessonHandler}
                    />

                </div>

                <Line/>

                <SmallTitle>Дата / Время</SmallTitle>

                <div className="double">

                    <MainInput
                        label={'Дата'}
                        name={'date_string'}
                        type={'text'}
                        required={false}
                        defaultValue={calendarDate}
                        readOnly
                        grey
                        onClick={openCalendarModal}
                        arrow={!calendarModal}
                        showArrow
                    />

                    <input type="hidden" name={'date'} value={hiddenDate}/>

                    <MainInput
                        label={'Время'}
                        name={'time'}
                        type={'text'}
                        required={false}
                        grey
                        readOnly
                        validation={dateValidation}
                        onClick={openTimeModal}
                        defaultValue={timeData}
                        arrow={!timeModal}
                        showArrow
                    />

                </div>

                {
                    calendarModal && <Calendar new onChange={handleDateChange}/>
                }
                {
                    timeModal && <TimeModal selectTime={selectTime}/>
                }

                <MainButton
                    text={'Изменить'}
                    type={'submit'}
                    disabled={false}
                />

                <div className="fake-calendar"/>

            </TextModalBody>

        </TextModalOverlay>
    )
}

export default EditEventModal;
