 import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import * as Style from './styled'

import {FormBtnContainer, FormMainContainer} from '../../popupsAdmin/styled';
import {Editor} from 'react-draft-wysiwyg';
import bold from '../../../assets/media/icon/bold.svg';
import italic from '../../../assets/media/icon/italic.svg';
import underline from '../../../assets/media/icon/underline.svg';
import unordered from '../../../assets/media/icon/mark_list.svg';
import link from '../../../assets/media/icon/link.svg';
import image from '../../../assets/media/icon/photo.svg';
import {uploadFile} from '../../../service/iTeacherApi';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import Comment from './comment/comment';
import styles from './editor_styles/teacher.module.css';
import successIcon from '../../../assets/media/icon/succes_task.svg';
import {CheckedWrapWriteText, CommentStyles} from './styled';

const MediaWriteText = (
    {
        classroomId,
        handleDoneTask,
        handleRevision,
        handleVisibleForTeacher,
        handleUpdateWritingText,
        handleVisibleComment,
        dataHomework,
        data,
        userType,
        handleCheckTask,
        tasks,
        createComment,
        handleUpdateComment,
    }) => {
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateComment, setEditorStateComment] = useState(EditorState.createEmpty());
    const [plug, setPlug] = useState(EditorState.createEmpty());
    const [studentVisibility, setStudentVisibility] = useState(false);
    const [commentText, setCommentText] = useState('')
    const [editorText, setEditorText] = useState('')
    const [message, setMessage] = useState('')
    const [sentToTeacher, setSentToTeacher] = useState(null)
    const [isCompletedTask, setIsCompletedTask] = useState(null)
    const [dataComments, setDataComments] = useState(null)
    const [commentIsNullable, setCommentIsNullable] = useState(null)

    const homeworkId = dataHomework?.id
    const dataId = data.id
    let commentData

    //определение открытого url
    let url = window.location.pathname;
    const isHomeworkRoom = url?.search('/homework-room') != -1
    const isClassRoom = url?.search('/class-room') != -1

    useEffect(() => {
        let answer = data.completed?.answer
        let completed = data.completed
        if (answer !== null && answer !== '' && !!completed) {
            const editorText = editorParse(answer)
            setEditorState(editorText)
        }
        if (completed) {
            setIsCompletedTask(completed.completed)
        }
        setSentToTeacher(completed?.sent_to_teacher)
        commentProcessing(data)
    }, [data])

    useEffect(() => {
        if (isClassRoom) {
            const taskChecked = tasks.find(i => i.task_id === dataId)
            if (taskChecked) {
                const completed = taskChecked.completed
                const editorText = editorParse(taskChecked.answer)

                setEditorState(editorText)
                setIsCompletedTask(completed)
                setSentToTeacher(taskChecked.sent_to_teacher)
                setCompleted(completed ? '#24A1481A' : '#DA1E281A')
                commentProcessing(taskChecked)
            }
        }
    }, [tasks]);

    //Проверка на пустоту в редакторе комментария для отключения видимости пустого комментария
    useEffect(() => {
        if (commentIsNullable !== null && !isEditorCommentText()) {
            setStudentVisibility(false)
        }
    }, [editorStateComment])

    const isEditorCommentText = () => editorStateComment.getCurrentContent().hasText()
    const editorParse = (convertElement) => EditorState.createWithContent(convertFromRaw(JSON.parse(convertElement)))

    //обработка комментариев
    const commentProcessing = (dataComments) => {
        if (dataComments.comments?.length > 0) {
            commentData = dataComments.comments[dataComments.comments.length - 1]
            const editorCommentText = editorParse(commentData.text)
            setDataComments(dataComments.comments)
            setEditorStateComment(editorCommentText)
            setCommentIsNullable(isEditorCommentText())
            setCommentText(commentData.text)
            setStudentVisibility(commentData.visible)
        }
    }

    //ЗА УЧЕНИКА Сохранение сочинения+передача редактора сочинения учителю
    const sendItTeacher = async (e) => {
        e.preventDefault()
        if (editorText !== '') {
            await handleCheckTask(editorText, dataId)
            await handleVisibleForTeacher(userType, dataId, true)
            let messageNotification = isHomeworkRoom ? 'Ваш ответ сохранен' : 'Ваш ответ отправлен учителю'
            setMessage(messageNotification)
            setTimeout(() => {
                setMessage('')
            }, 15000)
        }
        if (editorText === '') {
            setMessage('Заполните поле для сочинения')
        }
    };

    //Редактирование текста сочинения
    const updateTextDescription = (state) => {
        setEditorState(state);
        setEditorText(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    }

    //заглушка
    const updateTextPlug = (state) => {
        setPlug(state);
    }
    //Сохранить внесенные поправки в сочинение ученика УЧИТЕЛЕМ+ сохранение комментария+ завершение выполнения
    // задания  в зависимости от типа кнопки
    const handleCreateCommit = async (e, buttonType) => {
        e.preventDefault();
        let dataRoomId
        let comment

        if (isHomeworkRoom) {
            comment = {
                task: dataId,
                text: commentText,
                homework: homeworkId,
                classroom: null,
                visible: studentVisibility,
            }
            dataRoomId = {homework_id: homeworkId, classroom_id: null}
        }

        if (isClassRoom) {
            comment = {
                task: dataId,
                text: commentText,
                homework: null,
                classroom: classroomId,
                visible: studentVisibility,
            }
            dataRoomId = {homework_id: null, classroom_id: classroomId}
        }

        if (comment.text !== '') {
            const commentUpdateData = {
                id: data.comments[data.comments.length - 1]?.id,
                text: commentText,
            }
            commentUpdateData.visible = studentVisibility

            data?.comments?.length > 0
                ? handleUpdateComment(userType, commentUpdateData)
                : createComment(userType, comment)
        }

        if (editorText !== '') {
            isHomeworkRoom ? handleUpdateWritingText(editorText, dataId, userType) : handleCheckTask(editorText, dataId)
        }

        if (buttonType === 'revision') {
            await handleVisibleForTeacher(userType, dataId, false)
            if (isHomeworkRoom) {
                await handleRevision(userType)
            }
            setMessage('Отправлено на доработку')
        }

        if (buttonType === 'done') {
            handleDoneTask(userType, dataRoomId, dataId)
            setMessage('Задание выполнено!')
        }
    }

    //Видимость комментария для ученика
    const onCheckboxChange = () => {
        if (editorStateComment.getCurrentContent().getPlainText('\u0001') !== '') {
            setStudentVisibility(!studentVisibility)
        }
    }

    //Редактирование комментария
    const updateTextCommentDescription = (state) => {
        setEditorStateComment(state);
        setCommentText(JSON.stringify(convertToRaw(editorStateComment.getCurrentContent())))
    }

    let content;

    //Редактор отрисовки комментария без редактирования
    let commentContent = (<>
            {dataComments?.length > 0 &&
            <CommentStyles>
                <div className="note_container">
                    <span>Комментарий от учителя</span>
                    <Editor
                        editorState={editorStateComment}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </CommentStyles>
            }
        </>
    )

    //Редактор для отрисовки сочинения без возможности редактирования
    let verifiedContent = (<>
        {userType === 'student' && studentVisibility && commentContent}

        {sentToTeacher !== null && <div style={{paddingBottom: '16px'}}>
            <div style={{
                border: '1px solid rgb(221, 225, 230)',
                borderRadius: '8px',
                textIndent: '15px'
            }}>
                <Editor
                    editorState={editorState}
                    toolbarHidden
                    readOnly
                    wrapperClassName="wrapper-class"
                    toolbarClassName="toolbar-class"
                />
            </div>
        </div>
        }
        {userType === 'teacher' && commentContent}
    </>)

    // Заглушка
    const plugText = (
        <FormMainContainer className={'padding noTopBotPadding'}
                           style={{margin: '15px 0 15px 0', padding: '0'}}>
            <Editor
                defaultEditorState={plug}
                editorState={plug}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder={'Поле для ответа ученика'}
                onEditorStateChange={updateTextPlug}
                toolbar={{
                    options: ['inline'],
                    inline: {
                        inDropdown: false,
                        className: 'media icon_font',
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                        bold: {icon: bold, className: undefined},
                        italic: {icon: italic, className: undefined},
                        underline: {icon: underline, className: undefined},
                    }
                }}/>
        </FormMainContainer>
    )

    //Редактор поля для сочинения
    const essayText = (
        <FormMainContainer className={'padding noTopBotPadding'}
                           style={{margin: '15px 0 15px 0', padding: '0'}}>
            <Editor
                defaultEditorState={editorState}
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={updateTextDescription}
                toolbar={{
                    options: ['inline', 'image', 'link', 'list', 'colorPicker'],
                    inline: {
                        inDropdown: false,
                        className: 'media icon_font',
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                        bold: {icon: bold, className: undefined},
                        italic: {icon: italic, className: undefined},
                        underline: {icon: underline, className: undefined},
                    },
                    colorPicker: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: ['rgb(0,0,0)', 'rgb(192,192,192)', 'rgb(128,128,128)', 'rgb(255,255,255)', 'rgb(128,0,0)',
                            'rgb(255,0,0)', 'rgb(128,0,128)', 'rgb(255,0,255)', 'rgb(0,128,0)', 'rgb(0,255,0)',
                            'rgb(128,128,0)', 'rgb(255,255,0)', 'rgb(0,0,128)',
                            'rgb(0,0,255)', 'rgb(0,128,128)', 'rgb(0,255,255)'],
                    },
                    list: {
                        inDropdown: false,
                        className: 'media icon_list',
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered'],
                        unordered: {icon: unordered, className: undefined},
                    },
                    link: {
                        inDropdown: false,
                        className: 'media icon_link',
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: '_self',
                        options: ['link'],
                        link: {icon: link, className: undefined},
                        linkCallback: undefined
                    },
                    image: {
                        icon: image,
                        className: 'media',
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: false,
                        uploadCallback: uploadFile,
                        alt: {present: true, mandatory: false},
                        previewImage: true,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                        defaultSize: {
                            height: 'auto',
                            width: '233px',
                        },
                    },
                }}
            />
        </FormMainContainer>)

    let contentTeacher = (<>
        {sentToTeacher ? <>
                {essayText}
                <div style={{margin: '15px 0 15px 0'}}>
                    <FormMainContainer style={{textIndent: '15px', borderRadius: '8px'}}>
                        <div style={{backgroundColor: '#fde900'}}>
                            <Editor
                                defaultEditorState={editorStateComment}
                                editorState={editorStateComment}
                                wrapperClassName="wrapper-class"
                                placeholder={'Оставить комментарий к ответу ученика'}
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={updateTextCommentDescription}
                                toolbar={{
                                    options: ['inline', 'colorPicker'],
                                    inline: {
                                        inDropdown: false,
                                        className: 'media icon_font',
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: ['bold', 'italic', 'underline'],
                                        bold: {icon: bold, className: undefined},
                                        italic: {icon: italic, className: undefined},
                                        underline: {
                                            icon: underline,
                                            className: undefined
                                        },
                                    },
                                    colorPicker: {
                                        className: undefined,
                                        component: undefined,
                                        popupClassName: undefined,
                                        colors: ['rgb(0,0,0)', 'rgb(192,192,192)', 'rgb(128,128,128)', 'rgb(255,255,255)', 'rgb(128,0,0)',
                                            'rgb(255,0,0)', 'rgb(128,0,128)', 'rgb(255,0,255)', 'rgb(0,128,0)', 'rgb(0,255,0)',
                                            'rgb(128,128,0)', 'rgb(255,255,0)', 'rgb(0,0,128)',
                                            'rgb(0,0,255)', 'rgb(0,128,128)', 'rgb(0,255,255)'],
                                    },
                                }}
                            /></div>
                    </FormMainContainer>

                    <span className="note-block">
                        <span className="switch-text-note text-muted">
                        Видимость для ученика
                        </span>

                         <label className="switch">
                            <input type="checkbox" checked={studentVisibility}
                                   onChange={onCheckboxChange}/>
                            <span className="switch-slider"/>
                        </label>
                    </span>

                    <FormBtnContainer
                        style={{display: 'flex', justifyContent: 'center',}}>
                        <button className={styles.button}
                                onClick={(e) => handleCreateCommit(e, 'revision')}>
                            Отправить на доработку
                        </button>
                        <button className={styles.button}
                                onClick={(e) => handleCreateCommit(e, 'done')}>
                            Задание выполнено
                        </button>
                    </FormBtnContainer>
                </div>
            </>
            : verifiedContent
        }
    </>)

    let contentStudent = (<>
        {!sentToTeacher ? <>
                <div style={{margin: '22px 20px 35px 23px', borderRadius: '8px'}}>
                    <Comment handleVisibleComment={handleVisibleComment}
                             comments={dataComments}
                             userType={userType}
                             header={'Комментарий'}
                             homeworkId={dataHomework?.id}
                    />
                </div>

                {essayText}

                <FormBtnContainer style={{display: 'flex', justifyContent: 'center',}}>
                    <button className={styles.button} style={{width: '400px'}}
                            onClick={(e) => sendItTeacher(e)
                            }>Сохранить
                    </button>
                </FormBtnContainer>
            </>

            : verifiedContent
        }
    </>)

    if (isHomeworkRoom || isClassRoom) {
        content = (
            <>
                {userType === 'student' && contentStudent}
                {userType === 'teacher' && contentTeacher}
            </>
        )
    }

    return (<>
        <div style={{
            margin: '15px 0 15px 0',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        }}>
            <div style={{padding: '0px'}}>
                {<Style.SpanText
                    dangerouslySetInnerHTML={{
                        __html: (data.completed?.task || data.task || data.desc)
                    }}/>}
            </div>
            {data.file &&
            <div style={{paddingTop: '25px',}}>
                <img style={{maxHeight: '250px', borderRadius: '8px'}}
                     src={data.file}
                     alt=""/>
            </div>}
        </div>
        {userType === 'teacher' && !isHomeworkRoom && !isClassRoom && <>{plugText}</>}
        {userType === 'teacher' && sentToTeacher === null && <>{plugText}</>}
        {isCompletedTask ? verifiedContent : content}

        {(isHomeworkRoom || isClassRoom) && <CheckedWrapWriteText status={completed}>
            {isCompletedTask
                ? (<>
                    {completed === '#24A1481A' && (
                        <>
                            <img className={'icon'}
                                 src={successIcon}
                                 alt="иконка успешной проверки"/>
                            <p className={'text'}>Задание выполнено!</p>
                        </>
                    )}
                    {completed === '#DA1E281A' && message !== '' && (
                        <>
                            <img className={'icon'} src={successIcon}
                                 alt="иконка успешной проверки"/>
                            <p className={'text'}>{message}</p>
                        </>
                    )}
                </>)
                : (<>
                    {userType === 'teacher' && !sentToTeacher && !isCompletedTask &&
                    <p>Задание ожидает выполнения</p>}

                    {userType === 'teacher' && isCompletedTask && <>
                        <img className={'icon'} src={successIcon}
                             alt="иконка успешной проверки"/>
                        <p>{message}</p>
                    </>}

                    {userType === 'student' && message !== '' && <>
                        <img className={'icon'} src={successIcon}
                             alt="иконка успешной проверки"/>
                        <p>{message}</p>
                    </>}
                </>)}
        </CheckedWrapWriteText>}
    </>)
};

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks,
    }
};

export default connect(mapStateToProps)(MediaWriteText);
