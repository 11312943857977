import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import CheckedBlock from '../checkedBlock/checkedBlock';
import {
    ColumnsWordForImg,
    ColumnWordForImg,
    ImageWrap
} from './mediaWordForPictureStyled';
import Notes from '../notes/notes';
import {
    DragWordsWrap,
    EmptyWord,
    Word,
    WordsSection
} from '../mediaDragWords/dragWordsStyled';
import ResetProgress from '../resetProgress/resetProgress';

const MediaWordForPicture = ({data, userType, tasks, handleCheckTask}) => {
    const [listData, setData] = useState([]);
    const [emptiesList, setEmptiesList] = useState([]);
    const [active, setActive] = useState(null);
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {

        if (!data.id) return
        if (data.completed?.completed) {
            displayingTask(data.completed?.task, true);
        } else if (data.completed?.have_answer) {
            displayingTask(data.completed?.answer, true);
            if (!!data.completed?.additional_info) setEmptiesList(JSON.parse(data.completed?.additional_info));
        } else {
            displayingTask(data.completed?.task || data.words_to_image || data.task, false)
        }
    }, [data])

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) {
            setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');

            if (JSON.stringify(listData) !== JSON.stringify(taskChecked.answer)) {
                const list = (taskChecked.answer).map(item => ({
                    ...item,
                    word: [item.word]
                }));
                setData(list);

                if (!!taskChecked.additional_info) setEmptiesList(JSON.parse(taskChecked.additional_info));
            }
        }
    }, [tasks]);

    const displayingTask = (task, isCompleted) => {
        let newEmptiesList = [];
        const list = (task).map(item => {
            if (isCompleted) {
                return item
            } else {
                newEmptiesList.push(item.word)
                return {...item, word: []}
            }
        });

        newEmptiesList.sort(() => Math.random() - .5);
        setData(list);
        setEmptiesList(newEmptiesList);
    }

    const setActiveWord = (word) => setActive(word);

    const dndStart = (e, word) => {
        e.target.style.opacity = '0.4';
        setTimeout(() => {
            setActive(word)

            const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
            const allDnDWord = document.querySelectorAll('.' + classNameDrag);
            allDnDWord.forEach(word => {
                word.classList.remove(classNameDrag)
            })
            e.target.classList.add(classNameDrag)
        })
    }

    const dndEnd = (e) => {
        e.target.style.opacity = '1';
        const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
        e.target.classList.remove(classNameDrag)
    }

    const dndEnter = (e) => {
        e.target.classList.add('dnd-hovered');
    }

    const dndOver = (e) => {
        e.preventDefault();
    }

    const dndLeave = (e) => {
        e.target.classList.remove('dnd-hovered');
    }

    const dndDrop = (e, key) => {
        e.target.classList.add('dnd-hovered');
        document.querySelectorAll('.dnd-hovered').forEach(block => {
            block.classList.remove('dnd-hovered')
        })
        setWordToPlace(key);
    }

    const replaceWord = (item) => {
        emptiesList.push(item.word[0])
        return {...item, word: [active]}
    }

    const setWordToPlace = (key) => {
        if (!active) return

        const newList = listData.map((item, index) => {
            if (index === key) {
                return (item.word.length > 0) ? replaceWord(item) : {
                    ...item,
                    word: [...item.word, active]
                }
            } else {
                return item
            }
        })

        const newEmptiesList = emptiesList.filter(item => item !== active);
        setData(newList);
        setEmptiesList(newEmptiesList);
        setActive(false);
        handleFalseCloseChecked();
    }

    const handleCheck = () => {
        //Для отправки задания на проверку нужно отправить ответ в сл. виде
        // "answer": {"13":"курс", "14": "елка", "15": "matlab"},
        // где id - то первчиный ключ токого объекта, который лежит в "comleted": "task",
        // а значение это слово, которое относится к этой картинке.
        let answer = {}

        listData?.map((column) => {
            const wordIsArr = Array.isArray(column.word)
            const columnId = column.id
            let columnWord
            if (wordIsArr) {
                columnWord = column.word[0]
            } else {
                columnWord = column.word
            }
            Object.assign(answer, {[columnId]: columnWord});
        })

        //Проверка ячеек на незаполненные поля
        const wordUndefined = Object.values(answer).findIndex(word => word === undefined)
        if (wordUndefined === -1) {
            handleCheckTask(answer, data.id, JSON.stringify(emptiesList))
        }
    };

    const handleFalseCloseChecked = () => setCompleted(false);
    const handleResetProgress = () => displayingTask(data.completed?.task || data.words_to_image || data.task, false);

    return (
        /*     <div style={{
                 display: 'flex', justifyContent: 'center',
                 flexDirection: 'column', alignItems: 'center',
             }}>*/
        <div>
            <DragWordsWrap style={{width: '100%'}}>
                <WordsSection className={!!handleCheckTask ? 'words_classroom' : ''}>
                    {emptiesList.length > 0
                        ? emptiesList.map((word, key) => (
                            <Word
                                key={word + key}
                                onClick={() => setActiveWord(word)}
                                isSelect={active === word}
                                userType={userType}
                                word={word}
                                task={data.id}
                                draggable="true"
                                onDragStart={(e) => {
                                    dndStart(e, word)
                                }}
                                onDragEnd={dndEnd}
                            >
                                {word?.split(',')[0]}
                            </Word>))
                        : <EmptyWord/>}

                </WordsSection>

                <ColumnsWordForImg>
                    {listData?.map((col, key) => {
                        return (
                            <ColumnWordForImg
                                task={data.id}
                                empty={key}
                                userType={userType}
                                key={col.id + key}
                                onClick={() => setWordToPlace(key)}
                                onDragEnter={(e) => {
                                    dndEnter(e, key)
                                }}
                                onDragLeave={dndLeave}
                                onDragOver={dndOver}
                                onDrop={(e) => dndDrop(e, key)}

                            >
                                <ImageWrap>
                                    <div className="words-image-block"
                                         onClick={() => completed !== '#24A1481A' && setWordToPlace(col.name)}>
                                        <Word style={{
                                            minWidth: '50%',
                                            minHeight: '30px',
                                            marginTop: '10px',
                                        }}>
                                            {col.word}
                                        </Word>
                                        <img
                                            src={col.image}
                                            className="image__container"
                                            alt=""
                                        />

                                    </div>
                                </ImageWrap>
                            </ColumnWordForImg>
                        )
                    })}
                </ColumnsWordForImg>
            </DragWordsWrap>

            <div style={{width: '100%'}}>
                <Notes notes={data.notes} userType={userType}/>
            </div>

            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
                isSimple={false}
            >
                {handleCheckTask && completed !== '#24A1481A' &&
                <ResetProgress handleReset={handleResetProgress}/>}
            </CheckedBlock>}
        </div>
    )

};

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
}

export default connect(mapStateToProps)(MediaWordForPicture);
