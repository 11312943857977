import styled from "styled-components";

const IndicatorWrap = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid ${props => props.complete ? '#43a047' : '#DDE1E6'};
  background-color: ${props => props.complete ? '#43a047' : '#fff'};
  margin-right: 18px;
  min-width: 22px;
`

export {IndicatorWrap}
