import React, {useEffect, useState} from "react";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import Question from "../tf/question/question";
import MainButton from "../../../buttons/mainButton/mainButton";

import {FormAddBtn, FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap, PopupLine} from "../../styled";

import plus from '../../../../assets/media/icon/plus-blue.svg'
import NoteInput from "../../../inputs/noteInput/noteInput";

const Connect = ({
                   edit,
                   back,
                   close,
                   addTask,
                   editTask
                 }) => {
  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [words, setWords] = useState([{start: '', end: '', id: 1}]);
  const [wordId, setWordId] = useState(2);
  const [editNoteValue, setEditNoteValue] = useState({});
  const [note, setNote] = useState({});

  useEffect(() => {
    if (!!edit.id) {
      let wordIdCount = 1;
      setType('edit');
      setWords(edit.list_connects.map(item => ({...item, id: wordIdCount++})));
      setTaskId(edit.id);
      setTaskTitle(edit.title);
      setWordId(wordIdCount);
      setNote(edit.notes)
    }
  }, [])

  const connects = words.map((item) => {
    return <Question key={item.id} connect data={item} onDelete={() => deleteWord(item.id)}/>
  })

  // добавление еще одной связи
  const addMore = () => {
    setWords([...words, {start: '', end: '', id: wordId}]);
    setWordId(wordId + 1);
  }

  const deleteWord = (id) => {
    setWords(words.filter(item => item.id !== id));
  }

  // создание задания
  const handleCreateTask = (e) => {
    e.preventDefault();
    const start = e.target.start;
    const end = e.target.end;
    let listConnects = [];
    let counter = 0;

    if (Object.prototype.toString.call(start).slice(8, -1) === "RadioNodeList" && Object.prototype.toString.call(end).slice(8, -1) === "RadioNodeList") {
      start.forEach(words => {
        listConnects.push({start: words.value, end: end[counter].value});
        counter++;
      })
    } else {
      listConnects.push({start: start.value, end: end.value});
    }

    const task = {
      title: taskTitle,
      list_connects: listConnects,
      task_type: 'CONNECT',
      note:editNoteValue,
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();
    const start = e.target.start;
    const end = e.target.end;
    let listConnects = [];
    let counter = 0;

    if (Object.prototype.toString.call(start).slice(8, -1) === "RadioNodeList" && Object.prototype.toString.call(end).slice(8, -1) === "RadioNodeList") {
      start.forEach(words => {
        listConnects.push({start: words.value, end: end[counter].value});
        counter++;
      })
    } else {
      listConnects.push({start: start.value, end: end.value});
    }

    const task = {
      title: taskTitle,
      list_connects: listConnects,
      task_type: 'CONNECT',
    };

    const noteData ={
      note: editNoteValue,
      noteId:note[0].id,
    }

    editTask(taskId, task,noteData);
  }

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Связи'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
          <NoteInput
              type={type}
              label={'Заметка'}
              defaultValue={note}
              desc_note={noteValue => setEditNoteValue( noteValue)}
          />
          <h3 className="title">Слова для связей</h3>
        </FormHeadContainer>
        <PopupLine/>
        <FormMainContainer className={'padding'}>
          {connects}
        </FormMainContainer>
        <PopupLine/>
        <FormBtnContainer>
          <FormAddBtn
            onClick={addMore}
            type={'button'}
          >
            <img src={plus} alt="plus"/>
            <p>Добавить связь</p>
          </FormAddBtn>
        </FormBtnContainer>
        <PopupLine/>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
    </AdminModalTask>
  )
}

export default Connect;
