import React, {useEffect, useState} from 'react';
import {convertFromRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import axiosInstance from '../../../service/iTeacherApi';
import {NotesStyles} from './noteStyled';

const Notes = ({notes, userType, label}) => {
    const [editorStateNote, setEditorStateNote] = useState(EditorState.createEmpty())
    const [studentVisibility, setStudentVisibility] = useState(false);

    let content;
    let note;
    let isText = false
    let notEmpty = false

    let url = window.location.pathname;
    const isCourse = url?.search('/course/') != -1

    if (notes?.length > 0) {
        note = notes[0]
        isText = editorStateNote.getCurrentContent().hasText()
        notEmpty = note?.text !== '{"blocks":[],"entityMap":{}}'
    }

    useEffect(() => {
        if (note && !!note.id) {

            setEditorStateNote(EditorState.createWithContent(convertFromRaw(JSON.parse(note.text))))
            setStudentVisibility(!note.visible)
        }
    }, [notes])

    //управление видимостью подсказкок
    const onCheckboxChange = async (e) => {
        setStudentVisibility(!studentVisibility)

        if (note && !!note.id) {
            try {
                await axiosInstance.patch(`teacher/notes/${note.id}/`, {visible: studentVisibility})
            } catch (e) {
                console.log(e)
            }
        }

    }

    if (userType === 'teacher' || userType === 'admin') {
        content = (
            <NotesStyles>
                <div className="note_container">

                    <div className="visible_block">

                        {label ? <p>{label}</p> : <p>Заметка</p>}
                        <span className="note-block">
                        <span className="switch-text-note text-muted">
                            Видимость для ученика
                        </span>

                        <label className="switch">
                            <input type="checkbox"
                                   onChange={onCheckboxChange}
                                   checked={!studentVisibility}
                                   disabled={isCourse}/>
                            <span className="switch-slider"/>
                        </label>
                     </span>
                    </div>

                    <Editor
                        editorState={editorStateNote}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </NotesStyles>)
        content = isCourse && !studentVisibility ? content : !isCourse && content
    } else {
        content = ((note && note.visible) &&
            <NotesStyles>
                <div className="note_container">
                    <Editor
                        editorState={editorStateNote}
                        toolbarHidden
                        readOnly
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
            </NotesStyles>
        )
    }

    return (
        <>
            {notEmpty && isText && content}
        </>
    );
};

export default Notes;
