import axiosInstance from '../../service/iTeacherApi';
import {
    GET_ALL_HOMEWORKS,
    GET_FILTERED_HOMEWORKS,
    GET_HOMEWORK,
    CLEAR_FILTERED_HOMEWORKS,
    CLEAR_HOMEWORK,
} from '../reducers/homeworksReducer';

import {openInfoPopup, setErrorInfoText, setSuccessInfoText} from './infoPopupAction';

import index from '../index.js';

export const getAllHomeworks = (userType) => {
    return async (dispatch) => {
        try {
            const {data} = await axiosInstance.get(`/${userType}/homework/`);
            if (JSON.stringify(index.getState().homeworks.homeworkList) !== JSON.stringify(data)) {
                dispatch({type: GET_ALL_HOMEWORKS, payload: data});
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const getFilteredHomeworks = (userType, query = '') => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.get(`/${userType}/homework/?${query}`);
            dispatch({type: GET_FILTERED_HOMEWORKS, payload: response.data});
        } catch (error) {
            console.log(error)
        }
    }
}

export const getHomework = (userType, id, catchFunc = () => {
}) => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.get(`/${userType}/homework/${id}/`);
            dispatch({type: GET_HOMEWORK, payload: response.data});
        } catch (error) {
            console.log(error);
            catchFunc();
        }
    }
} 

export const addHomework = (userType, data) => {
    return async (dispatch) => {
        try {
            await axiosInstance.post(`/teacher/homework/`, data);
            await dispatch(getAllHomeworks(userType));
            dispatch(setSuccessInfoText(`Домашнее задание успешно назначено`));
        } catch (error) {
            if (error.response.data.info) {
                dispatch(setErrorInfoText(`${error.response.data.info}.`));
            } else {
                dispatch(setErrorInfoText('При создании домашнего задания произошла ошибка, попробуйте позже.'));
            }
        } finally {
            dispatch(openInfoPopup());
        }
    }
}

export const editHomework = (id, data) => {
    return async (dispatch) => {
        try {
            await axiosInstance.patch(`/teacher/homework/${id}/`, data);
            await axiosInstance.get(`/teacher/homework/${id}/assigned-homework/`);
            await dispatch(getAllHomeworks('teacher'));
            dispatch(setSuccessInfoText(`Домашнее задание успешно изменено`));
        } catch (error) {
            if (error.response.data.info) {
                dispatch(setErrorInfoText(`${error.response.data.info}.`));
            } else {
                dispatch(setErrorInfoText('При изменении домашнего задания произошла ошибка, попробуйте позже.'));
            }
        } finally {
            dispatch(openInfoPopup());
        }
    }
}

export const deleteHomework = (id) => {
    return async (dispatch) => {
        try {
            await axiosInstance.delete(`/teacher/homework/${id}/`);
            await dispatch(getAllHomeworks('teacher'));
            dispatch(setSuccessInfoText(`Домашнее задание успешно удалено`));
        } catch (error) {
            if (error.response.data.info) {
                dispatch(setErrorInfoText(`${error.response.data.info}.`));
            } else {
                dispatch(setErrorInfoText('При удалении домашнего задания произошла ошибка, попробуйте позже.'));
            }
        } finally {
            dispatch(openInfoPopup());
        }
    }
}

export const clearFilteredHomeworks = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_FILTERED_HOMEWORKS})
    }
}

export const clearHomework = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_HOMEWORK})
    }
}
