import React, {Component} from "react";
import Dropzone from 'react-dropzone';

import Button from "../../../buttons/button/button";
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";

import {ImageZone} from "../image/styled";
import {AudioWrap} from "../audio/styled";
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

import dlt from "../../../../assets/media/icon/trash_basket.svg";
import NoteInput from "../../../inputs/noteInput/noteInput";

export default class AddDocumentModal extends Component {
  constructor(props) {

    super(props);
    this.state = {
      text: '',
      name: '',
      file: '',
      audioPreview: '',
      deleteAudio: false,
      type: 'add',
      taskId: '',
      file_size: '',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.file = React.createRef();
    }, 1000)
    this.setTypeModal();
  }

  // проверяем модалка на создания или редактирование
  setTypeModal = () => {
    const {edit} = this.props;
    if (!!edit.id) {
      this.setState({
        type: 'edit',
        text: edit.title,
        file: edit.file,
        file_size: edit.file_size,
        name: edit.name_video,
        taskId: edit.id,
        note: edit.notes,
        noteId: edit.notes[0].id,
      });
    }
  }

  // создаем задание
  createTask = (e) => {
    e.preventDefault();
    const file_size = this.state.file.size < 1000000 ? Math.round(this.state.file.size / 1024) + " kb" : Math.round(this.state.file.size / 1024 / 1024) + " mb";

    const data = new FormData();
    data.set("task_type", "DOCUMENT");
    data.set("title", this.state.text);
    data.set("file", this.state.file);
    data.set("name_video", this.state.name);
    data.set("file_size", file_size);
    data.set("note",JSON.stringify( this.state.editorNote));

    this.props.addTask(data);
  }

  // изминения задания
  editTask = (e) => {
    e.preventDefault();
    const file_size = this.state.file.size < 1000000 ? Math.round(this.state.file.size / 1024) + " kb" : Math.round(this.state.file.size / 1024 / 1024) + " mb";

    const data = new FormData();
    data.set("title", this.state.text);
    data.set("name_video", this.state.name);

    if (this.props.edit.file !== this.state.file) {
      data.set("file", this.state.file);
      data.set("file_size", file_size);
    }

    const note ={
      note: this.state.editorNote,
      noteId:this.state.noteId,
    }
    this.props.editTask(this.state.taskId, data,note);
  }

  DeleteAudioItem = () => {
    this.setState(() => {
      return {
        ...this.state,
        deleteAudio: true
      }
    });
    setTimeout(() => {
      this.setState(() => {
        return {
          ...this.state,
          deleteAudio: false,
          file: '',
          audioPreview: '',
        }
      })
    }, 500)
  }


  _handleAudioChange(e) {
    console.log('open')
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        audioPreview: reader.result
      });
    }

    reader.readAsDataURL(file)

  }

  uploadDoc(doc) {
    let reader = new FileReader();
    let file = doc[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        audioPreview: reader.result
      });
    }
    reader.readAsDataURL(file);
  }

  render() {
    const {back, close} = this.props;
    const {audioPreview, file, deleteAudio} = this.state;

    let $audioPreview = null;

    if (audioPreview || file) {

      $audioPreview = (

        <ImageZone>
          <AudioWrap>
            <div className="video__container">
              <span className="video__play"/>
            </div>
            <div className="video__wrap video__wrap_1">
              <span
                className="video__text">{audioPreview ? file.name : decodeURI(file.split('/')[file.split('/').length - 1])}</span>
              <span className="video__subtext">
                {
                  audioPreview ? file.type.split('application/vnd.openxmlformats-officedocument.wordprocessingml.')
                    : file.split('/')[file.split('/').length - 1].split('.')[1]
                },
                {
                  audioPreview ? file.size < 1000000 ? Math.round(file.size / 1024) + " kb" : Math.round(file.size / 1024 / 1024) + " mb"
                    : this.state.file_size
                }
              </span>
            </div>
            <button
              onClick={this.DeleteAudioItem}
              className={'delete_btn'}
            ><img src={dlt} alt="icon"/></button>
          </AudioWrap>
        </ImageZone>

      );

    } else {

      $audioPreview = (

        <Dropzone onDrop={acceptedFile => this.uploadDoc(acceptedFile)}>

          {({getRootProps, getInputProps, isDragActive}) => (

            <div {...getRootProps()} className={`cover__row cover__row_3`}>

              {isDragActive

                ? <span className="cover__title">Загрузить!</span>

                : <>

                  <span className="cover__title">Перетащите файл сюда</span>

                  <span className="cover__subtitle">или</span>

                  <Button
                    text="Выбрать файл на устройстве"
                    type="cover"
                  />

                </>}

              <input

                {...getInputProps()}
                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                className={'hidden-input'}
                id="contained-button-file"
                name={'banner'}
                onChange={(e) => this._handleAudioChange(e)}

              />

            </div>

          )}

        </Dropzone>

      );

    }

    return (
      <AdminModalTask
        type={this.state.type}
        back={back}
        title={'Документ'}
        close={close}
      >
        <FormWrap onSubmit={(e) => {
          this.state.type === 'add' ? this.createTask(e) : this.editTask(e)
        }}>
          <FormHeadContainer>
            <MainInput
              className={'task-input_head'}
              type={'text'}
              label={'Условия упражнения'}
              name={'title'}
              required={true}
              defaultValue={this.state.text}
              updateData={e => this.setState({text: e.target.value})}
            />
            <NoteInput
                type={this.state.type}
                label={'Заметка'}
                defaultValue={this.state.note}
                desc_note={noteValue => this.setState({editorNote: noteValue})}
            />
            <MainInput
              className={'task-input_name'}
              type={'text'}
              label={'Название документа'}
              name={'name'}
              required={true}
              defaultValue={this.state.name}
              updateData={e => this.setState({name: e.target.value})}
            />
          </FormHeadContainer>
          <FormMainContainer className={'padding noTopBotPadding'}>
            {
              deleteAudio
                ? <Dropzone onDrop={acceptedFile => this.uploadDoc(acceptedFile)}>
                  {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps()} className={`cover__row cover__row_3`}>

                      {isDragActive

                        ? <span className="cover__title">Загрузить!</span>

                        : <>

                          <span className="cover__title">Перетащите файл сюда</span>

                          <span className="cover__subtitle">или</span>

                          <Button
                            text="Выбрать файл на устройстве"
                            type="cover"
                          />
                        </>}
                      <input
                        {...getInputProps()}
                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        className={'hidden-input'}
                        id="contained-button-file"
                        name={'banner'}
                        onChange={(e) => this._handleAudioChange(e)}
                      />
                    </div>
                  )}
                </Dropzone>
                : $audioPreview
            }
          </FormMainContainer>
          <FormBtnContainer>
            <MainButton
              text={this.state.type === 'add' ? 'Добавить' : 'Сохранить'}
              width={'full'}
              type={'submit'}
            />
          </FormBtnContainer>
        </FormWrap>
      </AdminModalTask>
    )
  }
}
