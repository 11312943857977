import styled from 'styled-components';

import icon_close from '../../../assets/media/icon/cross_rotate.svg';
import {baseTheme} from "../../../baseTheme";

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 200;
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 54px;

  .popup__title {

    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;

    &_delete {

      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  @media ${baseTheme.media.medium} {
    padding: 24px 16px;
  }
`;

const Content = styled.div`
  min-width: 539px;
  max-width: 660px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(105, 112, 119, 0.12),
              0px 8px 16px rgba(105, 112, 119, 0.16);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  max-height: 100%;

  form {
    width: 100%;
  }

  @media ${baseTheme.media.medium} {
    max-width: 100%;
    width: 100%;
    min-width: auto;
    padding: 16px;
  }
`;


const PopupTitle = styled.div`

  user-select: none;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 24px;

  @media ${baseTheme.media.medium} {
    font-size: ${baseTheme.fz.titlePopupSmall};
    line-height: ${baseTheme.lh.titlePopupSmall};
  }
`;

const PopupTitleSmall = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
`;


const PopupClose = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    background: url(${icon_close}) center center/cover no-repeat;

  @media ${baseTheme.media.medium} {
    top: 16px;
    right: 16px;
  }
`;

export { PopupWrapper, PopupContainer, Content, PopupClose, PopupTitle, PopupTitleSmall };
