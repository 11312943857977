import React, {useState, useEffect} from "react";
import {Route, Switch} from "react-router";
import {connect} from "react-redux";

import Header from "../header/header";
import TopAlertLine from "../alerts/topAlertLine/topAlertLine";
import InfoPopup from "../infoPopup/infoPopup";

import axiosInstance from "../../service/iTeacherApi";
import {routingData} from "./data";

import {signIn} from "../../store/actions/usersAction";
import {getAllStudents} from "../../store/actions/studentsAction";
import {getAllAdminCourses, getAllCourses} from "../../store/actions/coursesAction"
import {getAllSchedules} from "../../store/actions/schedulesAction";
import PopupAddCourse from "../popupsAdmin/popupCourse/addCourse/popupAddCourse";
import Footer from "../footer/footer";
import {getAllHomeworks} from "../../store/actions/homeworksAction";

const App = ({
               user,
               userType,
               signIn,
               getAllCourses,
               getAllSchedules,
               getAllHomeworks,
               getAllStudents,
               getAllAdminCourses,
               topAlert,
               isOpenInfoPopup,
               isOpenAddCoursePopup
             }) => {
  const [loading, setLoading] = useState(false)
  const [topAlertMessage, setTopAlert] = useState(false)

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // задаем статуст top alert
  useEffect(() => {
    setTopAlert(topAlert)
  }, [topAlert]);

  useEffect(() => {
    if (user.id) {
      if (userType !== "admin") {
        getAllCourses(userType);
        getAllSchedules(userType);
        getAllHomeworks(userType)
      } else {
        getAllAdminCourses();
      }
      if (userType === "teacher") {
        getAllStudents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  // проверяем наличие токена пользователя
  const checkToken = async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      axiosInstance.defaults.headers["Authorization"] = "JWT " + token;
      signIn()
    }
    setLoading(true)
  }

  return (
    <>
      {/*alert message*/}
      {
        topAlertMessage && <TopAlertLine text={topAlertMessage}/>
      }
      {
        isOpenInfoPopup && <InfoPopup/>
      }
      {
        (isOpenAddCoursePopup && user.type !== 'STUDENT') && <PopupAddCourse/>
      }
      {/*alert message*/}
      <Header user={user}/>
      <Switch>
        {
          loading &&
          routingData.map(rout => {
            return (
              <Route exact path={rout.path} key={rout.path}>
                {
                  user.type ? rout.components[user.type.toLowerCase()] : rout.redirect
                }
              </Route>
            )
          })
        }
      </Switch>
      <Footer/>
    </>
  )
}

const mapStateToProps = ({users, infoPopup, addCoursePopup}) => {
  return {
    user: users.user,
    userType: users.userType,
    topAlert: users.topAlert,
    isOpenInfoPopup: infoPopup.isOpenPopup,
    isOpenAddCoursePopup: addCoursePopup.isOpenPopup
  }
};

const mapDispatchToProps = (dispatch) => ({
  signIn: () => dispatch(signIn()),
  getAllAdminCourses: () => dispatch(getAllAdminCourses()),
  getAllCourses: (userType) => dispatch(getAllCourses(userType)),
  getAllSchedules: (userType) => dispatch(getAllSchedules(userType)),
  getAllHomeworks: (userType) => dispatch(getAllHomeworks(userType)),
  getAllStudents: () => dispatch(getAllStudents())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
