import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

const CourseWrapper = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 201px);
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  
  .course-wrapper {
    text-decoration: none;
    
    .course-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-top: 16px;
      margin-bottom: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
    .course-next-lesson {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #697077;
    }
  }
  
  @media ${baseTheme.media.extraLarge} {
    gap: 24px 16px;
  }

  @media ${baseTheme.media.medium} {
    grid-template-columns: repeat(auto-fit, 156px);
    gap: 16px;
  }

  @media ${baseTheme.media.small} {
    justify-content: center;
  }

  @media ${baseTheme.media.extraSmall} {
    grid-template-columns: 1fr;
  }
`

export {
  CourseWrapper
}
