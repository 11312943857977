import React, {useState} from 'react';
import {InputWrap} from "../personalDataStyled";

const InputMonth =  ({defaultMonth}) => {
  const [month, setMonth] = useState(defaultMonth)
  const changeInput = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setMonth(value);
  };

  return (
    <InputWrap className={'month'}>
      <label className={'labelMonth active'} >Месяц</label>
      <input className={'input'} name={'month'} value={month} min={0} max={12}  type="number"  onChange={changeInput}/>
    </InputWrap>
  )
}

export default InputMonth
