import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import ConfirmPopup from "../../../confirmPopup/confirmPopup";
import InfoBlock from "../infoBlock";
import InfoNoItem from "../InfoNoItem";
import InfoItem from "../InfoItem";
import {getCreateTimeString} from "../../../../service/timePrettier";

import iconCross from '../../../../assets/media/icon/cross.svg';

const HomeworkBlock = ({userType, data, onAddHomework, onDeleteHomework}) => {
  const history = useHistory();
  const [selectedHomework, setSelectedHomework] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleEditHomeworkPush = (id) => history.push(`/homework/${id}`);

  const handleOpenDeleteHomeworkModal = (homework) => {
    setSelectedHomework(homework);
    setIsOpenDeleteModal(true);
  };

  const handleDeleteHomework = () => onDeleteHomework(selectedHomework.id, closeAllModal);
  const closeAllModal = () => {
    setIsOpenDeleteModal(false);
    setSelectedHomework({});
  }

  const homeworkItems = data.map((homework) => (
      <InfoItem
        key={homework.id}
        type={'schedule'}
        name={`${homework?.template?.lesson?.name}`}
        time={getCreateTimeString(homework.time_assigned)}
        status={homework.status}
        editFunc={userType === 'teacher' ? () => handleEditHomeworkPush(homework.id) : null}
        deleteFunc={userType === 'teacher' ? () => handleOpenDeleteHomeworkModal(homework) : null}
      />
    )
  )

  return (
    <>
      <InfoBlock
        title={'Домашние задания'}
        onItemFunc={onAddHomework}
        iconSrc={iconCross}
      >
        {data.length > 0 ? (
          homeworkItems
        ) : (
          <InfoNoItem>
            {userType === 'teacher' ? 'Нет заданий на проверке' : 'Нет назначенных заданий'}
          </InfoNoItem>
        )}
      </InfoBlock>
      {isOpenDeleteModal && (
        <ConfirmPopup
          onConfirm={handleDeleteHomework}
          onClose={closeAllModal}
          text={`Вы действительно хотите удалить\u00a0домашнее задание\u00a0${selectedHomework.template?.lesson?.name}\u00a0?`}
          successText={'Да, удалить'}
        />
      )}
    </>
  )
}

export default HomeworkBlock
