import React from "react";

import MainInput from "../../../../../inputs/mainInput/mainInput";

import * as Style from './style'
import {TextBlock} from "../../../sentenceOfWords/style";

const ColumnBlock = ({col, onChangeName, onChangeWords, onDelete}) => {

  return (
    <>
      <Style.Wrapper>
        <Style.Title>Колонка</Style.Title>
        <TextBlock>
          <div className="desc">Напишите слова для этой колонки через ", "</div>
        </TextBlock>
        <MainInput
          type={'text'}
          label={'Название колонки'}
          name={'name_column'}
          required={true}
          defaultValue={col.name}
          updateData={onChangeName}
        />
        <MainInput
          type={'text'}
          label={'Слова для этой колонки'}
          name={'words_column'}
          required={true}
          defaultValue={col.words}
          updateData={onChangeWords}
        />
        <div onClick={onDelete} className="delete"/>
      </Style.Wrapper>
    </>
  )
}

export default ColumnBlock;
