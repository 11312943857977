import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {baseTheme} from "../../../baseTheme";

const CoursesItem = styled(NavLink)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;

  a {
    display: block;
    text-decoration: none;
  }
  
  .course-edit {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    display: none;
  }

  :hover {
    .course-edit {
      display: block;
    }
  }

  @media ${baseTheme.media.medium} {
    .course-edit {
      display: block;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #111111;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
    &--margin {
      margin-bottom: 52px;
    }
    
    &--student-margin {
      margin-bottom: 47px;
    }
  }
  
  .desc {
    color: #697077;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
  }

  .ava {
    width: 24px;
    height: 24px;
    object-position: center;
    object-fit: cover;
    border-radius: 50%;
  }

  .info {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .desc{
      margin: 0 0 0 10px;
    }
  }
  
  
`

export {CoursesItem}
