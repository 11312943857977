import React from 'react';
import { connect } from "react-redux";
import PopupLesson from '../popupLesson';

import axiosInstance from "../../../../service/iTeacherApi";

export const PopupEditLesson = ({userType, lesson, courseId, updateCourse, close}) => {

  const editLessonData = async (name) => {
    try {
      const data = {name}
      await axiosInstance.patch(`/${userType}/lessons/${lesson.id}/`, data);
      updateCourse(courseId)
      close();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <PopupLesson
      type={'edit'}
      func={editLessonData}
      close={close}
      lessonName={lesson.name}
    />
  );
}

const mapStateToProps = ({users}) => {
  return {
    user: users.user
  }
};

export default connect( mapStateToProps )( PopupEditLesson );
