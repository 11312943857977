import React, {useState} from "react";
import {ConnectWrap} from "./styled";

const ConnectBlock = () => {
  const [linkValue, setLinkValue] = useState(document.location.href);
  const handleCopyClickBoard = () => {
    navigator.clipboard.writeText(document.location.href)
      .then(() => {
        setLinkValue('Ссылка скопирована');
        setTimeout(() => setLinkValue(document.location.href), 6000);
      })
      .catch(err => {
        setLinkValue('Ошибка копирования');
        setTimeout(() => setLinkValue(document.location.href), 3000);
        console.log(err)
      });
  }
  return (
    <ConnectWrap>
      <div className={'link-container'}>
        <p className={'title'}>Ссылка для подключения студента</p>
        <input className={'link__input'} type="text" readOnly={true} value={linkValue}/>
        <button className={'link__copy-btn'} title={'Скопировать ссылку'} onClick={handleCopyClickBoard}></button>
      </div>
    </ConnectWrap>
  )

}

export default ConnectBlock
