import React, {useState, useRef, useEffect} from 'react'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import {canvasPreview} from './canvas/canwasPreview'
import {useDebounceEffect} from './canvas/useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import {ModalContainer, ModalWrapper} from '../../../../ui/modal/styled';
import styles
    from '../../../../templateMedia/mediaWriteText/editor_styles/teacher.module.css';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export default function NewSize({url, setResizeImg}) {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)
    const [dataUrl, setDataUrl] = useState(null);

    useEffect(() => {
        setCrop(undefined)
        setImgSrc(url)
    }, [url])

    function onImageLoad(e) {
        if (aspect) {
            const {width, height} = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                    setDataUrl,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function saveImage(e) {
        e.preventDefault()

        setResizeImg(dataUrl)
    }

    return (
        <ModalWrapper>
            <ModalContainer>
                <div style={{display: 'flex', flexDirection: 'column',minHeight:'400px',justifyContent: 'center'}}>

                    {Boolean(imgSrc) && (<>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{
                                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                                        maxHeight: '400px'
                                    }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            <button className={styles.button} style={{margin: '0'}}
                                    onClick={(e) => saveImage(e)}>сохранить
                            </button>
                        </>
                    )}
                    <div>
                        {Boolean(completedCrop) && (
                            <canvas id={'canvas'}
                                    ref={previewCanvasRef}
                                    style={{
                                        display: 'none',
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                            />
                        )}

                    </div>
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}
