import React from 'react';
import CourseTabs from './courseTabs';
import {Link} from "react-router-dom";

// add icon/image
import close from "../../assets/media/icon/close.svg";
import plus from '../../assets/media/icon/plus.svg';
import plusBlue from '../../assets/media/icon/plus-blue.svg';

// style
import {AddCoursesWrap, TitleSection, AddCourse, AddCourseMobile} from "./addCoursesStyle";
import {WithOutHeaderContainer} from "../singleCourse/styled";
import {useDispatch} from "react-redux";
import {openAddCoursePopup} from "../../store/actions/addCoursePopupAction";

const AddCourses = () => {
  const dispatch = useDispatch();
  const handleOpenAddCoursePopup = () => dispatch(openAddCoursePopup());
  return (
    <WithOutHeaderContainer>
      <Link to='/Courses' className='close'>
        <img src={close} alt="icon"/>
      </Link>
      <AddCoursesWrap>
        <TitleSection>
          <h1>Выберите курс</h1>
          <AddCourse onClick={handleOpenAddCoursePopup}>
            <p>Создать курс
              <img src={plus} alt="icon"/>
            </p>
          </AddCourse>
          <AddCourseMobile onClick={handleOpenAddCoursePopup}>
            <img src={plusBlue} alt="icon"/>
          </AddCourseMobile>
        </TitleSection>
        <CourseTabs/>
      </AddCoursesWrap>
    </WithOutHeaderContainer>
  )
}

export default AddCourses;

