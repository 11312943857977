import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

export const PopupLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #DDE1E6;
`

export const FormWrap = styled.form`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  h3.title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
    margin-top: 24px;

    @media ${baseTheme.media.medium} {
      margin-top: 16px;
    }
  }
`

export const FormHeadContainer = styled.div`
  padding: 0 24px 24px;

  @media ${baseTheme.media.medium} {
    padding: 0 16px 16px;
  }
`

export const FormMainContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  &.padding {
    padding: 24px;

    @media ${baseTheme.media.medium} {
      padding: 16px;
      min-height: 150px;
      display: contents;
    }
  }

  &.noTopBotPadding {
    padding-top: 0;
    padding-bottom: 0;

    .format-support {
      margin-top: 5px;
    }
  }

  .cover {

    &__row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      //justify-content: flex-start;
      align-items: flex-start;

      &_1 {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #DDE1E6;
      }

      &_2 {
        justify-content: space-between;
        flex-flow: row wrap;
        padding-top: 8px;
      }

      &_3 {
        min-height: 200px;
        height: 100%;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        border: 2px dashed #DDE1E6;
        border-radius: 8px;
      }
    }

    &__gradient, &__image {
      color: #697077;
      line-height: 22px;
      padding-bottom: 18px;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        color: #000000;
        outline: transparent;
      }
    }

    &__gradient_active:after, &__image_active:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background: #4F7FFF;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      z-index: 5;
    }

    .cover__active {
      display: block;
    }

    &__gradient {
      margin-right: 32px;
    }

    &__gradient-item {
      margin-top: 16px;
      width: 111px;
      height: 111px;
      border-radius: 8px;
      cursor: pointer;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
      text-align: center;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 22px;
      color: #697077;
      margin-bottom: 16px;
    }
  }

  p {
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #697077;
    margin-top: -17px;
    padding-left: 15px;
  }

  .wrapper-class {
    width: 100%;
    opacity: 0.8;
    border-radius: 8px;
    position: relative;
    height: 100%;
    background: #DDE1E6;
    display: flex;
    flex-direction: column;

    .toolbar-class {
      background: #DDE1E6;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 0;
      border-bottom: 1px solid #D0D3D7;
      padding: 16px 16px 12px;
      position: sticky;
      top: 0;
      z-index: 2;

      .icons {
        border-right: 1px solid #D0D3D7;
        margin-right: 12px;
        margin-bottom: 0;

        .rdw-option-wrapper {
          background-color: transparent;
          border: none;
          margin: 0;
          margin-right: 12px;
          padding: 0;
          width: 18px;
          height: 18px;
          min-width: 18px;
          object-fit: cover;

          &:hover {
            box-shadow: none;
          }
        }
      }

      .rdw-image-wrapper {

        .icons {
          background-color: transparent;
          border: none;

          &:hover {
            box-shadow: none;
          }
        }
      }

      .icon_text,
      .icon_list,
      .icon_font {

      }
    }
  }

  .editor-class {
    flex: 2;
    padding: 17px 16px 17px;
    cursor: text;

    .DraftEditor-root {
     /* min-height: 270px;*/

      .DraftEditor-editorContainer {
        .public-DraftStyleDefault-block {
          margin: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${baseTheme.media.medium} {
    .cover__title {
      text-align: center;
    }

    .cover__row_3 {
      flex-grow: 1;
      padding: 16px;
    }
    
    .rdw-image-modal,
    .rdw-link-modal {
      left: 50%;
      transform: translateX(-50%);

      .rdw-image-modal-btn,
      .rdw-link-modal-btn {
        width: 75px;
        padding: unset;
      }
    }
  }


  @media ${baseTheme.media.extraSmall} {

    .wrapper-class {
      .rdw-editor-toolbar {
        padding: 8px;
      }

      .editor-class {
        padding: 8px;

        .DraftEditor-root {
          min-height: auto;
        }
      }
    }
  }
`

export const FormBtnContainer = styled.div`
  padding: 24px;

  @media ${baseTheme.media.medium} {
    padding: 16px;
  }
`

export const FormAddBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 22px;
  min-height: 22px;
  cursor: pointer;
  transition: .3s;

  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    object-position: center;
    display: block;
    margin-right: 11px;
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4F7FFF;
  }

  &:hover,
  &:focus {
    opacity: .6;
  }

  &:active {
    transform: translateY(2px);
  }
`
