import React from 'react';
import InfoItem from "../InfoItem";
import InfoBlock from "../infoBlock";

import iconPan from "../../../../assets/media/icon/pan.svg";
import iconArrow from "../../../../assets/media/icon/arrow-blue-down.svg";

const StudentBlock = ({data, isMore, onEditStudent, onToggleMoreInfo}) => {
  return (
    <InfoBlock
      title={'Информация'}
      // onItemFunc={onEditStudent}
      iconSrc={iconPan}
    >
      {data.map(item => (
        <InfoItem
          name={item.name}
          desc={item.value}
        />
      ))}
{/*      <InfoItem
        type={'more-info'}
        isMore={isMore}
        text={isMore ? 'Показать меньше информации' : 'Показать больше информации'}
        onClickHandle={onToggleMoreInfo}
      >
        <img src={iconArrow} alt="icon"/>
      </InfoItem>*/}
    </InfoBlock>
  );
};

export default StudentBlock;
