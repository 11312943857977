import { OPEN_COURSE_POPUP, CLOSE_COURSE_POPUP } from "../reducers/addCoursePopupReducer";

export const openAddCoursePopup = () => {
  return {type: OPEN_COURSE_POPUP}
}

export const closeAddCoursePopup = () => {
    return (dispatch) => {
      dispatch({ type: CLOSE_COURSE_POPUP })
    }
}
