const initState = {
  students: [],
  student: {},
  isStudentLoading: true
}

export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const CLEAR_STUDENT = 'CLEAR_STUDENT';
export const ADD_NEW_STUDENT = 'ADD_NEW_STUDENT';

const studentsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENTS:
      return {
        ...state,
        students: action.payload,
      }

    case GET_STUDENT:
      return {
        ...state,
        student: action.payload,
        isStudentLoading: false
      }

    case CLEAR_STUDENT:
      return {
        ...state,
        student: {},
        isStudentLoading: true
      }

    case ADD_NEW_STUDENT:
      return {
        ...state,
        students: state.students.push(action.payload)
      }

    default:
      return state
  }
}

export default studentsReducer;
