import React, {useState} from 'react';

import Popup from '../popupContainer/popupContainer';
import Button from "../../buttons/button/button";
import InputPrice from "../../inputs/inputsAdmin/inputPrice/inputPrice";
import InputText from "../../inputs/inputsAdmin/inputText/inputText";

import {PopupTitle} from '../popupContainer/popupStyled';
import {useSelector} from "react-redux";
import MainDropList from "../../inputs/mainDropList/mainDropList";

const levelVariables = [
  ['Не выбрано', 'Не выбрано'],
  ['P','P - Pre-elementary'],
  ['A1', 'A1 - Beginner'],
  ['A2', 'A2 - Elementary'],
  ['B1', 'B1 - Pre-intermediate'],
  ['B2', 'B2 - Intermediate'],
  ['C1', 'C1 - Advanced'],
  ['C2', 'C2 - Proficiency'],
];

const PopupCourse = ({type, course, func, close}) => {
  const user = useSelector(state => state.users.user);
  const levelSelected = course?.level ? (levelVariables.find(i => i[0] === course.level) || levelVariables[0]) : levelVariables[0];
  const [name, setName] = useState(course?.name || "");
  const [level, setLevel] = useState(levelSelected);
  const [price, setPrice] = useState(course?.price || 0);

  const sendData = () => {
    const data = {name, level: level[0], price};
    func(data);
  }
  const handleChange = (value) => {
    setLevel(value);
  }

  return (
    <Popup onClose={close}>
      <PopupTitle>
        {`${type === 'add' ? 'Создать' : 'Редактировать'} курс`}
      </PopupTitle>
      <InputText
        placeholder="Название курса"
        value={name}
        set={setName}
      />
      <MainDropList
        label="Уровень"
        name="complexity"
        options={levelVariables}
        onChange={handleChange}
        defaultId={levelSelected[0]}
      />
      {user.type === "ADMIN" &&
      <InputPrice
        type="price"
        value={price}
        set={setPrice}
        placeholder="0.00"
      />}
      <Button
        type="add-popup"
        text={`${type === 'add' ? 'Создать' : 'Редактировать'} курс`}
        func={sendData}
      />
    </Popup>
  );
}

export default PopupCourse;
