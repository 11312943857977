import React from "react";

import InfoBlock from "../../../components/ui/infoBlock/infoBlock";
import InfoItem from "../../../components/ui/infoBlock/InfoItem";
import {ActiveSidebarWrap} from "../styled";

const LeftSidebar = ({course}) => {
  let countLesson = course.course_template.lessons.length;
  const countLessonString = `${(countLesson === 0 || countLesson >= 5) ? ' уроков' : countLesson === 1 ? ' урок' : ' урока'}`

  return (
    <ActiveSidebarWrap className='left'>
      <InfoBlock title={'Информация'}>
        <InfoItem
          name={'Длительность курса'}
          desc={`${countLesson} ${countLessonString}`}
        />
        <InfoItem
          name={'Уровень'}
          desc={course.course_template.level}
        />
      </InfoBlock>
    </ActiveSidebarWrap>
  )
}

export default LeftSidebar;
