import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

const TextBlock = styled.div`
  //margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
    min-width: 90px;
  }
  
  .desc {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #697077;
    
    ul {
      margin: 0;
      padding: 5px 0 0 10px;
      list-style: none;
    }
  }

  @media ${baseTheme.media.medium} {
    margin-top: 8px;
    flex-direction: column;

    .title {
      font-size: 14px;
      line-height: 18px;
      margin-right: 0;
      margin-bottom: 8px;
    }

    .desc {
      font-size: 12px;
      line-height: 16px;
    }

    .switch-text {
      font-size: 12px;
      line-height: 16px;
    }
  }
`

export {TextBlock}
