import React from 'react';
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import MediaModals from '../../../components/mediaModals/mediaModals';

const TasksContent = (
    {
        handleDoneTask,
        handleRevision,
        handleUpdateComment,
        handleVisibleForTeacher,
        dataHomework,
        data,
        handleCheckTask,
        createComment,
        handleVisibleComment,
        handleUpdateWritingText,
    }) => {

    const renderTaskList = data.map(task => (
            <SwiperSlide key={task.id} className={'taskItem'} tasktype={task.task_type}>
                <div className="section__title">{task.title}</div>
                <MediaModals
                    handleDoneTask={handleDoneTask}
                    handleRevision={handleRevision}
                    handleVisibleForTeacher={handleVisibleForTeacher}
                    handleCheckTask={handleCheckTask}
                    type={task.task_type}
                    data={task}
                    createComment={createComment}
                    handleUpdateComment={handleUpdateComment}
                    roomType={'homework'}
                    dataHomework={dataHomework}
                    handleVisibleComment={handleVisibleComment}
                    handleUpdateWritingText={handleUpdateWritingText}
                />
            </SwiperSlide>
        )
    )

    return (
        <Swiper
            className={'tasksList'}
            scrollbar={false}
            modules={[Navigation, Pagination]}
            navigation={true}
            allowTouchMove={false}
            pagination={{
                'type': 'fraction'
            }}

        >
            {renderTaskList}
        </Swiper>
    );
};

export default TasksContent;
