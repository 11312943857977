import styled from 'styled-components';
import {baseTheme} from '../../../baseTheme';

import icon_play from '../../../assets/media/icon/audio.svg';
import icon_play_active from '../../../assets/media/icon/audioActive.svg';
import icon_change from '../../../assets/media/icon/refresh.svg';

const AudioWrap = styled.div`

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  position: relative;

  .video {

    &__container {
      z-index: 11;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-color: #DDE1E6;
      margin-right: 16px;
      border-radius: 50%;
      border: none;
    }

    &__play {
      width: 18px;
      height: 18px;
      background: url(${icon_play}) center center/contain no-repeat;
      cursor: pointer;

      &.active {
        background-image: url(${icon_play_active});
      }
    }

    &__wrap {

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 90vh;
      height: 40px;

      &_1 {

        flex-flow: column nowrap;
        margin-right: auto;
        min-height: 48px;
      }

      &_2 {

        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

      }

    }

    &__subtext {

      font-size: 14px;
      line-height: 20px;
      color: #697077;
      margin-left: 5px;
    }

    &__icon {

      display: block;
      width: 20px;
      height: 16px;
      background: url(${icon_change}) center center/cover no-repeat;
      margin-right: 9px;

    }

    &__remove {

      color: #4F7FFF;

    }

  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
    padding: 0 16px;
    flex-grow: 1;
    overflow: auto;
    align-items: flex-start;

  }
`;

export {AudioWrap};
