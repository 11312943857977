import React from "react";
import AddButton from "../../../buttons/addButton/addButton";
import InfoBlock from "../infoBlock";
import InfoItem from "../InfoItem";

import ava from "../../../../assets/media/icon/avatar.svg";

const MemberBlock = ({show, list, userType, isStudentCourse, isInactiveCourse}) => {

  return (
    <InfoBlock title={userType === 'teacher' ? isStudentCourse ? 'Ученик' : 'Ученики' : 'Преподаватель'}>
      {list?.length > 0
        ? list?.map((item) => (
          <InfoItem
            key={item?.id}
            type={'user'}
            photoSrc={item?.user?.photo || ava}
            name={item?.user?.username || item?.user?.email}
          />
        ))
        : list?.user
          ? <InfoItem
            type={'user'}
            photoSrc={list?.user?.photo || ava}
            name={list?.user?.username || list?.user?.email}
          /> : null
      }
      {userType === 'teacher' && !isStudentCourse && <AddButton
        style={isInactiveCourse ? {opacity: 0.4} : null}
        text={'Добавить ученика'}
        func={!isInactiveCourse ? show : null}
      />}
    </InfoBlock>
  )
}

export default MemberBlock;
