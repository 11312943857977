import React, { useState } from 'react';
import {InputWrap} from "../personalDataStyled";


const InputYear =  ({defaultYear}) => {
  const [year, setYear] = useState(defaultYear)
  const changeInput = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setYear(value);
  };

  return (
    <InputWrap>
      <label className={'labelYear active'} >Год</label>
      <input className={'input'} name={'year'} value={year} min={0} max={3000} type="number"  onChange={changeInput}/>
    </InputWrap>
  )
}

export default InputYear

