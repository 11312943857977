import React, {useEffect, useState} from 'react';

import AdminModalTask from '../../adminModalTask/adminModalTask';
import MainInput from '../../../inputs/mainInput/mainInput';
import MainButton from '../../../buttons/mainButton/mainButton';
import Question from './question/question';

import {
    FormAddBtn,
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap,
    PopupLine
} from '../../styled';

import plus from '../../../../assets/media/icon/plus-blue.svg'
import NoteInput from '../../../inputs/noteInput/noteInput';


const TF = ({
                edit,
                back,
                close,
                addTask,
                editTask
            }) => {
    const [type, setType] = useState('add');
    const [taskTitle, setTaskTitle] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [questions, setQuestions] = useState([{
        question: '',
        result: 'True',
        id: 1,
        // smile: like
    }]);
    const [questionId, setQuestionId] = useState(2);
    const [editNoteValue, setEditNoteValue] = useState({});
    const [note, setNote] = useState({});

    // добавление или обновление
    useEffect(() => {
        if (!!edit.id) {
            setType('edit');
            setQuestions(edit.list_tf_question);
            setTaskId(edit.id);
            setTaskTitle(edit.title);
            setQuestionId(edit.list_tf_question[edit.list_tf_question.length - 1].id + 1);
            setNote(edit.notes)
        }
    }, [edit])

    const questionsRender = questions.map((item) => {
        return (
            <Question
                data={item}
                key={item.id}
                handleChange={(e) => handleChangeValue(e, item.id)}
                handleChangeResult={(value) => handleChangeResultValue(value, item.id)}
                onDelete={() => deleteQuestion(item.id)}
                deleteDisabled={questions.length <= 1}
            />
        )
    })

    const addMore = () => {
        setQuestions([...questions, {question: '', result: 'True', id: questionId}]);
        setQuestionId(questionId + 1);
    }

    const deleteQuestion = (id) => {
        setQuestions(questions.filter(item => item.id !== id));
    }

    const handleChangeValue = (e, id) => {
        setQuestions(questions.map(item => item.id === id ? ({
            ...item,
            question: e.target.value
        }) : item))
    }

    const handleChangeResultValue = (value, id) => {
        setQuestions(questions.map(item => item.id === id ? ({
            ...item,
            result: value.name
        }) : item))
    }

    const handleCreateTask = (e) => {
        e.preventDefault();

        const task = {
            title: taskTitle,
            list_tf_question: questions,
            task_type: 'TF',
            note: editNoteValue,
        };

        addTask(task);
    }

    const handleEditTask = (e) => {
        e.preventDefault();
        const task = {
            title: taskTitle,
            list_tf_question: questions,
            task_type: 'TF',
        };

        const noteData = {
            note: editNoteValue,
            noteId: note[0].id,
        }

        editTask(taskId, task, noteData);
    }

    return (
        <AdminModalTask
            type={type}
            back={back}
            title={'Правда/ложь?'}
            close={close}
        >
            <FormWrap
                onSubmit={(e) => {
                    type === 'add' ? handleCreateTask(e) : handleEditTask(e)
                }}
            >
                <FormHeadContainer>
                    <MainInput
                        className={'task-input_head'}
                        type={'text'}
                        label={'Условия упражнения'}
                        name={'title'}
                        required={true}
                        defaultValue={taskTitle}
                        updateData={e => setTaskTitle(e.target.value)}
                    />

                    <NoteInput
                        type={type}
                        label={'Заметка'}
                        defaultValue={note}
                        desc_note={noteValue => setEditNoteValue(noteValue)}
                    />

                    <h3 className="title">Утверждения</h3>
                </FormHeadContainer>
                <PopupLine/>
                <FormMainContainer className={'padding'}>
                    {questionsRender}
                </FormMainContainer>
                <PopupLine/>
                <FormBtnContainer>
                    <FormAddBtn
                        onClick={addMore}
                        type={'button'}
                    >
                        <img src={plus} alt="plus"/>
                        <p>Добавить утверждение</p>
                    </FormAddBtn>
                </FormBtnContainer>
                <PopupLine/>
                <FormBtnContainer>
                    <MainButton
                        text={type === 'add' ? 'Добавить' : 'Сохранить'}
                        width={'full'}
                        type={'submit'}
                    />
                </FormBtnContainer>
            </FormWrap>
        </AdminModalTask>
    )
}

export default TF;
