import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CheckedBlock from "../checkedBlock/checkedBlock";

import {ImageWrap} from './mediaImgStyled';
import Notes from "../notes/notes";

const MediaImg = ({data, userType, tasks, handleCheckTask}) => {
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
    }, [tasks]);

    const handleCheck = () => {
        handleCheckTask("", data.id)
    };

    const handleFalseCloseChecked = () => setCompleted(false);

    return (
        <div style={{
            display: "flex", justifyContent: "center",
            flexDirection: "column", alignItems: "center"
        }}>
            <ImageWrap>
                <img
                    src={data.file}
                    className="image__container"
                    alt=""
                />

                {/*<div className="image__wrap image__wrap_1">*/}
                {/*    <span className="image__text">{data.name}</span>*/}
                {/*    /!*нужно распарсить формат*!/*/}
                {/*    /!*<span className="image__subtext">{ format }, { size }</span>*!/*/}
                {/*</div>*/}
            </ImageWrap>

            <div style={{width: "100%", paddingTop:'15px'}}>
            <Notes notes={data.notes} userType={userType}/>
            </div>
            
            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
                isSimple
            />}
        </div>
    )

};

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
}

export default connect(mapStateToProps)(MediaImg);
