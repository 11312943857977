import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Button = styled.button`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.disabled?.length <= 0 ? '#A2A9B0' : props.disabled === true ? '#A2A9B0' : '#fff'};
  background-color: ${props => props.disabled?.length <= 0 ? '#DDE1E6' : props.disabled === true ? '#DDE1E6' : '#4F7FFF'};
  border-radius: 6px;
  padding: 14px 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: ${props => props.width ? `${props.width === 'full' ? '100%' : props.width + 'px'}` : 'auto'};
  transition: transform .3s ease, opacity .3s ease-in;
  
  &:disabled {
    cursor: initial;
  }
  
  &.btn-back {
    margin-bottom: 16px;
  }

  &.btn-del {
    background-color: ${props => props.disabled?.length <= 0 ? '#DDE1E6' : props.disabled === true ? '#DDE1E6' : 'transparent'};
    margin-bottom: 16px;
    color: #DA1E28;
    border: 1px solid #DDE1E6;
  }
  
  :hover {
    opacity: .9;
  }
  
  :active {
    transform: scale(.98);
  }

  @media ${baseTheme.media.medium} {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
  }
`

export {Button}
