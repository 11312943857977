import React, {useEffect, useState} from "react";

import Checkbox from './checkboxInput';
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";

import {
  //ToggleBlock,
  //CheckBoxWrapper,
  //CheckBoxLabel,
  //CheckBox,
  MainBtnWrap,
  Answers,
  QuestionWrapper
} from './styled';
import plus from "../../../../assets/media/icon/add.svg";
import {FormAddBtn, FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap, PopupLine} from "../../styled";
import NoteInput from "../../../inputs/noteInput/noteInput";

const TestModal = ({
                     edit,
                     back,
                     close,
                     addTask,
                     editTask
                   }) => {
  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [questions, setQuestions] = useState([{id: 0, value: '', answers: [{id: 0, value: '', result: false}]}]);
  const [questionCounter, setQuestionCounter] = useState(1);
  const [answerCounter, setAnswerCounter] = useState(1);
  const [editNoteValue, setEditNoteValue] = useState({});
  const [note, setNote] = useState({});

  useEffect(() => {
    if (!!edit.id) {
      setType('edit');
      const lastQuestion = edit.question[edit.question.length - 1]
      setQuestions(edit.question);
      setTaskId(edit.id)
      setTaskTitle(edit.title)
      setQuestionCounter(lastQuestion.id + 1);
      setAnswerCounter(lastQuestion.answers[lastQuestion.answers.length - 1].id + 1);
      setNote(edit.notes)
    }
  }, [])

  const addMoreQuestion = () => {
    setQuestions([...questions, {value: '', id: questionCounter, answers: [{id: answerCounter, result: false}]}]);
    setQuestionCounter(questionCounter + 1);
    setAnswerCounter(answerCounter + 1);
  }

  const deleteQuestion = (id) => {
    setQuestions(questions.filter(item => item.id !== id));
  }

  const addMoreAnswer = (id) => {
    setQuestions(questions.map(item => item.id !== id ? item : {
      ...item,
      answers: [...item.answers, {id: answerCounter, result: false}]
    }));
    setAnswerCounter(answerCounter + 1);
  }

  const deleteAnswer = (questionId, id) => {
    setQuestions(questions.map(item => item.id !== questionId ? item : ({
      ...item,
      answers: item.answers.filter(item => item.id !== id)
    })));
  }

  const handleChangeQuestionValue = (e, questionId) => {
    setQuestions(questions.map(item => item.id !== questionId ? item : ({...item, value: e.target.value})));
  }

  const handleToggleCheckboxValue = (e, questionId, answerId) => {
    setQuestions(questions.map(item => item.id !== questionId ? item : (
      {
        ...item, answers: item.answers.map(answer => answer.id !== answerId ? answer : (
          {
            ...answer, result: e.target.checked
          })
        )
      }
    )))
  }

  const handleChangeAnswerValue = (e, questionId, answerId) => {
    setQuestions(questions.map(item => item.id !== questionId ? item : (
      {
        ...item, answers: item.answers.map(answer => answer.id !== answerId ? answer : (
          {
            ...answer, value: e.target.value
          })
        )
      }
    )))
  }

  const handleCreateTask = (e) => {
    e.preventDefault();

    const task = {
      title: e.target.title.value,
      question: questions,
      task_type: 'TEST',
      timer: 'false',
      note:editNoteValue,
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();

    const task = {
      title: e.target.title.value,
      question: questions,
      task_type: 'TEST',
      timer: 'false',
    };

    const noteData ={
      note: editNoteValue,
      noteId:note[0].id,
    }

    editTask(taskId, task,noteData);
  }

  const questionRender = questions.map(item => {
    return (
      <>
        <QuestionWrapper>
          <MainInput
            className={'task-input_head'}
            key={item.id}
            type={'text'}
            label={'Вопрос'}
            name={'question'}
            required={true}
            defaultValue={item.value}
            updateData={(e) => handleChangeQuestionValue(e, item.id)}
          />
          <div onClick={() => deleteQuestion(item.id)} className="delete"/>
        </QuestionWrapper>
        <Answers>
          <h4>Ответы</h4>
          <div className="answers_block">
            {item.answers.length > 0 && (
              item.answers.map(answer => (
                <Checkbox
                  data={answer}
                  key={answer.id}
                  handleToggleCheckbox={(e) => handleToggleCheckboxValue(e, item.id, answer.id)}
                  handleChangeValue={(e) => handleChangeAnswerValue(e, item.id, answer.id)}
                  onDelete={() => deleteAnswer(item.id, answer.id)}
                />))
            )}
          </div>
          <MainBtnWrap type={'button'} onClick={() => addMoreAnswer(item.id)}>
            <img src={plus} alt="icon"/>
            Добавить ответ
          </MainBtnWrap>
        </Answers>
      </>
    )
  })


  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Тест'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >

        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
          <NoteInput
              type={type}
              label={'Заметка'}
              defaultValue={note}
              desc_note={noteValue => setEditNoteValue( noteValue)}
          />
        </FormHeadContainer>
        {/*
        <Line/>

        <ToggleBlock>
          <h4>Таймер</h4>

          <CheckBoxWrapper>
            <CheckBox id="checkbox" type="checkbox" name={'timer'}/>
            <CheckBoxLabel htmlFor="checkbox"/>
          </CheckBoxWrapper>
        </ToggleBlock>*/}
        <PopupLine/>
        <FormMainContainer>
          {questionRender}
        </FormMainContainer>
        <PopupLine/>
        <FormBtnContainer>
          <FormAddBtn
            onClick={addMoreQuestion}
            type={'button'}
          >
            <img src={plus} alt="+"/>
            <p>Добавить вопрос</p>
          </FormAddBtn>
        </FormBtnContainer>
        <PopupLine/>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
      {/*children*/}
    </AdminModalTask>
  )
}

export default TestModal;
