import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";
import icon_arrow_left from "../../../assets/media/icon/arrow_left.svg";

export const RoomWrap = styled.div`
  background: #F8F9FB;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  .container {
    padding-top: 32px;
    padding-bottom: 32px;
    flex-grow: 1;
    overflow-y: auto;
  }

  @media ${baseTheme.media.extraLarge} {
    .container {
      padding: 24px;
    }
  }

  @media ${baseTheme.media.large} {
    .container {
      padding: 16px;
    }
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
    .container {
      padding: 0;
      border-top: 2px solid #DDE1E6;
    }
  }
`

export const RoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 48px;
  background: #FFFFFF;
  z-index: 12;
  box-shadow: 0px 0px 4px rgb(105 112 119 / 8%), 0px 4px 4px rgb(105 112 119 / 16%);

  .titleBlock {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #111111;
    display: flex;
    align-items: center;
  }

  @media ${baseTheme.media.extraLarge} {
    padding: 16px 24px;
  }

  @media ${baseTheme.media.large} {
    padding: 16px;

    button {
      padding: 10px 14px;
    }
  }

  @media ${baseTheme.media.small} {
    align-items: flex-start;
    
    &.homework {
      align-items: center;
    }

    .titleBlock {
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100vw - 168px)
    }

    button {
      font-size: 12px;
      padding: 10px 8px;
    }
  }
`

export const RoomNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #111111;

  .dictionary {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    img {
      width: 22px;
      height: 17px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
`

export const RoomBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media ${baseTheme.media.large} {
    flex-direction: column;
  }
  
  .tasksList {
    background-color: transparent;
    padding: 0 3px;
    margin-bottom: 14px;
    flex: 2;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, .5) rgba(221, 225, 230, 0.5);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(221, 225, 230, 0.5);
    }

    @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
      margin-bottom: 0;
      padding: 0;
      overflow: hidden;
    }
  }
  
  
  .taskItem {
    width: 100%;
    padding: 17px 16px 16px 23px;
    background: ${props => props.taskType === 'NOTE' ? 'linear-gradient(0deg, #EEF3FF, #EEF3FF), #FFFFFF;' : '#FFFFFF'};
    border: ${props => props.taskType === 'NOTE' ? '1px solid rgba(79, 127, 255, 0.4)' : '1px solid #DDE1E6'};
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(105, 112, 119, 0.06), 0 2px 2px rgba(105, 112, 119, 0.12);
    border-radius: 8px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 2px;
    }

    .section__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-right: auto;
      margin-bottom: 10px;

      @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
        padding: 0 16px;
      }
    }

    .words_classroom {
      position: sticky;
      top: 0;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 10px #fff;
      
      @media ${baseTheme.media.small} {
        min-height: auto;
      }
    }
    
    @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
      padding: 10px 0 41px;
      border: none;
      border-radius: 8px 8px 0 0;
      box-sizing: border-box;
      margin-bottom: 0;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
    }
  }
`

export const RoomBackLink = styled.a`
  display: block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: url(${icon_arrow_left}) center center/cover no-repeat;
  cursor: pointer;
  margin-right: 16px;
  transition: .3s;
  
  &:focus,
  &:hover {
    opacity: .7;
  }
`;
