import React, {useEffect, useState} from "react";
import '../text/react-draft-wysiwyg.css';
import {Editor} from "react-draft-wysiwyg";
import {EditorState, ContentState, convertFromHTML} from "draft-js";
import {stateToHTML} from "draft-js-export-html";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainButton from "../../../buttons/mainButton/mainButton";

import bold from "../../../../assets/media/icon/bold.svg";
import italic from "../../../../assets/media/icon/italic.svg";
import underline from "../../../../assets/media/icon/underline.svg";
import unordered from "../../../../assets/media/icon/mark_list.svg";
import link from "../../../../assets/media/icon/link.svg";
import image from "../../../../assets/media/icon/photo.svg";

import {uploadFile} from "../../../../service/iTeacherApi";
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

const Note = ({
                edit,
                back,
                close,
                section,
                addTask,
                editTask
              }) => {
  const [type, setType] = useState('add');
  const [taskId, setTaskId] = useState(null);
  const [studentVisibility, setStudentVisibility] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState();

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setEditorContent(stateToHTML(editorState.getCurrentContent()))
  };

  useEffect(() => {
    if (!!edit.id) {
      setTaskId(edit.id)
      setType("edit");
      setStudentVisibility(edit.student_visibility)
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(edit.desc)
        )
      ))
    }
  }, [])

  const handleCreateTask = (e) => {
    e.preventDefault();

    const task = {
      section: section.id,
      title: studentVisibility ? 'Заметка' : 'Заметка (видна только вам)',
      desc: editorContent,
      task_type: 'NOTE',
      student_visibility: studentVisibility
    }

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();

    const task = {
      section: section.id,
      title: studentVisibility ? 'Заметка' : 'Заметка (видна только вам)',
      desc: editorContent,
      task_type: 'NOTE',
      student_visibility: studentVisibility
    }
    editTask(taskId, task);
  }

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Заметка для учителя'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <div className="desc">Введите текст заметки</div>
          <div>
            <span className="switch-text text-muted">Видимость для ученика</span>
            <label className="switch">
              <input type="checkbox" checked={studentVisibility}
                     onChange={() => setStudentVisibility(!studentVisibility)}/>
              <span className="switch-slider"></span>
            </label>
          </div>
        </FormHeadContainer>
        <FormMainContainer className={'padding noTopBotPadding'}>
          <Editor
            defaultEditorState={editorState}
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ['inline', 'image', 'link', 'list',],
              inline: {
                inDropdown: false,
                className: 'media icon_font',
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
                bold: {icon: bold, className: undefined},
                italic: {icon: italic, className: undefined},
                underline: {icon: underline, className: undefined},
              },
              list: {
                inDropdown: false,
                className: 'media icon_list',
                component: undefined,
                dropdownClassName: undefined,
                options: ['unordered', 'ordered'],
                unordered: {icon: unordered, className: undefined},
                //ordered: { icon: ordered, className: undefined },
              },
              // textAlign: {
              //   inDropdown: false,
              //   className: 'media icon_text',
              //   component: undefined,
              //   dropdownClassName: undefined,
              //   options: ['left', 'center', 'right'],
              //   left: {icon: left, className: undefined},
              //   center: {icon: center, className: undefined},
              //   right: {icon: right, className: undefined},
              //   //justify: { icon: justify, className: undefined },
              // },
              link: {
                inDropdown: false,
                className: 'media icon_link',
                component: undefined,
                popupClassName: undefined,
                dropdownClassName: undefined,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_self',
                options: ['link'],
                link: {icon: link, className: undefined},
                //unlink: { icon: unlink, className: undefined },
                linkCallback: undefined
              },
              image: {
                icon: image,
                className: 'media',
                component: undefined,
                popupClassName: undefined,
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: false,
                uploadCallback: uploadFile,
                alt: {present: true, mandatory: false},
                previewImage: true,
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
              },
            }}
          />
        </FormMainContainer>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
    </AdminModalTask>
  )
}

export default Note;
