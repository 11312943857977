import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {setTopAlertText, signIn} from "../../store/actions/usersAction";

import CourseItem from "./courseItem/courseItem";
import NoneCourses from "./noneCourse/noneCourse";

import * as Style from './styled';

import library from '../../assets/media/icon/library.svg';
import plus from '../../assets/media/icon/plus-blue.svg';
import {checkOrder, resetOrder} from "../../store/actions/orderAction";
import AddCourseButton from "../../components/buttons/addCourseButton/addCourseButton";
import {openAddCoursePopup} from "../../store/actions/addCoursePopupAction";
import {openInfoPopup, setErrorInfoText, setSuccessInfoText} from "../../store/actions/infoPopupAction";
const Courses = ({user, setTopAlertText, courses, orderPaid, checkOrder,isCheckedOrder, resetOrder, signIn, openAddCoursePopup, setSuccessInfoText, setErrorInfoText, openInfoPopup}) => {
  // обновляем список курсов
  useEffect(async () => {
    setTopAlertText(false);
  }, [user]);
  useEffect(() => {
    const orderId = localStorage.getItem('orderIdPay');
    if (orderId) checkOrder(orderId);
    if (isCheckedOrder) {
      if (orderPaid) {
        signIn();
        resetOrder();
        setSuccessInfoText('Оплата прошла успешно');
      } else {
        setErrorInfoText('Оплата не прошла, попробуйте оплатить другой картой либо свяжитесь со службой поддержки');
      }
      openInfoPopup();
    }
  }, [isCheckedOrder])

  // check if there are any courses . If there are no courses render EMPTY course page
  if (!courses || courses.length === 0) return <NoneCourses handleOpenCourseCreatePopup={openAddCoursePopup}/>

  return (
    <Style.CourseWrap>
      <div className='container'>
        <Style.Caption>
          <Style.Title>Курсы</Style.Title>

          {/*покупать курсы может только учитель*/}
          {
            user && user.type === 'TEACHER' && (
              <>
                <Style.AddCourse>
                  <Link to='/courses-library'>Магазин курсов
                    <img src={library} alt="icon"/>
                  </Link>
                </Style.AddCourse>
                <Style.AddCourseMobile>
                  <Link to='/courses-library'>
                    <img src={plus} alt="icon"/>
                  </Link>
                </Style.AddCourseMobile>
              </>
            )
          }
        </Style.Caption>

        {/*<Style.CaptionInput>*/}
        {/*  <Style.SearchBlock>*/}
        {/*    <Style.Input>*/}
        {/*      <img src={search} alt="icon"/>*/}
        {/*      <input type="text" placeholder="Поиск"/>*/}
        {/*    </Style.Input>*/}
        {/*  </Style.SearchBlock>*/}
        {/*  <Style.SortBlock>*/}
        {/*    <img src={sort} alt="icon"/>*/}
        {/*    <p>Сортировать по</p>*/}
        {/*    <p><b>алфавиту</b></p>*/}
        {/*    <img src={arrow} alt="icon"/>*/}
        {/*  </Style.SortBlock>*/}
        {/*</Style.CaptionInput>*/}

        {/*ADD COURSES LIST*/}

        <Style.CoursesList>
          {user.type === 'TEACHER' &&<AddCourseButton clickHandle={openAddCoursePopup} text={'Создать курс'}/>}
          {courses.map(course => {
            return <CourseItem key={course.id} course={course} user={user}/>
          })}
          {/*<CoursesItemsV2/>*/}
        </Style.CoursesList>

      </div>
    </Style.CourseWrap>
  )
}


const mapStateToProps = ({users, courses, order}) => ({
  user: users.user,
  students: users.students,
  courses: courses.courses,
  orderPaid: order.orderPaid,
  isCheckedOrder: order.isCheckedOrder,
})

const mapDispatchToProps = (dispatch) => ({
  setTopAlertText: (text) => dispatch(setTopAlertText(text)),
  checkOrder: (id) => dispatch(checkOrder(id)),
  resetOrder: () => dispatch(resetOrder()),
  signIn: () => dispatch(signIn()),
  openAddCoursePopup: () => dispatch(openAddCoursePopup()),
  setSuccessInfoText: (text) => dispatch(setSuccessInfoText(text)),
  setErrorInfoText: (text) => dispatch(setErrorInfoText(text)),
  openInfoPopup: () => dispatch(openInfoPopup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
