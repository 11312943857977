import React from "react";
import {connect} from "react-redux";

import Indicator from "./indicator/indicator";

import {PlanListItemWrap} from "./style";

const PlansListItem = ({
                         lesson,
                         number,
                         open,
                         time,
                         user,
                         openCalendar,
                         isStudentCourse,
                         isInactiveCourse,
                         complete
                       }) => {
  return (
    <PlanListItemWrap>

      <Indicator complete={complete}/>

      <div className="nameBlock">
        <div className='top' onClick={() => open(lesson)}>
          <div className="number">#{number + 1}</div>
          <div className="name">{lesson.name}</div>
        </div>
        {
          (isStudentCourse && user.type === 'TEACHER' && !complete && !time) ?
            <button
              type={'button'}
              className={`bottom ${isInactiveCourse ? 'opacity' : ''}`}
              onClick={() => openCalendar(lesson.id)}
              disabled={isInactiveCourse}
            >
              Назначить урок
            </button>
          : <div className={'time'}>{time}</div>
        }
      </div>
    </PlanListItemWrap>
  )
}

const mapStateToProps = ({users}) => ({
  user: users.user
})

export default connect(mapStateToProps, null)(PlansListItem);
