import React from "react";

import CourseCover from "../../../../components/courseCover/courseCover";

import {Desc, InfoLesson, Name} from "../../styled";
import {getLessonTimeString} from "../../../../service/timePrettier";

const MainInfo = ({event}) => {
  // получаем дату
  const date = getLessonTimeString(event.start_time);
  return (
    <InfoLesson>
      <div className="td-wrapper">
        {/*блок с картинкой курса*/}
        <CourseCover course={event.course.course_template} small={true}/>

        {/*<div className="infoBG">*/}
        {/*  <p className={'nameCourse'}>{course.name}</p>*/}
        {/*  <p className={'firstLetter'}>{course.name[0]}</p>*/}
        {/*</div>*/}
        <div className="textInfo">
          <Name className="number">#{event.lesson.id} {event.lesson.name}</Name>
          <Desc className="desc">{date}</Desc>
        </div>
      </div>
    </InfoLesson>
  )
}

export default MainInfo;
