import React from 'react';
import classNames from "classnames";

import * as Style from './styled'
import {InfoIcon} from "./styled";

import iconPen from '../../../assets/media/icon/pen_grey.svg';
import iconTrash from '../../../assets/media/icon/trash_basket.svg';

const InfoItem = ({photoSrc, name, desc, time, type, text, status, editFunc, deleteFunc, onClickHandle, isMore, children}) => {
  return (
    <Style.InfoItem className={classNames(type)} onClick={onClickHandle || null} isMore={isMore}>
      {!!photoSrc && <div className='avatar'><img src={photoSrc} alt="user-avatar"/></div>}
      {!!name && <p className='name'>{name}</p>}
      {!!desc && <p className='desc'>{desc}</p>}
      {!!time && <p className='time'>{time}</p>}
      {!!text && <p className='text'>{text}</p>}
      {!!status && (
        <p className={classNames('status', {verify: status === 'VERIFIED', complete: status === 'COMPLETE'})}>
          {status === 'ASSIGNED'
            ? 'Назначено'
            : status === 'COMPLETE'
              ? 'Выполнено'
              : 'Проверено'
          }
        </p>
      )}
      {!!editFunc && <InfoIcon className={'icon icon_edit'} src={iconPen} onClick={editFunc}/>}
      {!!deleteFunc && <InfoIcon className={'icon icon_delete'} src={iconTrash} onClick={deleteFunc}/>}
      {children}
    </Style.InfoItem>
  );
};

export default InfoItem;
